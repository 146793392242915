import React, { useState } from 'react';
import {
    FormControlLabel, Checkbox, FormGroup,
} from '@mui/material';
import { SensorQuestion } from '../../../hooks/Questionnaire/types';
import QuestionnaireStep from './QuestionnaireStep';
import { SensorSelection } from './state/types';

const startState = (sensorQuestions: SensorQuestion[], selectedSensors: SensorSelection) => Object.fromEntries(
    sensorQuestions.map((question) => {
        const selectedSensorSelected = selectedSensors[question.id]?.isEquipped ?? false;
        return [question.id, { name: question.name, isEquipped: selectedSensorSelected }];
    }),
);
export default ({
    sensorQuestions, setSelectedSensors, selectedSensors, navigateBack,
}: {
    sensorQuestions: SensorQuestion[],
    setSelectedSensors: (selectedSensors: SensorSelection) => void,
    selectedSensors: SensorSelection,
    navigateBack: () => void,
}) => {
    const [sensorValues, setSensorValues] = useState<SensorSelection>(startState(sensorQuestions, selectedSensors));
    return (
        <QuestionnaireStep
            headerText="Please select the equipped vehicle sensors:"
            onSubmit={() => setSelectedSensors(sensorValues)}
            areAnswersValid
            navigateBack={navigateBack}
        >
            <FormGroup>
                {sensorQuestions.map(({ id, name }) => (
                    <FormControlLabel
                        sx={{ color: '#4a4a4a' }}
                        label={name}
                        control={(
                            <Checkbox
                                checked={sensorValues[id].isEquipped}
                                onChange={(e) => setSensorValues({
                                    ...sensorValues,
                                    [id]: {
                                        name,
                                        isEquipped: e.target.checked,
                                    },
                                })}
                            />
                        )}
                    />
                ))}
            </FormGroup>
        </QuestionnaireStep>
    );
};
