/* eslint-disable max-len */
import { Guid } from '@adas/shared-types';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { isNil } from 'lodash';

const GetUserUsageForMonthQuery = gql`
    query GetUserUsageForMonth($userId: String, $monthTime: Int, $offset: Int, $limit: Int) {
        userUsageResponse(userId: $userId, monthTime: $monthTime, offset: $offset, limit: $limit)
        @rest(
                type: "UserUsage[]",
                path: "api/user-usage/{args.userId}/month/{args.monthTime}?offset={args.offset}&limit={args.limit}"
            )
        {
            usage {
                customerUsageId
                customerId
                userId
                vin
                usageDate
            }
            usageCount
            error
        }
    }
`;

export interface UserUsage {
    customerUsageId: Guid;
    userId: Guid;
    usageId: Guid;
    usageDate: Date;
    vin: string;
}

export interface UserUsageResponse {
    usage: UserUsage[]
    usageCount: number;
    error: string;
}

type QueryParameters = {
    userId: Guid | undefined;
    monthTime: string | undefined;
    offset: number;
    limit: number;
};

type DataType = { userUsageResponse: UserUsageResponse };

export const GetUserUsageForMonth = (options: QueryHookOptions<DataType, QueryParameters> = {}) => {
    const response = useQuery<DataType, QueryParameters>(GetUserUsageForMonthQuery, {
        ...options,
        skip: isNil(options?.variables?.userId)
            || isNil(options?.variables?.offset)
            || isNil(options?.variables?.limit),
    });
    const userUsageResponse = response.data?.userUsageResponse;
    const usage = userUsageResponse?.usage ?? [];
    const error = userUsageResponse?.error;
    const usageCount = userUsageResponse?.usageCount ?? 0;
    return [{ usage, usageCount, error }, response] as const;
};

export default GetUserUsageForMonth;
