import { Button, Guid } from '@adas/shared-types';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import GetAllUserUsageForMonth, { UsageLine } from './hooks/GetAllUserUsageForMonth';

const headers: { label: string, key: keyof UsageLine }[] = [
    { label: 'VIN', key: 'vin' },
    { label: 'Usage Date', key: 'usageDate' }];

export default ({ userId, usageMonth } :{ userId: Guid, usageMonth: number }) => {
    const [usageDataForDownload, setUsageDataForDownload] = useState<UsageLine[] | undefined>();
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const [fetchUsageData] = GetAllUserUsageForMonth({
        variables: { userId, usageMonth },
        onCompleted: ({ userUsage }) => {
            setUsageDataForDownload(userUsage);
            csvLinkRef?.current?.link.click();
        },
    });
    return (
        <header className="level">
            <div className="level-left">
                <div className="level-item">
                    <h1 className="title">Usage Report</h1>
                </div>
            </div>
            <div className="level-right">
                <Button onClick={() => fetchUsageData()}>Download Usage to CSV</Button>
                {usageDataForDownload && (
                    <CSVLink
                        headers={headers}
                        data={usageDataForDownload}
                        ref={csvLinkRef}
                        filename={`AdasThink_Usage_${format(new Date(usageMonth), 'MM-yyyy')}.csv`}
                        target="_blank"
                    />
                )}
            </div>
        </header>
    );
};
