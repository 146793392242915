/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { deleteAppToken, routes } from '@adas/shared-types';
import { isNil } from 'lodash';
import { UseAzure } from '@repairify/react-auth';
import MenuLink from './MenuLink';
import AdminMenu from './AdminMenu';
import GetUserSettings from '../../../hooks/GetUserSettings';
import { AppContext } from '../../../AppContext';
import IsFeatureFlagEnabled from '../../../hooks/IsFeatureFlagEnabled';
import GetUserPermissions from '../../../hooks/GetUserPermissions';
import deleteCookie from '../../../utils/deleteCookie';

const NewReportLink = () => {
    const [userSettings, { loading: userSettingsLoading }] = GetUserSettings();

    if (userSettingsLoading) {
        return null;
    }
    if (isNil(userSettings) || userSettings?.useCustomReport === false) {
        return (
            <MenuLink to={routes.pages.newReport}>
                <span className="icon is-small is-left">
                    <i className="fas fa-file-upload" />
                </span>
                New Report
            </MenuLink>
        );
    }
    return (
        <MenuLink to={routes.pages.customReport}>
            <span className="icon is-small is-left">
                <i className="fas fa-file-upload" />
            </span>
            Custom Report
        </MenuLink>
    );
};

const Sidebar = () => {
    const { setIsUnregistered } = useContext(AppContext);
    const { logoutUser } = UseAzure();

    const [userPermissions, { loading: userPermissionsLoading }] = GetUserPermissions();
    const [questionnaireEnabled, { loading: questionnaireFeatureFlagLoading }] = IsFeatureFlagEnabled({
        variables: { featureKey: 'QUESTIONNAIRE' },
    });

    if (userPermissionsLoading || questionnaireFeatureFlagLoading) {
        return null;
    }

    const hasQuestionnairePermission = !!userPermissions?.newPermissions?.questionnaire;

    const hasQuestionnaireMenuItem = hasQuestionnairePermission && questionnaireEnabled;
    return (
        <aside className="menu">
            <h1 className="title">
                <Link to={routes.pages.home}>
                    <img src="/logo.png" alt="adasThink" id="logo" />
                </Link>
            </h1>
            <p className="menu-label">Vehicle Reports</p>
            <ul className="menu-list">
                <li>
                    <NewReportLink />
                </li>
                <li>
                    <MenuLink to={routes.pages.pastReports()} activeWhenStartsWith>
                        <span className="icon is-small is-left">
                            <i className="fas fa-copy" />
                        </span>
                        Past Reports
                    </MenuLink>
                </li>
                <li>
                    <MenuLink to={routes.pages.gettingStarted}>
                        <span className="icon is-small is-left">
                            <i className="fas fa-rocket" />
                        </span>
                        Getting Started
                    </MenuLink>
                </li>
                {hasQuestionnaireMenuItem && (
                    <li>
                        <MenuLink to={routes.pages.questionnaire}>
                            <span className="icon is-small is-left">
                                <i className="fas fa-question" />
                            </span>
                            Questionnaire
                        </MenuLink>
                    </li>
                )}
            </ul>
            <AdminMenu />
            <p className="menu-label">User Account</p>
            <ul className="menu-list">
                <li>
                    <MenuLink to={routes.pages.account.index}>
                        <span className="icon is-small is-left">
                            <i className="fas fa-cog" />
                        </span>
                        Account
                    </MenuLink>
                </li>
                <li>
                    <a
                        data-testid="side-bar-logout"
                        onClick={async () => {
                            deleteCookie('connect.sid');
                            deleteAppToken();
                            setIsUnregistered(undefined);
                            await logoutUser();
                        }}
                    >
                        <span className="icon is-small is-left">
                            <i className="fas fa-sign-out-alt" />
                        </span>
                        Log Out
                    </a>
                </li>
            </ul>
        </aside>
    );
};

export default Sidebar;
