import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

export class StripeApi {
    private stripePromise: Promise<Stripe | null>;

    constructor(publicApiKey: string) {
        this.stripePromise = loadStripe(publicApiKey);
    }

    stripe() {
        return this.stripePromise;
    }

    sendCard(stripe: Stripe, cardElement: StripeCardElement, setupIntentSecret: string) {
        return stripe.confirmCardSetup(setupIntentSecret, {
            payment_method: {
                card: cardElement,
            },
        });
    }
}
