import { isNil, isEmpty } from 'lodash';
import { indexToStep } from '../helpers';
import { QuestionnaireState, Steps } from '../types';

export default (state: QuestionnaireState): number => {
    const {
        currentStep, trimQuestion, sensorQuestions, carPartQuestions,
    } = state;
    let nextStepCandidate = currentStep + 1;

    if (indexToStep(nextStepCandidate) === Steps.TRIM && isNil(trimQuestion)) {
        nextStepCandidate += 1;
    }

    if (indexToStep(nextStepCandidate) === Steps.SENSOR && isEmpty(sensorQuestions)) {
        nextStepCandidate += 1;
    }

    if (indexToStep(nextStepCandidate) === Steps.CAR_PART && isEmpty(carPartQuestions)) {
        nextStepCandidate += 1;
    }

    if (isNil(Object.values(Steps)[nextStepCandidate])) {
        return currentStep;
    }

    return nextStepCandidate;
};
