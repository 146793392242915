import React from 'react';
import { isEmpty, isNil, sortBy } from 'lodash';
import { UserUsage } from './hooks/GetUserUsageForMonth';

export default ({ userUsage, error }: { userUsage: UserUsage[], error?: string }) => {
    if (!isNil(error)) {
        return (
            <div data-testid="error-message" className="box">
                {error}
            </div>
        );
    }
    if (isEmpty(userUsage)) {
        return (
            <div data-testid="no-usage-message" className="box">
                No usage to report.
            </div>
        );
    }
    return (
        <table id="past-userUsage-table" className="table is-hoverable" data-testid="past-userUsage-table">
            <thead>
                <tr>
                    <th>Usage Date</th>
                    <th>VIN</th>
                </tr>
            </thead>
            <tbody>
                {sortBy(userUsage, (cu) => cu.usageDate).map((usageItem) => {
                    const {
                        usageId, usageDate, vin,
                    } = usageItem;

                    const formattedDate = new Date(usageDate);
                    formattedDate.setMinutes(formattedDate.getMinutes() + formattedDate.getTimezoneOffset());

                    return (
                        <tr
                            data-testid="usage-row"
                            key={usageId}
                            data-vin={vin}
                        >

                            <td data-testid="usage-date">{formattedDate.toLocaleDateString()}</td>
                            <td>{vin}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
