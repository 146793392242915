/* eslint-disable prefer-destructuring */
import actionCreators from './actionCreators';

export const addCarPartsQuestions = actionCreators.addCarPartsQuestions;
export const addSensorQuestions = actionCreators.addSensorQuestions;
export const addTrimQuestion = actionCreators.addTrimQuestion;
export const completeExchange = actionCreators.completeExchange;
export const setExchangeError = actionCreators.setExchangeError;
export const setExchangeLoading = actionCreators.setExchangeLoading;
export const navigateBack = actionCreators.navigateBack;
export const selectCarParts = actionCreators.selectCarParts;
export const selectSensors = actionCreators.selectSensors;
export const selectTrim = actionCreators.selectTrim;
export const selectVin = actionCreators.selectVin;

export type ActionCreator = typeof actionCreators;
type ActionCreatorKeys = keyof typeof actionCreators;
export type QuestionnaireAction = ReturnType<typeof actionCreators[ActionCreatorKeys]>;
