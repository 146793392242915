import { Address } from "../users";
import { EditStateCountry } from "./EditStateCountry";
import React from "react";
import {
    TextField,
    InputAdornment,
    Grid
} from '@mui/material';
export const EditAddress = ({
    address,
    onChange,
    isShopAddress=false,
}: {
    address: Address;
    onChange: (address: Address) => void;
    isShopAddress?: boolean;
}) => {
    return (
            <Grid  container spacing={2} columnSpacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="address-1"
                        data-testid="address-line1-text-box"
                        label="Shop Address"
                        defaultValue={address.addressLine1}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="fas fa-building"></i>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        onChange={(e) => onChange({...address, addressLine1: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="address-2"
                        data-testid="address-line2-text-box"
                        label="Address Line 2"
                        defaultValue={address.addressLine2}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="fas fa-fw"></i>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        onChange={(e) => onChange({ ...address, addressLine2: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="city"
                        data-testid="city-text-box"
                        label="City"
                        defaultValue={address?.city}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="fas fa-city"></i>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        onChange={(e) => onChange({ ...address, city: e.target.value })}
                    />
                </Grid>
                <Grid item>
                    <EditStateCountry
                        state={address.state}
                        country={address.country}
                        onChange={data => onChange({ ...address, state: data.state, country: data.country })}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="zip-1"
                        data-testid="zip-text-box"
                        label="Zip/Postal Code"
                        defaultValue={address.zip}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <i className="fas fa-mail-bulk"></i>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        onChange={(e) => onChange( {...address, zip: e.target.value})}
                    />
                </Grid>
            </Grid>
    );
};
