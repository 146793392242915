import React, { ChangeEventHandler } from "react";
import {
    TextField,
    InputAdornment,
    Grid,
} from '@mui/material';

export default ({ defaultValue, onChange }: { defaultValue: string, onChange: ChangeEventHandler<HTMLInputElement> }) => (
    <TextField
        id="last-name"
        data-testid="last-name-text-box"
        label="Last Name"
        defaultValue={defaultValue}
        variant="outlined"
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <i className="fas fa-user"></i>
                </InputAdornment>
            ),
        }}
        fullWidth
        onChange={onChange}
    />
)