import { Api, UpdateDevCreditCardMessage } from "../ClientServerApi.generated";

export interface CreditCard {
    number: string;
}

export class TestStripeApi {
    sendCard(cardNumber: string): Promise<string> {
        const stripePaymentMethodId = `tok_${cardNumber.slice(-4)}`;
        const message: UpdateDevCreditCardMessage = {
            last4: cardNumber.slice(-4),
            stripePaymentMethodId,
        };
        return Api.updateDevCreditCard(message).then(() => stripePaymentMethodId);
    }
}
