import React, { useState } from "react";
import { Button } from "../Button";
import { EditAddress } from '../EditAddress';
import { Grid } from '@mui/material';
import validateRegistrationForm, { Address, ValidationErrorResponse, ValidationField } from "./validateRegistrationForm";
import { parseQueryString } from "../../utils";
import TermsOfServiceForm from "./TermsOfServiceForm";
import FirstNameField from "./FirstNameField";
import LastNameField from "./LastNameField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import ShopNameField from "./ShopNameField";
import ErrorMessage from "./ErrorMessage";

interface UserRegistrationMessage {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    shopName: string;
    shopAddress: Address;
    agreeLegal: boolean;
}

interface UserRegInitialValues  {
    firstName?: string,
    lastName?: string,
    shopAddress?: string,
    phone?: string
};

export const UserRegistrationForm = (
    {
        isSubmitting,
        onSubmit,
        apiErrorResponse,
        email,
        initialValues,
    } : 
    {
        isSubmitting: boolean;
        onSubmit: (regForm: UserRegistrationMessage) => void;
        apiErrorResponse: string | undefined;
        email: string;
        initialValues?: UserRegInitialValues;
    }
) => {
    const [registerMessage, setRegisterMessage] = useState<UserRegistrationMessage>({
        email: email ?? parseQueryString().email ?? '',
        firstName: initialValues?.firstName ?? '',
        lastName: initialValues?.lastName ?? '',
        shopName: '',
        phone: initialValues?.phone ?? '',
        shopAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            country: 'US',
            state: "AL",
            zip: '',
        },
        agreeLegal: false,
    });
    const [validationMessage, setValidationMessage] = useState<ValidationErrorResponse>({});
    const [errorMessage, setErrorMessage] = useState('');

    const fieldsToValidate: ValidationField[] = ['agreeLegal', 'firstName', 'lastName', 'shopName', 'shopAddress', 'email', 'phone']
    const runFormValidation = () => {
        const validationCheck = validateRegistrationForm(registerMessage, fieldsToValidate);
        setValidationMessage(validationCheck?.errorMessages ?? {})
        setErrorMessage(validationCheck?.displayMessage?.[0] ?? '')
        return validationCheck;
    };

    const validateAndSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const validatedFormInputs = runFormValidation();
        if(!validatedFormInputs.displayMessage.length) {
            onSubmit(registerMessage);
        }
    };

    const updateRegisterMessage = (prop: Partial<UserRegistrationMessage>) => {
        setRegisterMessage({ ...registerMessage, ...prop });
    };
    
    const { agreeLegal, firstName, lastName, shopAddress, shopName, phone } = registerMessage;

    return (
        <form method="post" onSubmit={validateAndSubmit} >
            <Grid container spacing={1} columnSpacing={2}>
                <Grid item container spacing={2} columnSpacing={2} paddingBottom={2}>
                    <Grid item xs={12}>
                        <h2>User Info</h2>
                    </Grid>
                    <Grid item xs={6}>
                        <FirstNameField
                            defaultValue={firstName}
                            onChange={(value) => updateRegisterMessage({ firstName: value.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LastNameField
                            defaultValue={lastName}
                            onChange={(value) => updateRegisterMessage({ lastName: value.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EmailField 
                            error={validationMessage?.email}
                            defaultValue={email ?? email}
                            disabled={true}
                            onChange={(e) => updateRegisterMessage({ email: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={phone}
                            onChange={(value) => updateRegisterMessage({ phone: value })}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <h2>Shop Info</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <ShopNameField
                            defaultValue={shopName}
                            onChange={(e) => updateRegisterMessage({ shopName: e.target.value })}
                            error={validationMessage?.shopName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EditAddress 
                            onChange={(address) => updateRegisterMessage({shopAddress: {...shopAddress, ...address}})}
                            address={shopAddress}
                            isShopAddress
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} columnSpacing={2} paddingTop={2} xs={12}>
                    <Grid item xs={12}>
                        <TermsOfServiceForm
                            checked={agreeLegal}
                            onChange={(e) => {  updateRegisterMessage({ agreeLegal: e.target.checked }); }}
                        />
                    </Grid>
                    <Grid item xs={12} justifyContent="center" paddingTop={1}>
                        <ErrorMessage errorMessage={errorMessage} apiErrorResponse={apiErrorResponse} />
                        <Button data-testid="register-button" kind="link" type="submit" disabled={isSubmitting}>
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default UserRegistrationForm;