import {
    Button, Guid, Modal, Notification, stringUtils,
} from '@adas/shared-types';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Api, CustomerUserInvitationsListResponse } from '../../ClientServerApi.generated';

export interface CustomerUserInvitationsProps {
    onChange?: () => void;
}

export const CustomerUserInvitations = (props: CustomerUserInvitationsProps) => {
    const [customerUsersList, setCustomersUserList] = useState<CustomerUserInvitationsListResponse | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        Api.listCustomerUserInvitations().then((customerInvitations) => {
            setCustomersUserList(customerInvitations);
            setLoading(false);
        }).catch(() => {});
    }, []);

    const removeInvitationForCustomerId = (customerId: Guid) => {
        if (customerUsersList) {
            setCustomersUserList({
                ...customerUsersList,
                invitations: customerUsersList.invitations.filter((i) => i.customerId !== customerId),
            });
            props.onChange?.();
        }
    };

    const acceptInvitation = (customerId: Guid) => {
        Api.respondCustomerUserInvitation({
            customerId,
            accept: true,
        }).then(() => {
            Notification.show('Accepted invitation.');
            removeInvitationForCustomerId(customerId);
        }).catch(() => {
            Notification.show('Error: Invitation Failed to Accept');
        });
    };

    const declineInvitation = async (customerId: Guid, customerName: string) => {
        const result = await Modal.confirm(`Are you sure you want to decline the invitation from ${customerName}?`);
        if (result) {
            Api.respondCustomerUserInvitation({
                customerId,
                accept: false,
            }).then(() => {
                Notification.show('Declined invitation.');
                removeInvitationForCustomerId(customerId);
            }).catch(() => {
                Notification.show('Error: Invitation Failed to Decline');
            });
        }
    };

    if (loading) {
        return <div data-testid="loading-customer-invitations">Loading...</div>;
    }

    if (isNil(customerUsersList) || isEmpty(customerUsersList.invitations)) {
        return <div data-testid="empty-customer-invitations" />;
    }

    return (
        <>
            {customerUsersList.invitations.map((invitation, i) => (
                <div
                    data-test-group="customer-user-invitation"
                    data-testid="customer-user-invitation"
                    data-test-selector={invitation.name}
                    className="message"
                    key={invitation.customerId + invitation.name}
                >
                    <div className="message-header">
                        {`Invitation to Join - ${invitation.name}`}
                    </div>
                    <div className="message-body">
                        <p>
                            {`You received an invitation to join ${stringUtils.getPossesiveName(invitation.name)} plan.`}
                        </p>
                        <p>Would you like to accept?</p>
                        <div className="field is-grouped">
                            <p className="control">
                                <Button
                                    kind="info"
                                    data-testid="yes-button"
                                    onClick={() => acceptInvitation(invitation.customerId)}
                                    disabled={customerUsersList.isUserOwnerOfPaidNonExpiringPlanOrCustomerWithOtherUsers}
                                >
                                    Yes
                                </Button>
                            </p>
                            <p className="control">
                                <Button
                                    kind="danger"
                                    data-testid="no-button"
                                    onClick={() => declineInvitation(invitation.customerId, invitation.name)}
                                >
                                    No
                                </Button>
                            </p>
                        </div>
                        {customerUsersList.isUserOwnerOfPaidNonExpiringPlanOrCustomerWithOtherUsers
                                && (
                                    <p data-testid="cancel-before-accept-message">
                                        <i>
                                            Note: You are the owner of a paid plan or have other users under your plan.
                                            In order to accept this invitation you must ensure your current plan is
                                            cancelled and remove all users under your plan.
                                        </i>
                                    </p>
                                )}
                    </div>
                </div>
            ))}
        </>
    );
};
