import {
    gql, QueryHookOptions, useQuery,
} from '@apollo/client';

const GetAzureConfigurationQuery = gql`
    query GetAzureConfiguration {
        azureConfiguration @rest(type: "AzureConfiguration", path: "api/azure-configuration") {
            tenant
            clientId
        }
    }
`;

type DataType = { azureConfiguration: { tenant: string, clientId: string } };

const GetAzureConfiguration = (options: QueryHookOptions<DataType> = {}) => {
    const response = useQuery<DataType>(GetAzureConfigurationQuery, {
        ...options,
    });
    return [response.data?.azureConfiguration ?? { tenant: '', clientId: '' }, response] as const;
};
export default GetAzureConfiguration;
