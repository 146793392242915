import { parseQueryString } from "./parseQueryString";

export class UrlBuilder {
    private queryParams: { [name: string]: string } = {};

    constructor(private pathname: string) {
    }

    static fromCurrent() {
        const queryParams = parseQueryString();
        const pathname = window.location.pathname;
        let builder = new UrlBuilder(pathname);
        console.log(queryParams);
        for (const key of Object.keys(queryParams)) {
            const value = queryParams[key];
            if (value != null) {
                builder = builder.setQueryParam(key, value);
            }
        }
        return builder;
    }

    setPathName(pathname: string) {
        this.pathname = pathname;
    }

    setQueryParamTrimmed(name: string, value: string | number | boolean | undefined) {
        if (value == null) {
            return this;
        }

        const trimmedValue = value.toString().trim();
        if (trimmedValue.length > 0) {
            this.setQueryParam(name, trimmedValue);
        } else {
            this.removeQueryParam(name);
        }
        return this;
    }

    setQueryParam(name: string, value: string | number | boolean | undefined) {
        if (value == null) {
            return this;
        }

        this.queryParams[name] = value.toString();
        return this;
    }

    removeQueryParam(name: string) {
        delete this.queryParams[name];
        return this;
    }

    getParamsString() {
        const params = Object.keys(this.queryParams)
            .map(key => `${key}=${encodeURIComponent(this.queryParams[key])}`)
            .join("&");
        return params.length > 0 ? "?" + params : "";
    }

    toString() {
        return this.pathname + this.getParamsString();
    }
}
