import { Button, formatCentsAsDollars, Guid, Modal, parseQueryString, routes, Spinner } from "@adas/shared-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api, PlanPageView, PlansPagePlanView, PlanTaxLineItem, StripeDiscount } from "../ClientServerApi.generated";
import { AreaManagedByAccountOwnerMessage } from "./billing";
import { LinkButton, LoadingBar } from "./components";

export function PlanPage() {
    const planId = useParams<{ planId: string }>().planId?.toUpperCase() as Guid;
    const hasReviewQueryParam = useState(parseQueryString().review === "true");
    const [planView, setPlanView] = useState<PlanPageView | undefined>(undefined);
    const [couponCode, setCouponCode] = useState<string | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        Api.getPlanView({ planId, couponCode }).then(view => {
            setPlanView(view);
        });
    }, [couponCode]);

    if (planView == null) {
        return <LoadingBar />;
    }

    if (planView.plan === "notOwner") {
        return <AreaManagedByAccountOwnerMessage />;
    }
    if (planView.plan === "notFound") {
        return renderNotFound();
    }

    const priceInfo = getPriceInfo(planView.plan);
    const isReviewingPurchase = getIsReviewingPurchase(planView, planView.plan);
    return (
        <div className="content">
            <header className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">
                            {isReviewingPurchase ? "Review Order: " : ""}
                            Plan - {planView.plan.name} ({planView.plan.isYearly ? "Yearly" : "Monthly"})
                        </h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <div className="buttons">
                            <LinkButton className="is-info" to={routes.pages.account.plans}>View All Plans</LinkButton>
                        </div>
                    </div>
                </div>
            </header>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Estimates Per Month</td>
                        <td>{planView.plan.estimatesPerMonth ?? "Billed per use."}</td>
                    </tr>
                    {priceInfo != null ? renderPriceRows(planView, planView.plan, priceInfo) : (
                        <tr>
                            <td>Price</td>
                            <td>Contact us for pricing</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {renderBody(planView, planView.plan)}
        </div>
    );

    function renderPriceRows(planView: PlanPageView, plan: PlansPagePlanView, priceInfo: PriceInfo) {
        return (
            <>
                {priceInfo.discount != null && (
                    <>
                        <tr data-test-group="plan-page">
                            <td>Price</td>
                            <td data-testid="price-amount">
                                {formatCentsAsDollars(priceInfo.priceInCents)} {priceInfo.formattedCurrencyAndRate}
                            </td>
                        </tr>
                        <tr data-test-group="plan-page">
                            <td className="brand-color">
                                {priceInfo.discount.name}
                            </td>
                            <td>
                                <span className="brand-color" data-testid="discount-amount">
                                    {formatCentsAsDollars(priceInfo.subTotalInCents - priceInfo.priceInCents)}{" "}
                                    {priceInfo.formattedCurrencyAndRate}
                                </span>
                                {renderApplyCoupon(planView, plan)}
                            </td>
                        </tr>
                    </>
                )}
                {priceInfo.taxLineItems.length > 0 && (
                    <tr data-test-group="plan-page">
                        <td>Subtotal</td>
                        <td data-testid="sub-total-value">
                            {formatCentsAsDollars(priceInfo.subTotalInCents)} {priceInfo.formattedCurrencyAndRate}
                        </td>
                    </tr>
                )}
                {priceInfo.taxLineItems.map((taxLineItem, i) => (
                    <>
                        <tr data-test-group="tax-line-item" data-test-selector={i} key={i}>
                            <td data-testid="tax-line-item-name">
                                {taxLineItem.name}
                                {` (${taxLineItem.percentage}%)`}
                            </td>
                            <td data-testid="tax-line-item-value">
                                {formatCentsAsDollars(taxLineItem.taxInCents)} {priceInfo.formattedCurrencyAndRate}
                            </td>
                        </tr>
                    </>
                ))}
                <tr data-test-group="plan-page">
                    <td>
                        <b>Total Price</b>
                    </td>
                    <td>
                        <b data-testid="total-price-value">
                            {formatCentsAsDollars(priceInfo.totalPrice)} {priceInfo.formattedCurrencyAndRate}
                        </b>
                        {priceInfo.discount == null && renderApplyCoupon(planView, plan)}
                    </td>
                </tr>
            </>
        );
    }

    interface PriceInfo {
        totalPrice: number;
        priceInCents: number;
        subTotalInCents: number;
        formattedCurrencyAndRate: string;
        taxLineItems: PlanTaxLineItem[];
        discount: StripeDiscount | undefined;
    }

    function getPriceInfo(plan: PlansPagePlanView): PriceInfo | undefined {
        if (plan.price == null) {
            return undefined;
        }
        const formattedCurrency = plan.price.currency.toUpperCase();
        const formattedCurrencyAndRate = `${formattedCurrency} / ${plan.isYearly ? "year" : "month"}`;
        const totalPrice = plan.price.subTotalInCents
            + plan.price.taxLineItems.map(a => a.taxInCents).reduce((a, b) => a + b, 0);

        return {
            totalPrice,
            priceInCents: plan.price.priceInCents,
            subTotalInCents: plan.price.subTotalInCents,
            formattedCurrencyAndRate,
            taxLineItems: plan.price.taxLineItems,
            discount: plan.price.discount,
        };
    }

    function renderApplyCoupon(planView: PlanPageView, plan: PlansPagePlanView) {
        if (plan.planId === planView.currentPlanId || plan.price == null) {
            return <></>;
        }

        return (
            <span data-test-group="plan-page">
                {" ("}
                <a href="javascript:void(0)" onClick={onApplyOrChangeCoupon} data-testid="apply-coupon-button">
                    {plan.price.discount == null ? "Apply Coupon" : "Change Coupon"}
                </a>
                {")"}
            </span>
        );

        async function onApplyOrChangeCoupon() {
            const couponCode = await getCouponCode();

            if (couponCode != null) {
                setCouponCode(couponCode);
                setPlanView(undefined);
            }

            async function getCouponCode() {
                while (true) {
                    const couponCode = (await Modal.input("Please enter your coupon code:"))?.trim();
                    if (couponCode == null || couponCode.length === 0) {
                        return undefined;
                    }
                    const isValid = await Api.checkCouponCode({ couponCode });
                    if (isValid) {
                        return couponCode;
                    } else {
                        await Modal.alert("Coupon code is not valid. Please try again.");
                    }
                }
            }
        }
    }

    function renderBody(planView: PlanPageView, plan: PlansPagePlanView) {
        if (plan.planId === planView.currentPlanId) {
            return <CurrentPlanBody plan={plan} />;
        } else if (plan.price == null) {
            return (
                <div className="notification">
                    <a href="mailto:customerservice@astech.com">Contact us</a> for details about this plan.
                </div>
            );
        } else {
            return renderSwitch(planView, plan);
        }
    }

    function CurrentPlanBody({ plan }: { plan: PlansPagePlanView }) {
        const [showCancel, setShowCancel] = useState(false);
        return (
            <>
                <p>
                    You are currently on this plan.
                </p>
                {plan.price != null && (
                    <p data-test-group="plan-page">
                        {showCancel ? showCancelArea() : (
                            <button
                                data-testid="cancel-plan-button"
                                className="button is-danger"
                                onClick={() => setShowCancel(true)}
                            >
                                Cancel Plan
                            </button>
                        )}
                    </p>
                )}
            </>
        );

        function showCancelArea() {
            return (
                <>
                    <div className="notification">
                        <p>
                            Are you sure you want to cancel? Consider{" "}
                            <Link to={routes.pages.account.plans}>
                                switching plans
                            </Link>{" "}
                            instead.
                        </p>
                        {plan.isYearly && (
                            <p>
                                Cancelling a yearly plan takes effect immediately. You will be refunded the remaining
                                amount of your subscription based on our proration calculation.
                            </p>
                        )}
                        {!plan.isYearly && (
                            <p>
                                Cancelling a monthly plan takes effect at the end of the current month of your billing
                                cycle. After cancelling, you'll be able to use your subscription until that date.
                            </p>
                        )}
                        <CancelButton plan={plan} />
                    </div>
                </>
            );
        }
    }

    function CancelButton({ plan }: { plan: PlansPagePlanView }) {
        const [isLoading, setIsLoading] = useState(false);
        return (
            <Button
                kind="danger"
                type="button"
                data-test-group="plan-page"
                data-testid="confirm-cancel-plan-button"
                disabled={isLoading}
                onClick={onClick}
            >
                {isLoading && <Spinner />}
                Cancel Plan
            </Button>
        );

        async function onClick() {
            const wasConfirmed = await Modal.confirm(
                `We're sad to see you go! Are you sure you want to cancel your ${plan.name} plan?`,
            );
            if (wasConfirmed) {
                setIsLoading(true);
                Api.cancelPlan().then(() => {
                    history.push(routes.pages.account.index);
                    Modal.alert("Your plan was cancelled.");
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        }
    }

    function renderSwitch(planView: PlanPageView, plan: PlansPagePlanView) {
        return (
            <>
                <p>
                    {planView.hasCreditCardOnFile
                        ? <PurchaseButton planView={planView} plan={plan} />
                        : renderAddCreditCardButton()}
                </p>
            </>
        );
    }

    function renderAddCreditCardButton() {
        return (
            <span data-test-group="plan-page">
                <LinkButton
                    data-testid="add-billing-information-button"
                    className="is-success"
                    to={`${routes.pages.account.billing}?returnPlanId=${planId.toLowerCase()}`}
                >
                    Add Billing Information
                </LinkButton>
            </span>
        );
    }

    function PurchaseButton({ planView, plan }: { planView: PlanPageView; plan: PlansPagePlanView }) {
        const [isLoading, setIsLoading] = useState(false);
        const [stripeErrorMessage, setStripeErrorMessage] = useState<string | undefined>(undefined);
        const isInitialPurchase = planView.currentPlanId == null;

        return (
            <div className="notification" data-test-group="plan-page">
                <p>
                    By {isInitialPurchase ? "purchasing" : "switching to"} this plan, you acknowledge you have read{" "}
                    and agree to be bound by the{" "}
                    <a href="https://adasthink.com/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                    {" and "}
                    <a href="https://adasthink.com/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>.
                </p>
                <p>
                    {getInvoiceMessage()}
                </p>
                <p>
                    <button
                        data-testid="purchase-plan-button"
                        className="button is-success"
                        onClick={onClick}
                        disabled={isLoading}
                    >
                        {isLoading && <Spinner />}
                        {isInitialPurchase ? "Purchase" : "Switch to this plan"}
                    </button>
                </p>
                {stripeErrorMessage && (
                    <div className="notification is-danger" data-testid="stripe-error-message-box">
                        <p>
                            An error occurred making the payment. You may need to double check your account's{" "}
                            <Link to={routes.pages.account.billing}>billing information</Link>.
                        </p>
                        <p>Message from billing provider: {stripeErrorMessage}</p>
                    </div>
                )}
            </div>
        );

        function getInvoiceMessage() {
            if (plan.isYearly) {
                if (isInitialPurchase) {
                    return "The credit card you provided in your billing information will be invoiced immediately for this amount. "
                        + "A receipt will be sent to the email specified in your billing information.";
                } else {
                    return "The credit card you provided in your billing information will be invoiced immediately for this amount and "
                        + "be prorated based on the previously paid outstanding amount. "
                        + "A receipt will be sent to the email specified in your billing information.";
                }
            } else {
                if (isInitialPurchase) {
                    return "The credit card you provided in your billing information will be invoiced immediately for a prorated amount based on "
                        + "the time remaining for the current month. "
                        + "A receipt will be sent to the email specified in your billing information.";
                } else {
                    return "Your next invoice at the start of the next month will be prorated to reflect this change. Your credit card will not be charged until then.";
                }
            }
        }

        async function onClick() {
            setStripeErrorMessage(undefined);
            const wasConfirmed = await Modal.confirm(
                `Are you sure you want to ${
                    isInitialPurchase
                        ? "purchase"
                        : "purchase and switch to"
                } the ${plan.name} plan?`,
            );
            if (wasConfirmed) {
                setIsLoading(true);
                Api.purchasePlan({ planId: plan.planId, couponCode }).then(response => {
                    if (response.error) {
                        if (response.error.stripeError) {
                            setStripeErrorMessage(response.error.message);
                        } else {
                            Modal.alert(
                                "An unknown error occurred purchasing your plan. We've been notified of this problem.",
                            );
                        }
                    } else {
                        history.push(routes.pages.account.index);
                        Modal.alert(getSuccessMessage());
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
            }

            function getSuccessMessage() {
                if (plan.isYearly || isInitialPurchase) {
                    return "You're all set!\nA receipt will be emailed to the email address specified in your billing information.";
                } else {
                    return "You're all set!\nYour next invoice at the start of the next month will be prorated to reflect this change.";
                }
            }
        }
    }

    function getIsReviewingPurchase(planView: PlanPageView, plan: PlansPagePlanView) {
        return hasReviewQueryParam && planView.currentPlanId !== plan.planId && planView.hasCreditCardOnFile;
    }

    function renderNotFound() {
        return (
            <div className="content">
                <div className="notification is-warning">
                    <p>
                        Could not find the plan.
                    </p>
                </div>
            </div>
        );
    }
}
