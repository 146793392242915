import { Button, Guid, isValidEmail, Modal, Notification, Spinner, TextBox } from "@adas/shared-types";
import React, { useEffect, useState } from "react";
import {
    Api,
    CustomerUserManagementPageView,
    CustomerUserManagementPageViewDetails,
    CustomerUserManagementPageViewUser,
} from "../ClientServerApi.generated";
import { AreaForPaidUsersOnlyMessage, AreaManagedByAccountOwnerMessage } from "./billing";
import { LoadingBar } from "./components";

export function CustomerUsersPage() {
    const [view, setView] = useState<CustomerUserManagementPageView | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingUser, setIsAddingUser] = useState(false);

    useEffect(() => {
        if (isLoading) {
            Api.getCustomerUserManagementPageView().then(response => {
                setView(response);
                setIsLoading(false);
            });
        }
    }, [isLoading]);

    if (view == null) {
        return <LoadingBar />;
    }
    if (view.details === "notOwner") {
        return <AreaManagedByAccountOwnerMessage />;
    }
    if (view.details === "notPaidCustomer") {
        return <AreaForPaidUsersOnlyMessage />;
    }

    const details = view.details;
    return (
        <div className="content" data-test-group="customer-users-page">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">Plan Users</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        {isLoading && <Spinner />}
                        {!isAddingUser && details.canAddUser && (
                            <Button
                                data-testid="add-user-button"
                                kind="info"
                                onClick={() => setIsAddingUser(!isAddingUser)}
                            >
                                Add User
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {isAddingUser && (
                <SendEmailInvitation
                    onEmailAdded={email => {
                        setView({ details: { ...details, invitationEmails: [...details.invitationEmails, email] } });
                        setIsAddingUser(false);
                    }}
                    onCancel={() => setIsAddingUser(false)}
                />
            )}
            {renderUsers(details, emails =>
                setView({
                    details: {
                        ...details,
                        invitationEmails: [...emails],
                    },
                }), removedUserId => {
                setIsLoading(true);
                setView({
                    details: {
                        ...details,
                        users: details.users.filter(u => u.userId !== removedUserId),
                    },
                });
            })}
        </div>
    );
}

function SendEmailInvitation({
    onEmailAdded,
    onCancel,
}: {
    onEmailAdded: (email: string) => void;
    onCancel: () => void;
}) {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSending, setIsSending] = useState(false);
    return (
        <div className="notification">
            <p>
                Specifying an email here will send an email invitation to the user, allowing them to register for an
                account or login with their existing account. After logging in, they will be able to accept your
                invitation to join your plan on the adasThink portal's home page.
            </p>
            <p>
                <i>Users you add to your plan will contribute to your usage when they upload an estimate.</i>
            </p>
            <form onSubmit={onSubmit}>
                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="invitation-email-text-box"
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Email"
                            onChange={value => updateEmail(value.trim())}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                    </div>
                </div>
                {errorMessage.length > 0
                    && (
                        <div className="notification is-warning" data-testid="invitation-error-message">
                            {errorMessage}
                        </div>
                    )}
                <div className="field is-grouped">
                    <div className="control">
                        <Button
                            data-testid="send-invitation-button"
                            type="submit"
                            kind="primary"
                            disabled={isSending}
                        >
                            Send Invitation
                        </Button>
                    </div>
                    <div className="control">
                        <Button
                            data-testid="cancel-invitation-button"
                            disabled={isSending}
                            onClick={e => {
                                e.preventDefault();
                                onCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );

    function updateEmail(email: string) {
        setEmail(email);
        setErrorMessage("");
    }

    async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const emails = email.split(/[,;]/).filter(e => e.trim().length > 0);
        if (emails.length > 1) {
            setErrorMessage("Please only provide one email address at a time.");
        } else if (emails.length !== 1 || !emails.every(isValidEmail)) {
            setErrorMessage("Please provide a valid email address.");
        } else {
            setIsSending(true);
            try {
                const result = await Api.sendCustomerUserInvitation({ email: emails.join(",") });
                if (result.error) {
                    setErrorMessage(result.error);
                } else {
                    onEmailAdded(email);
                    Notification.show("Sent invitation.");
                }
            } catch (err) {
                console.error("Error sending invitation.", err);
                setErrorMessage("There was an unknown error sending the invitation.");
            } finally {
                setIsSending(false);
            }
        }
    }
}

function renderUsers(
    details: CustomerUserManagementPageViewDetails,
    onEmailsChanged: (emails: string[]) => void,
    onUserRemoved: (userId: Guid) => void,
) {
    return (
        <table className="table" data-testid="users-table">
            <thead>
                <td>Name</td>
                <td>Email</td>
                <td>Shop</td>
                <td className="has-text-centered">Month Usage</td>
                <td></td>
            </thead>
            <tbody>
                <>
                    {details.users.map(user => {
                        return (
                            <tr
                                key={user.userId}
                                data-test-group="user-row"
                                data-test-selector={user.email}
                            >
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td data-testid="user-row-email-cell">{user.email}</td>
                                <td>{user.shopName}</td>
                                <td className="has-text-centered">{user.usageCount}</td>
                                <td className="has-text-right">
                                    {user.userId !== details.currentUserId && (
                                        <Button
                                            size="small"
                                            kind="warning"
                                            data-testid="remove-user-button"
                                            onClick={() => removeUser(user)}
                                        >
                                            Remove
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </>
                <>
                    {details.invitationEmails.map((email, i) => {
                        return (
                            <tr key={i} data-test-group="user-invitation-row" data-test-selector={email}>
                                <td>--</td>
                                <td data-testid="user-invitation-email-cell">{email}</td>
                                <td>--</td>
                                <td className="has-text-centered">0</td>
                                <td className="has-text-right">
                                    <Button
                                        size="small"
                                        kind="warning"
                                        data-testid="user-invitation-row-cancel-invitation-button"
                                        onClick={() => onCancel(email)}
                                    >
                                        Cancel Invitation
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </>
            </tbody>
        </table>
    );

    async function removeUser(user: CustomerUserManagementPageViewUser) {
        const result = await Modal.confirm(
            `Are you sure you want to remove ${user.email} from `
                + `this plan?\n\nNote: Removing a user will not reduce your plan's usage.`,
        );
        if (result) {
            await Api.removeCustomerUser({ userId: user.userId });
            onUserRemoved(user.userId);
            Notification.show("Removed user.");
        }
    }

    async function onCancel(email: string) {
        const result = await Modal.confirm(`Are you sure you want to cancel the invitation to ${email}?`);
        if (result) {
            await Api.deleteCustomerUserInvitation({ email });

            // update the ui
            const index = details.invitationEmails.indexOf(email);
            if (index >= 0) {
                const emails = [
                    ...details.invitationEmails.slice(0, index),
                    ...details.invitationEmails.slice(index + 1),
                ];
                onEmailsChanged(emails);
            }
        }
    }
}
