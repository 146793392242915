import React from "react";

export function Pager({
    pageIndex,
    pageCount,
    onPageChange,
}: {
    pageIndex: number;
    pageCount: number;
    onPageChange: (index: number) => void;
}) {
    if (pageCount <= 1) {
        return <></>;
    }

    return (
        <nav className="pagination" role="navigation" aria-label="pagination">
            <button
                onClick={() => onPageChange(pageIndex - 1)}
                className="button pagination-previous"
                disabled={pageIndex === 0}
            >
                Previous
            </button>
            <button
                onClick={() => onPageChange(pageIndex + 1)}
                className="button pagination-next"
                disabled={pageIndex >= pageCount - 1}
            >
                Next
            </button>
            <ul className="pagination-list">
                {pageCount <= 7
                    ? renderSevenAndUnder()
                    : pageIndex < 3
                    ? renderOnLeft()
                    : pageIndex > pageCount - 4
                    ? renderOnRight()
                    : renderMiddle()}
            </ul>
        </nav>
    );

    function renderSevenAndUnder() {
        const buttons = [];
        for (let i = 0; i < pageCount; i++) {
            buttons.push(renderButton(i));
        }
        return buttons;
    }

    function renderOnLeft() {
        return (
            <>
                {renderButton(0)}
                {renderButton(1)}
                {renderButton(2)}
                {renderButton(3)}
                {renderEllipsis()}
                {renderButton(pageCount - 2)}
                {renderButton(pageCount - 1)}
            </>
        );
    }

    function renderOnRight() {
        return (
            <>
                {renderButton(0)}
                {renderButton(1)}
                {renderEllipsis()}
                {renderButton(pageCount - 4)}
                {renderButton(pageCount - 3)}
                {renderButton(pageCount - 2)}
                {renderButton(pageCount - 1)}
            </>
        );
    }

    function renderMiddle() {
        return (
            <>
                {renderButton(0)}
                {renderEllipsis()}
                {renderButton(pageIndex - 1)}
                {renderButton(pageIndex)}
                {renderButton(pageIndex + 1)}
                {renderEllipsis()}
                {renderButton(pageCount - 1)}
            </>
        );
    }

    function renderButton(index: number) {
        const isCurrent = index === pageIndex;
        return (
            <li>
                <button
                    className={"button pagination-link" + (isCurrent ? " is-current" : "")}
                    aria-label={`Goto page ${index + 1}`}
                    onClick={() => onPageChange(index)}
                    area-current={isCurrent ? "page" : ""}
                >
                    {index + 1}
                </button>
            </li>
        );
    }

    function renderEllipsis() {
        return (
            <li>
                <span className="pagination-ellipsis">&hellip;</span>
            </li>
        );
    }
}
