import {
    Guid,
    Instant,
    StateCodes,
    CountryCodes,
    UploadDisabledReason,
    ReportSuccessResponse,
    axiosGet,
    axiosPost,
    VIN,
} from '@adas/shared-types';

export interface AddEditCustomerView {
    details: 'notOwner' | AddEditCustomerViewOwnerDetails;
}

export interface AddEditCustomerViewOwnerDetails {
    stripeCustomerId: string | undefined;
    customer: AddEditCustomerMessage | undefined;
    cardView: AddEditCreditCardView;
}

export interface AddEditCustomerMessage {
    fullName: string;
    address: Address;
    phone: string;
    email: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: CountryCodes;
    state: StateCodes;
    zip: string;
}

export interface AddEditCreditCardView {
    stripePublicKey: string;
    isTestApi: boolean;
    card: { last4: string; stripePaymentMethodId: string; } | undefined;
}

export interface AddEditCustomerResponse {
    stripeError?: string | undefined;
}

export interface PlansPageView {
    planId: Guid | undefined;
    plans: 'notOwner' | PlansPagePlanView[];
}

export interface PlansPagePlanView {
    planId: Guid;
    name: string;
    estimatesPerMonth: number | undefined;
    isYearly: boolean;
    isPublic: boolean;
    price: PlansPagePlanPriceView | undefined;
}

export interface PlansPagePlanPriceView {
    priceInCents: number;
    subTotalInCents: number;
    currency: string;
    taxLineItems: PlanTaxLineItem[];
    discount: StripeDiscount | undefined;
}

export interface PlanTaxLineItem {
    name: string;
    percentage: number;
    taxInCents: number;
}

export interface StripeDiscount {
    stripeCouponId: string;
    discountPercentage: number;
    name: string;
}

export interface PlanPageView {
    currentPlanId: Guid | undefined;
    hasCreditCardOnFile: boolean;
    plan: 'notFound' | 'notOwner' | PlansPagePlanView;
}

export interface PurchasePlanMessage {
    planId: Guid;
    couponCode: string | undefined;
}

export interface PurchasePlanResponse {
    error?: { stripeError: boolean; message: string; } | undefined;
}

export interface CustomerUserInvitationManagementMessage {
    email: string;
}

export interface ApiActionResponse {
    error: string | undefined;
}

export interface AccountPageView {
    ownerName: string;
    ownerDetails: 'notOwner' | AccountPageViewOwnerDetails;
    userReportsUsedCount: number;
    isApiUser: boolean;
}

export interface AccountPageViewOwnerDetails {
    currentPlanId: Guid | undefined;
    planName: string;
    expiryDate: Instant | undefined;
    hasBillingInformation: boolean;
    usage: UsageInfoForAccountOwner;
    usersCount: number | 'notAvailable';
}

export interface UsageInfoForAccountOwner {
    usedCount: number;
    allowedCount: number | undefined;
    expiryDate: Instant | undefined;
    uploadDisabledReason: UploadDisabledReason | undefined;
}

export interface ApiDetailsPageView {
    userId: Guid;
    apiKey1: string;
    apiKey2: string;
}

export interface EstimateUploadAvailability {
    isEnabled: boolean;
    disabledReason: UploadDisabledReason | undefined;
    message: string;
}

export interface ReportsListResponse {
    isOwnerUserWithPlanUsers: boolean;
    rowCount: number;
    reports: ReportResponse[];
}

export interface ReportResponse {
    reportId: Guid;
    createdDate: Instant;
    year: string;
    make: string;
    model: string;
    trim: string | undefined;
    vin: string;
    email: string | undefined;
    shopName: string | undefined;
}

export interface GetReportResponse {
    response: ReportSuccessResponse;
    reportUser: GetReportResponseUser;
}

export interface GetReportResponseUser {
    firstName: string;
    lastName: string;
    email: string;
    shopName: string;
    isCurrentUser: boolean;
}

export interface UpdateReportTrimMessage {
    reportId: Guid;
    trim: string | undefined;
}

export interface UpdateReportSensorMessage {
    reportId: Guid;
    sensorId: Guid;
    isOnVehicle: boolean;
}

export interface UserProfileResponse {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    shopName: string;
    shopAddress: Address;
    coverageIndicatorEnabled: boolean;
}

export interface UserProfileMessage {
    firstName: string;
    lastName: string;
    phone: string;
    shopName: string;
    shopAddress: Address;
}

export interface GetStripeSetupIntentSecretResponse {
    secret: string;
}

export interface UpdateCreditCardMessage {
    stripePaymentMethodId: string;
}

export interface UpdateDevCreditCardMessage {
    stripePaymentMethodId: string;
    last4: string;
}

export interface CustomerUserInvitationsListResponse {
    isUserOwnerOfPaidNonExpiringPlanOrCustomerWithOtherUsers: boolean;
    invitations: CustomerUserInvitationsListResponseInvitation[];
}

export interface CustomerUserInvitationsListResponseInvitation {
    customerId: Guid;
    name: string;
}

export interface CustomerUserInvitationRespondMessage {
    customerId: Guid;
    accept: boolean;
}

export interface CustomerUserRemoveMessage {
    userId: Guid;
}

export interface CustomerUserManagementPageView {
    details: 'notOwner' | 'notPaidCustomer' | CustomerUserManagementPageViewDetails;
}

export interface CustomerUserManagementPageViewDetails {
    currentUserId: Guid;
    users: CustomerUserManagementPageViewUser[];
    invitationEmails: string[];
    customerName: string;
    canAddUser: boolean;
}

export interface CustomerUserManagementPageViewUser {
    userId: Guid;
    firstName: string;
    lastName: string;
    email: string;
    shopName: string;
    usageCount: number;
}

export class Api {
    static getCustomer = () => axiosGet<AddEditCustomerView>('/api/customer/view');

    static addEditCustomer = (message: AddEditCustomerMessage) => axiosPost<AddEditCustomerMessage,
    AddEditCustomerResponse>('/api/customer/addEdit', message);

    static getPlansView = () => axiosGet<PlansPageView>('/api/plans');

    static getPlanView = (params: {
        planId: Guid;
        couponCode?: string;
    }) => axiosGet<PlanPageView>('/api/plan', { params });

    static purchasePlan = (message: PurchasePlanMessage) => axiosPost<PurchasePlanMessage,
    PurchasePlanResponse>('/api/plan/purchase', message);

    static cancelPlan = () => axiosPost<undefined, PurchasePlanResponse>('/api/plan/cancel', undefined);

    static checkCouponCode = (message: { couponCode: string; }) => axiosPost<{ couponCode: string; },
    boolean>('/api/plan/coupon-code-check', message);

    static sendCustomerUserInvitation =
    (message: CustomerUserInvitationManagementMessage) => axiosPost<CustomerUserInvitationManagementMessage,
    ApiActionResponse>('/api/customer-user-invitation/send', message);

    static deleteCustomerUserInvitation =
    (message: CustomerUserInvitationManagementMessage) => axiosPost<CustomerUserInvitationManagementMessage,
    boolean>('/api/customer-user-invitation/delete', message);

    static getAccountView = () => axiosGet<AccountPageView>('/api/account-view');

    static getApiDetails = () => axiosGet<ApiDetailsPageView | undefined>('/api/api-details');

    static regenerateApiKey1 = () => axiosPost<undefined, boolean>('/api/api-key-1-regenerate', undefined);

    static regenerateApiKey2 = () => axiosPost<undefined, boolean>('/api/api-key-2-regenerate', undefined);

    static getEstimateUploadAvailability =
    () => axiosGet<EstimateUploadAvailability>('/api/estimate-upload-availability');

    static listReports = (params: {
        searchText: string;
        pageIndex: number;
        showAllCustomerUserReports: boolean;
    }) => axiosGet<ReportsListResponse>('/api/list-reports', { params });

    static getCustomReport = (params: { vin: VIN }) => axiosPost<{ vin: VIN }, GetReportResponse>('/api/custom-report', params);

    static getReport = (params: {
        reportId: Guid;
    }) => axiosGet<GetReportResponse>('/api/get-report', { params });

    static updateReportTrim = (message: UpdateReportTrimMessage) => axiosPost<UpdateReportTrimMessage,
    boolean>('/api/update-report-trim', message);

    static updateReportSensor = (message: UpdateReportSensorMessage) => axiosPost<UpdateReportSensorMessage,
    boolean>('/api/update-report-sensor', message);

    static getUserProfile = () => axiosGet<UserProfileResponse>('/api/user-profile');

    static updateUserProfile = (message: UserProfileMessage) => axiosPost<UserProfileMessage,
    ApiActionResponse>('/api/user-profile/update', message);

    static getStripeSetupIntentSecret =
    () => axiosGet<GetStripeSetupIntentSecretResponse>('/api/stripe-setup-intent-secret');

    static updateCreditCard = (message: UpdateCreditCardMessage) => axiosPost<UpdateCreditCardMessage,
    boolean>('/api/update-credit-card', message);

    static updateDevCreditCard = (message: UpdateDevCreditCardMessage) => axiosPost<UpdateDevCreditCardMessage,
    boolean>('/api/update-dev-credit-card', message);

    static getCustomerName = () => axiosGet<string>('/api/get-customer-name');

    static listCustomerUserInvitations =
    () => axiosGet<CustomerUserInvitationsListResponse>('/api/list-customer-user-invitations');

    static respondCustomerUserInvitation =
    (message: CustomerUserInvitationRespondMessage) => axiosPost<CustomerUserInvitationRespondMessage,
    boolean>('/api/respond-customer-user-invitation', message);

    static removeCustomerUser = (message: CustomerUserRemoveMessage) => axiosPost<CustomerUserRemoveMessage,
    boolean>('/api/remove-customer-user', message);

    static getCustomerUserManagementPageView =
    () => axiosGet<CustomerUserManagementPageView>('/api/customer-user-management-view');
}
