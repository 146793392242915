import React from "react";
import { getHtmlProps } from "./getHtmlProps";

export interface CheckBoxProps {
    className?: string;
    onChange?: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
}

export function CheckBox(props: CheckBoxProps) {
    return (
        <input
            type="checkbox"
            disabled={props.disabled}
            checked={props.checked}
            onChange={(event) => props.onChange?.(event.target.checked)}
            {...getHtmlProps(props)}
        />
    );
}
