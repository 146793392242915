import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { NewUserPermissions, UserPermissions } from '@adas/shared-types';
import UseAppToken from '../AuthenticationHooks/UseAppToken';

const getUserPermissionsQuery = gql`
    query GetUserPermissions {
        userPermissions
        @rest(
            type: "UserPermissionsResponse",
            method: "GET",
            path: "api/user-permissions"
        ) {
            permissions
            newPermissions
            isEnabled
        }
    }
`;
interface UserPermissionsResponse {
    userPermissions: {
        permissions: UserPermissions;
        newPermissions: NewUserPermissions;
        isEnabled: boolean;
    }
}

const GetUserPermissions = (options: QueryHookOptions<UserPermissionsResponse> = {}) => {
    const [appToken] = UseAppToken();
    const response = useQuery<UserPermissionsResponse>(getUserPermissionsQuery, {
        ...options,
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        skip: !appToken,
    });
    return [response.data?.userPermissions, response] as const;
};

export default GetUserPermissions;
