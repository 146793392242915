import React, { useEffect, useState } from "react";
import { Api } from "../../ClientServerApi.generated";
import { LoadingBar } from "../components";

export function AreaManagedByAccountOwnerMessage() {
    const [customerName, setCustomerName] = useState<string | undefined>(undefined);

    useEffect(() => {
        Api.getCustomerName().then(customerName => {
            setCustomerName(customerName);
        });
    }, []);

    if (customerName == null) {
        return <LoadingBar />;
    }

    return (
        <div className="notification" data-testid="area-managed-by-account-owner-message">
            <h3 className="title">Details Managed By {customerName}</h3>
            <p>
                These details are managed by {customerName} for your account.
            </p>
        </div>
    );
}
