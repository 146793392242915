import React, { useMemo } from 'react';
import { EstimateHoverImageItem } from '@adas/shared-types';
import getRenderedEstimateHoverImage from '../../../utils/getRenderedEstimateHoverImage';

const RenderedImage = (prop: { image: EstimateHoverImageItem }) => {
    const renderedUri = useMemo(() => {
        try {
            return getRenderedEstimateHoverImage(prop.image);
        } catch (err) {
            console.error('Error rendering scene.', err);
            return undefined;
        }
    }, [prop.image]);

    return <img alt="" src={renderedUri} />;
};

export default RenderedImage;
