import React from "react";

export function HowToSaveEstimates() {
    return (
        <>
            <header>
                <h1 className="title">Getting Started—Uploading Estimates to adasThink</h1>
            </header>
            <p>
                Uploading estimates to adasThink is easy. But if you're having issues uploading estimates to adasThink,
                this guide should help you! When you're finished writing your estimate, follow these steps:
            </p>
            <h3>Audatex</h3>
            <p>
                Step 1: Click on "Estimate Reports" in the sidebar, just above the "Forms" button.
            </p>
            <HelpImage
                src="audatex-01-estimate-reports.png"
                alt="Click estimate reports."
                width={257}
                height={478}
            />
            <p>
                <b>Tip:</b> Do not select "Print/Email" or "Estimate Report" under the "Damage" tab.
            </p>
            <p>
                Step 2: Select the "Save" button that appears in the toolbar when you bring your mouse near the bottom
                of the screen. <b>Do not</b> select the "Print" button.
            </p>
            <HelpImage
                src="audatex-02-save-form.png"
                alt="Click the save button"
                width={310}
                height={195}
            />
            <p>
                Step 3: Save your file and upload it to adasThink. You're done!
            </p>

            <h3>CCC</h3>
            <p>Step 1: Select the "Print" button.</p>
            <HelpImage
                src="ccc-01-print-button.png"
                alt="Click the print button"
                width={195}
                height={80}
            />
            <p>Step 2: Select "Microsoft Print to PDF" as your printer.</p>
            <HelpImage
                src="ccc-02-print-to-pdf.png"
                alt="Select Microsoft print to PDF"
                width={369}
                height={176}
            />
            <p>Step 3: Save your file and upload it to adasThink. You're done!</p>

            <h3>Mitchell Ultramate</h3>
            <p>Step 1: Select the "Print Estimate" button.</p>
            <HelpImage
                src="mitchell-01-print-button.png"
                alt="Select the print estimate button"
                width={225}
                height={118}
            />
            <p>Step 2: Click the Print button.</p>
            <p>Step 3: Select "Microsoft Print to PDF" as your printer.</p>
            <HelpImage
                src="mitchell-02-print-to-pdf.png"
                alt="Select Microsoft print to PDF"
                width={220}
                height={169}
            />
            <p>Step 4: Save your file and upload it to adasThink. You're done!</p>

            <h3>More questions?</h3>
            <p>
                If you're still having difficulty, get in touch with us at{" "}
                <a href="mailto:customerservice@astech.com">customerservice@astech.com</a> or give us a call at 1 (877) 410-ADAS (2327).
            </p>
            <p>
                Thanks for reading our guide!
            </p>
        </>
    );

    function HelpImage({ src, alt, width, height }: { src: string; alt: string; width: number; height: number }) {
        return (
            <img
                src={`/images/help/how-to-save-estimates/${src}`}
                alt={alt}
                width={width}
                height={height}
            />
        );
    }
}
