import { isEqual } from 'lodash';
import {
    answersChanged, stepToIndex,
} from './helpers';
import {
    QuestionnaireAnswerState, QuestionnaireState, Steps,
} from './types';
import { QuestionnaireAction } from './actionCreators';
import getNextStep from './utils/getNextStep';
import getPreviousStep from './utils/getPreviousStep';
import cleanupStateAfterExchange from './utils/cleanupStateAfterExchange';

export const initialState: QuestionnaireState = {
    currentStep: 0,
    loading: false,
    error: false,
    savedAnswers: {
        vin: undefined,
        trim: undefined,
        sensors: {},
        carParts: {},
    },
    draftAnswers: {
        vin: undefined,
        trim: undefined,
        sensors: {},
        carParts: {},
    },
    trimQuestion: undefined,
    sensorQuestions: undefined,
    carPartQuestions: undefined,
    triggerExchange: false,
};

export const questionnaireReducer = (state: QuestionnaireState, action: QuestionnaireAction): QuestionnaireState => {
    switch (action.type) {
        case 'select-vin': {
            const draftAnswers: QuestionnaireAnswerState = { ...state.draftAnswers, vin: action.payload };
            const hasChanges = answersChanged(draftAnswers, state.savedAnswers);
            return {
                ...state,
                triggerExchange: hasChanges,
                currentStep: hasChanges ? state.currentStep : getNextStep(state),
                draftAnswers,
            };
        }
        case 'select-trim': {
            const draftAnswers: QuestionnaireAnswerState = { ...state.draftAnswers, trim: action.payload };
            const hasChanges = answersChanged(draftAnswers, state.savedAnswers);
            return {
                ...state,
                triggerExchange: hasChanges,
                currentStep: hasChanges ? state.currentStep : getNextStep(state),
                draftAnswers,
            };
        }
        case 'select-sensors': {
            const draftAnswers: QuestionnaireAnswerState = { ...state.draftAnswers, sensors: action.payload };
            const hasChanges = answersChanged(draftAnswers, state.savedAnswers);
            return {
                ...state,
                triggerExchange: hasChanges,
                currentStep: hasChanges ? state.currentStep : getNextStep(state),
                draftAnswers,
            };
        }
        case 'select-car-parts': {
            const draftAnswers: QuestionnaireAnswerState = { ...state.draftAnswers, carParts: action.payload };
            const hasChanges = answersChanged(draftAnswers, state.savedAnswers);
            return {
                ...state,
                triggerExchange: hasChanges,
                currentStep: hasChanges ? state.currentStep : getNextStep(state),
                draftAnswers,
            };
        }
        case 'navigate-back': {
            return { ...state, currentStep: getPreviousStep(state) };
        }
        case 'loading-questionnaire-exchange': {
            const vinUpdated = state.draftAnswers.vin !== state.savedAnswers.vin;
            const trimUpdated = state.draftAnswers.trim !== state.savedAnswers.trim;
            const sensorsUpdated = !isEqual(state.draftAnswers.sensors, state.savedAnswers.sensors);

            const newDraftAnswers: QuestionnaireAnswerState = {
                vin: state.draftAnswers.vin,
                trim: vinUpdated ? undefined : state.draftAnswers.trim,
                sensors: (trimUpdated || vinUpdated) ? {} : state.draftAnswers.sensors,
                carParts: (sensorsUpdated || trimUpdated || vinUpdated) ? {} : state.draftAnswers.carParts,
            };
            return {
                ...state,
                loading: true,
                triggerExchange: false,
                error: false,
                draftAnswers: newDraftAnswers,
            };
        }
        case 'exchange-error': {
            return {
                ...state, loading: false, error: true, draftAnswers: state.savedAnswers,
            };
        }
        case 'add-trim-question': {
            return {
                ...(cleanupStateAfterExchange(state, action.payload.trigger, Steps.TRIM)),
                loading: false,
                triggerExchange: false,
                currentStep: stepToIndex(Steps.TRIM),
                trimQuestion: action.payload.question,
            };
        }
        case 'add-sensor-questions': {
            return {
                ...(cleanupStateAfterExchange(state, action.payload.trigger, Steps.SENSOR)),
                loading: false,
                triggerExchange: false,
                currentStep: stepToIndex(Steps.SENSOR),
                sensorQuestions: action.payload.questions,
            };
        }
        case 'add-car-parts-questions': {
            return {
                ...(cleanupStateAfterExchange(state, action.payload.trigger, Steps.CAR_PART)),
                loading: false,
                triggerExchange: false,
                currentStep: stepToIndex(Steps.CAR_PART),
                carPartQuestions: action.payload.questions,
            };
        }
        case 'complete-exchange': {
            return {
                ...(cleanupStateAfterExchange(state, action.payload.trigger, Steps.CONFIRM)),
                loading: false,
                triggerExchange: false,
                currentStep: stepToIndex(Steps.CONFIRM),
            };
        }
        default:
            return initialState;
    }
};
