import React from 'react';
import { SensorOverride, SensorSelection, Guid } from '@adas/shared-types';
import { HoverItemTooltip } from './HoverTooltips';

const SensorSelections = (
    {
        sensorSelections,
        sensorOverrides,
        allowEdit,
        updateIsSensorOnVehicle,
    }:
    {
        sensorSelections: SensorSelection[],
        sensorOverrides: SensorOverride[],
        allowEdit: boolean,
        updateIsSensorOnVehicle: (sensorId: Guid, isOnVehicle: boolean) => void
    },
) => {
    if (sensorSelections.length === 0) {
        return <></>;
    }
    const isSingular = sensorSelections.length === 1;
    const isAllAnswered = sensorSelections.every(
        (sensorSelection) => sensorOverrides.find((s) => s.sensorId === sensorSelection.sensorId) != null,
    );
    return (
        <div className={`message${isAllAnswered ? '' : ' is-danger'}`}>
            <div className="message-header">
                <p>
                    {isAllAnswered ? <></> : (
                        <>
                            <span className="icon has-text-warning">
                                <i className="fas fa-exclamation-triangle" />
                            </span>
                            {' '}
                        </>
                    )}
                    {isSingular ? 'Potential Calibration' : 'Potential Calibrations'}
                </p>
            </div>
            <div className="message-body">
                <p>
                    {isSingular
                        ? 'adasThink identified a potential calibration on an optional sensor.'
                        : 'adasThink identified potential calibrations on optional sensors.'}
                </p>
                {sensorSelections.map((sensorSelection, i) => {
                    const sensorOverride = sensorOverrides.find((s) => s.sensorId === sensorSelection.sensorId);
                    const isYes = sensorOverride?.isOnVehicle ?? false;
                    const isNo = sensorOverride != null && !sensorOverride.isOnVehicle;
                    const toolTipName = `sensor-selection-${i}`;
                    const toolTipProps = sensorSelection.hoverItem == null ? {} : {
                        'data-tip': true,
                        'data-for': toolTipName,
                    };
                    return (
                        <div
                            className="field is-grouped"
                            key={sensorSelection.sensorId}
                            data-test-group="potential-calibration"
                            data-test-selector={sensorSelection.sensorName}
                            data-is-on-vehicle={sensorOverride?.isOnVehicle}
                        >
                            <HoverItemTooltip id={toolTipName} image={sensorSelection.hoverItem} />
                            <p className="control">
                                <button
                                    data-testid="potential-calibration-yes-button"
                                    className={`button is-small ${isYes ? ' is-info' : ''}`}
                                    onClick={() => updateIsSensorOnVehicle(sensorSelection.sensorId, true)}
                                    disabled={!allowEdit}
                                    type="button"
                                >
                                    Yes
                                </button>
                            </p>
                            <p className="control">
                                <button
                                    data-testid="potential-calibration-no-button"
                                    className={`button is-small ${isNo ? ' is-danger' : ''}`}
                                    onClick={() => updateIsSensorOnVehicle(sensorSelection.sensorId, false)}
                                    disabled={!allowEdit}
                                    type="button"
                                >
                                    No
                                </button>
                            </p>
                            <p>
                                Is the vehicle equipped with
                                {' '}
                                <span
                                    data-testid="potential-calibration-name"
                                    data-tip={toolTipProps['data-tip']}
                                    data-for={toolTipProps['data-for']}
                                >
                                    {sensorSelection.sensorName}
                                </span>
                                ?
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SensorSelections;
