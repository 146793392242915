import {
    gql, QueryHookOptions, useLazyQuery,
} from '@apollo/client';
import { Questionnaire, QuestionnaireAnswers } from './types';

export const QUESTIONNAIRE_EXCHANGE_QUERY = gql`
    query QuestionnaireExchange($questionnaireAnswers: QuestionnaireAnswers!) {
        questionnaireExchange(input: $questionnaireAnswers)
        @rest(
            type: "QuestionnaireExchange",
            method: "POST",
            path: "api/questionnaire/exchange"
        ) {
            type
            questions
        }
    }
`;

export type RequestBody = {
    questionnaireAnswers: QuestionnaireAnswers
};

export type DataType = {
    questionnaireExchange: Questionnaire
};

const UseQuestionnaireExchange = (options: QueryHookOptions<DataType, RequestBody> = {}) => (
    useLazyQuery<DataType, RequestBody>(QUESTIONNAIRE_EXCHANGE_QUERY, options)
);

export default UseQuestionnaireExchange;
