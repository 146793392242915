import { ReportSuccessResponse, ReportUploadUser } from '@adas/shared-types';

export interface ReportUploadSuccessResponse {
    response: ReportSuccessResponse;
    user: ReportUploadUser;
}

export class LastUploadedReportSuccessResponseStore {
    private lastUploadedReportSucessResponse: ReportUploadSuccessResponse | undefined;

    static readonly instance = new LastUploadedReportSuccessResponseStore();

    set(report: ReportUploadSuccessResponse) {
        this.lastUploadedReportSucessResponse = report;
    }

    take() {
        const response = this.lastUploadedReportSucessResponse;
        this.lastUploadedReportSucessResponse = undefined;
        return response;
    }
}
