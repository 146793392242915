import { charHelpers } from "./utils";

/** A vehicle identification number. */
export interface VIN {
    readonly text: string;
    readonly wmi: string;
    readonly vehicleDescriptor: string;
    readonly checkDigit: string | undefined;
    readonly modelYear: string;
    readonly plantCode: string;
    readonly serialNumber: string;
}

// Resources
// * https://en.wikibooks.org/wiki/Vehicle_Identification_Numbers_(VIN_codes)/Check_digit
// * https://en.wikipedia.org/wiki/Vehicle_identification_number#North_American_check_digits
// * https://www.iso.org/standard/52200.html

/** Finds the VINS in the provided text. */
export function findVins(text: string): VIN[] | undefined {
    if (text.length < 17) {
        return undefined;
    }
    text = text.toUpperCase();

    let vins: VIN[] | undefined;
    let i = 0;

    for (i = 0; i < text.length; i++) {
        const wasLastWordBarrier = i === 0 || !charHelpers.isAlphaNumeric(text[i - 1]);
        if (!wasLastWordBarrier) {
            continue;
        }

        const vin = tryGetVin();

        if (vin != null) {
            const isNextTextBarrier = i === text.length || !charHelpers.isAlphaNumeric(text[i]);
            if (isNextTextBarrier) {
                if (vins == null) {
                    vins = [];
                }
                vins.push(vin);
            }
        }
    }

    return vins;

    function tryGetVin() {
        const startIndex = i;
        // World manufacturer identifier: Three char, alpha-numeric, excludes I, O, & Q
        const wmi = getAlphaNumeric(3);
        if (wmi == null) {
            return undefined;
        }
        // Vehicle descriptor: Five char, excludes I, O, & Q. Could be 6 if no check digit
        const vehicleDescriptor = getAlphaNumeric(5);
        if (vehicleDescriptor == null) {
            return undefined;
        }
        // Check digit
        const checkDigit = getCheckDigit();
        i++;
        if (checkDigit == null) {
            return undefined;
        }
        // Model year
        const modelYear = getModelYear();
        i++;
        if (modelYear == null) {
            return undefined;
        }
        // Plant code: One char, alpha-numeric, excludes I, O, Q
        const plantCode = getAlphaNumeric(1);
        if (plantCode == null) {
            return undefined;
        }
        // Serial number: Six char, alpha-numeric, excludes I, O, Q
        const serialNumber = getAlphaNumeric(6);
        if (serialNumber == null) {
            return undefined;
        }

        const vinText = sanitizeTextForVin(text.substring(startIndex, i));
        if (isAllNumbers(vinText)) {
            return undefined;
        }

        const vin: VIN = {
            text: vinText,
            wmi: sanitizeTextForVin(wmi),
            vehicleDescriptor: sanitizeTextForVin(vehicleDescriptor),
            checkDigit: sanitizeTextForVin(checkDigit),
            modelYear: sanitizeTextForVin(modelYear),
            plantCode: sanitizeTextForVin(plantCode),
            serialNumber: sanitizeTextForVin(serialNumber),
        };
        return vin;
    }

    function getCheckDigit() {
        // Single numeric or 'X'
        const char = text[i];
        if (charHelpers.isNumeric(char) || char === "X") {
            return text[i];
        } else {
            return undefined;
        }
    }

    function getModelYear() {
        // Model year: One char, alpha-numeric, excludes I, O, Q, U, Z, 0
        const char = text[i];
        if (!charHelpers.isAlphaNumeric(char)) {
            return undefined;
        }
        if (char === "I" || char === "O" || char === "Q" || char === "U" || char === "Z" || char === "0") {
            return undefined;
        }
        return text[i];
    }

    function getAlphaNumeric(count: number) {
        let startPos = i;
        let endPos = i + count;
        for (; i < endPos; i++) {
            const currentChar = text[i];
            if (!charHelpers.isAlphaNumeric(currentChar)) {
                return undefined;
            }
            // don't bother checking for "o" (replace it with "0" later)
            if (currentChar === "Q" || currentChar === "I") {
                return undefined;
            }
        }
        return text.substring(startPos, i);
    }
}

function isAllNumbers(text: string) {
    for (let i = 0; i < text.length; i++) {
        let charCode = text.charCodeAt(i);
        const isNumber = charCode >= 48 && charCode <= 57;
        if (!isNumber) {
            return false;
        }
    }
    return true;
}

export function isValidVin(text: string) {
    try {
        parseVin(text);
        return true;
    } catch {
        return false;
    }
}

/** Parses a single VIN out of the provided text. */
export function parseVin(text: string) {
    const vins = findVins(text);
    if (vins == null || vins.length === 0) {
        throw new Error("Could not find VIN in text.");
    }
    if (vins.length > 1) {
        throw new Error("Please provide some text that only has one VIN in it.");
    }
    return vins[0];
}

export function checkVin(text: string) {
    text = sanitizeTextForVin(text);

    if (text.length > 17) {
        return false;
    }

    const products: number[] = [];
    const checkDigit = text[8] === "X" ? 10 : parseInt(text[8], 10);

    // could happen for non-north american cars that don't have a check digit
    if (isNaN(checkDigit)) {
        return false;
    }

    for (let i = 0; i < text.length; i++) {
        if (i == 8) {
            continue; // skip check digit
        }

        const value = decodeChar(text[i]);
        if (value == null) return false;
        const weight = indexToWeight(i);
        const product = value * weight;

        products.push(product);
    }
    const sum = products.reduce((a, b) => a + b, 0);
    return sum % 11 == checkDigit;
}

function decodeChar(char: string): number | undefined {
    char = char.toUpperCase()[0];

    // dprint-ignore
    switch (char) {
        case "A": return 1;
        case "B": return 2;
        case "C": return 3;
        case "D": return 4;
        case "E": return 5;
        case "F": return 6;
        case "G": return 7;
        case "H": return 8;
        case "J": return 1;
        case "K": return 2;
        case "L": return 3;
        case "M": return 4;
        case "N": return 5;
        case "P": return 7;
        case "R": return 9;
        case "S": return 2;
        case "T": return 3;
        case "U": return 4;
        case "V": return 5;
        case "W": return 6;
        case "X": return 7;
        case "Y": return 8;
        case "Z": return 9;
        case "0": return 0;
        case "1": return 1;
        case "2": return 2;
        case "3": return 3;
        case "4": return 4;
        case "5": return 5;
        case "6": return 6;
        case "7": return 7;
        case "8": return 8;
        case "9": return 9;
        default: return undefined;
    }
}

const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
function indexToWeight(index: number) {
    if (index >= weights.length) {
        throw new Error(
            `Invalid index to weight. Expected an index less than ${weights.length}, but ${index} was provided.`,
        );
    }
    return weights[index] as number;
}

function sanitizeTextForVin(text: string) {
    // replace all "o"s with a "0"
    return text.replace(/[oO]/g, "0").trim();
}
