export const identifiers = {
    modal: {
        message: "modalMessage",
        inputTextBox: "modalInputTextBox",
        yesButton: "modalYesButton",
        noButton: "modalNoButton",
        okButton: "modalOkButton",
        closeButton: "modalCloseButton",
        cancelButton: "modalCancelButton",
    },
};
