import React from 'react';
import {
    Box, Button, Container, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { UseAzure } from '@repairify/react-auth';

const UnauthorizedUserPage = () => {
    const { logoutUser } = UseAzure();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <Typography variant="h1">
                            403
                        </Typography>
                        <Typography variant="h6">
                            You are not authorized to access these pages. Please contact an administrator to grant you access.
                        </Typography>
                        <Button variant="contained" onClick={() => logoutUser()}>Return to Login</Button>
                    </Grid>
                    <Grid xs={6}>
                        <img
                            src="/images/door-green-closed-lock.jpg"
                            alt=""
                            width={500}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default UnauthorizedUserPage;
