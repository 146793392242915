import { Guid, isGuid, routes } from '@adas/shared-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Api, GetReportResponse } from '../ClientServerApi.generated'
import { LastUploadedReportSuccessResponseStore } from '../utils/LastUploadedReportSuccessResponseStore'
import { LoadingBar } from './components'
import EstimateSuccessReport from './estimate/EstimateSuccessReport'

export const ReportPage = () => {
    const reportId = useParams<{ reportId: string }>().reportId?.toUpperCase() as Guid
    const [isLoading, setIsLoading] = useState(true)
    const [vehicleInCollision, setVehicleInCollision] = useState<boolean>(true)
    const [report, setReport] = useState<GetReportResponse | undefined>(undefined)

    useEffect(() => {
        if (!isGuid(reportId)) {
            setIsLoading(false)
            setReport(undefined)
            return
        }

        const lastUploadedReport = LastUploadedReportSuccessResponseStore.instance.take()
        if (lastUploadedReport != null && lastUploadedReport.response.reportId === reportId) {
            setIsLoading(false)
            setReport({
                response: lastUploadedReport.response,
                reportUser: {
                    isCurrentUser: true,
                    ...lastUploadedReport.user,
                },
            })
        } else {
            Api.getReport({ reportId })
                .then((result) => {
                    setReport(result)
                })
                .catch((err) => {
                    console.error(err)
                    setReport(undefined)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [reportId])

    if (isLoading) {
        return <LoadingBar />
    }

    if (report == null) {
        return (
            <div className="box" data-testid="report-not-found-message">
                Report not found.
            </div>
        )
    }

    const downloadUrl = `${routes.data.get.getReportPdf(report.response.reportId)}${
        vehicleInCollision === false ? '/?hideCollision=true' : ''
    }`

    const handleDownload = () => {
        window.location.href = downloadUrl
    }

    return (
        <div id="report-page" className="content" data-test-group="report-page">
            <header className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">adasThink Vehicle Report</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <button
                            type="button"
                            onClick={(e) => handleDownload()}
                            className="button is-info"
                            data-testid="download-report-button"
                        >
                            <span className="icon is-small">
                                <i className="fas fa-file-pdf" />
                            </span>
                            <span>Download Report</span>
                        </button>
                    </div>
                </div>
            </header>
            <EstimateSuccessReport
                vehicleInCollision={vehicleInCollision}
                setVehicleInCollision={setVehicleInCollision}
                response={report}
            />
        </div>
    )
}

export default ReportPage
