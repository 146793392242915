import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
    EstimateHoverItem,
} from '@adas/shared-types';
import RenderedImage from './RenderedImage';
import HoverLineItem from './HoverLineItem';

export const HoverItemsTooltip = (
    { id, hoverItems }: { id: string, hoverItems: EstimateHoverItem[] },
) => {
    if (hoverItems.length === 0) {
        return <></>;
    }

    return (
        <ReactTooltip id={id} place="bottom">
            <div className="hover-image-table">
                {hoverItems.map((hoverItem, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="hover-image-row">
                        {hoverItem.kind === 'image'
                            ? <RenderedImage image={hoverItem} /> : <HoverLineItem lineItem={hoverItem} /> }
                    </div>
                ))}
            </div>
        </ReactTooltip>
    );
};

export const HoverItemTooltip = (
    { id, image }: { id: string, image: EstimateHoverItem | undefined },
) => {
    if (image == null) {
        return <></>;
    }
    return <HoverItemsTooltip id={id} hoverItems={[image]} />;
};

export default HoverItemsTooltip;
