/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-extend-native */
import { deleteAppToken, parseQueryString, routes } from '@adas/shared-types';
import React, {
    ReactNode, useContext, useMemo, useState,
} from 'react';
import {
    BrowserRouter as Router, Redirect, Route, RouteProps, Switch,
} from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { isEmpty, isNil } from 'lodash';
import { AzureAuthProvider, LoginPage, UseAzure } from '@repairify/react-auth';
import {
    AccountPage,
    ApiDetailsPage,
    CustomerUsersPage,
    EditBillingPage,
    EstimatePage,
    GettingStartedPage,
    HomePage,
    PastReportsPage,
    PlanPage,
    PlansPage,
    ReportPage,
} from './pages';
import CustomReport from './pages/CustomReport';
import client from './RestClient';
import UserProfile from './pages/UserProfile';
import CustomerUsageReport from './pages/usage-report/CustomerUsageReport';
import GetAzureConfiguration from './AuthenticationHooks/GetAzureConfiguration';

import GetUserPermissions from './hooks/GetUserPermissions';
import Sidebar from './pages/components/sidebar/Sidebar';
import { LoadingBar } from './pages/components';
import UseAppToken from './AuthenticationHooks/UseAppToken';
import { AppContext, ApplicationContext } from './AppContext';
import useOnLogin from './AuthenticationHooks/useOnLogin';
import UnauthorizedUserPage from './pages/UnauthorizedUserPage';
import IsFeatureFlagEnabled from './hooks/IsFeatureFlagEnabled';
import Questionnaire from './pages/components/Questionnaire/Questionnaire';

const buildReturnUrl = (location: RouteProps['location']) => {
    const { pathname = '/', search = '' } = location ?? {};

    const uri = isEmpty(search) ? pathname : `${pathname}?${search}`;
    if (uri === '/') {
        return '';
    }
    return `?returnUrl=${encodeURIComponent(uri)}`;
};

interface PrivateRouteProps extends RouteProps {
    hasSidebar?: boolean;
    requiredPermission?: string;
}

const PrivateRoute = ({ hasSidebar = true, ...props }: PrivateRouteProps) => {
    const { location, requiredPermission } = props;

    const children = props.children as ReactNode;
    const [userPermissions, { loading: loadingPermissions }] = GetUserPermissions();
    const { isLoggingOut, isAuthenticated, inProgress } = UseAzure();
    const [appToken] = UseAppToken();
    const { isUnregistered } = useContext(AppContext);

    // The app is checking azure auth or checking for a registered adasThink user corresponding to the azure authed user (userPermissions)
    if (loadingPermissions || isLoggingOut || inProgress !== 'none') {
        return <LoadingBar />;
    }

    // Azure auth has been checked, and the user is not authenticated
    if (!isAuthenticated) {
        return (
            <Route>
                <Redirect to={routes.pages.login + buildReturnUrl(location)} />
            </Route>
        );
    }

    // There is an azure user, but there is not an adasThink user associated with it
    if (isUnregistered) {
        // If this unregistered user is trying to access any route other than register, redirect them to register with the url params intact
        if (location?.pathname?.slice(0, 9).toLowerCase() !== routes.pages.register) {
            return (
                <Route>
                    <Redirect to={`${routes.pages.register}`} />
                </Route>
            );
        }
    } else if (isNil(appToken)) {
        return (
            <Route>
                <Redirect to={`${routes.pages.login}`} />
            </Route>
        );
    }

    if (requiredPermission && !userPermissions?.newPermissions[requiredPermission]) {
        return (
            <Route>
                <UnauthorizedUserPage />
            </Route>
        );
    }

    if (userPermissions?.isEnabled === false) {
        return (
            <Route>
                <UnauthorizedUserPage />
            </Route>
        );
    }

    if (!hasSidebar) {
        return (
            <Route {...props}>
                <div className="App" style={{ width: '100%' }}>
                    {children}
                </div>
            </Route>
        );
    }

    // This user is authenticated with azure and has user permissions
    return (
        <Route {...props}>
            <div className="columns">
                <div className="column is-narrow" id="left-menu">
                    <Sidebar />
                </div>
                <div className="column" style={{ width: '100%' }}>
                    <div className="App" style={{ width: '100%' }}>
                        {children}
                    </div>
                </div>
            </div>
        </Route>
    );
};

const LoginPageWrapper = () => {
    const [appToken] = UseAppToken();
    const onLogin = useOnLogin();
    const { idToken } = UseAzure();
    if (idToken && appToken) {
        return <Redirect to={routes.pages.home} />;
    }
    return (
        <LoginPage
            isLoggedIn={!isNil(appToken)}
            onPostLogin={onLogin}
            onPreLogin={() => {
                deleteAppToken();
            }}
        />
    );
};

const Routes = () => {
    const [planPageDisabled, { loading: planPagefeatureFlagLoading }] = IsFeatureFlagEnabled({
        variables: { featureKey: 'PLAN_PAGE_DISABLED' },
    });
    const [questionnaireEnabled, { loading: questionnaireFeatureFlagLoading }] = IsFeatureFlagEnabled({
        variables: { featureKey: 'QUESTIONNAIRE' },
    });

    if (planPagefeatureFlagLoading || questionnaireFeatureFlagLoading) {
        return null;
    }

    return (
        <Switch>
            <Route path="/refresh" render={() => <Redirect to={parseQueryString().url ?? '/'} />} />
            <Route path={routes.pages.login}>
                <LoginPageWrapper />
            </Route>
            <Route path={routes.pages.logout}>
                <Redirect to={routes.pages.home} />
            </Route>
            <PrivateRoute path={routes.pages.register} hasSidebar={false}>
                <UnauthorizedUserPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.newReport}>
                <EstimatePage />
            </PrivateRoute>
            {questionnaireEnabled && (
                <PrivateRoute path={routes.pages.questionnaire}>
                    <Questionnaire />
                </PrivateRoute>
            )}
            <PrivateRoute path={routes.pages.customReport}>
                <CustomReport />
            </PrivateRoute>
            <PrivateRoute path={[routes.pages.pastReports(':pageNumber' as any), routes.pages.pastReports()]}>
                <PastReportsPage />
            </PrivateRoute>
            <PrivateRoute path="/user-usage/:userId/month/:month">
                <CustomerUsageReport />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.getReport(':reportId' as any)}>
                <ReportPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.gettingStarted}>
                <GettingStartedPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.account.apiDetails}>
                <ApiDetailsPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.account.billing}>
                <EditBillingPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.account.userProfile}>
                <UserProfile />
            </PrivateRoute>
            {!planPageDisabled && (
                <PrivateRoute path={routes.pages.account.plans}>
                    <PlansPage />
                </PrivateRoute>
            )}
            {!planPageDisabled && (
                <PrivateRoute path={routes.pages.account.plan(':planId' as any)}>
                    <PlanPage />
                </PrivateRoute>
            )}

            <PrivateRoute path={routes.pages.account.users}>
                <CustomerUsersPage />
            </PrivateRoute>
            {/* have these last so they don't override the other child routes */}
            <PrivateRoute path={routes.pages.account.index}>
                <AccountPage />
            </PrivateRoute>
            <PrivateRoute path={routes.pages.home}>
                <HomePage />
            </PrivateRoute>
        </Switch>
    );
};

const AzureWrapper = () => {
    const [configuration, { loading }] = GetAzureConfiguration();
    if (isNil(configuration) || loading) {
        return null;
    }

    return (
        <AzureAuthProvider clientId={configuration.clientId} tenant={configuration.tenant} loginUri="/login" logoutUri="/logout">
            <Router>
                <Routes />
            </Router>
        </AzureAuthProvider>
    );
};

const App = () => {
    const [isUnregistered, setIsUnregistered] = useState<boolean | undefined>();
    const providerState: ApplicationContext = useMemo(
        () => ({
            isUnregistered,
            setIsUnregistered,
        }),
        [isUnregistered, setIsUnregistered],
    );

    return (
        <ApolloProvider client={client}>
            <AppContext.Provider value={providerState}>
                <AzureWrapper />
            </AppContext.Provider>
        </ApolloProvider>
    );
};

export default App;

// shims (https://stackoverflow.com/a/53327815/188246)
Promise.prototype.finally = Promise.prototype.finally
    || {
        finally(fn: any) {
            const onFinally = (callback: any) => Promise.resolve(fn()).then(callback);
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            return (this as any).then(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                (result: any) => onFinally(() => result),
                (reason: any) => onFinally(() => Promise.reject(reason)),
            );
        },
    }.finally;

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        value(predicate: any) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            const thisArg = arguments[1];

            // 5. Let k be 0.
            let k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return k.
                const kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }
                // e. Increase k by 1.
                k += 1;
            }

            // 7. Return -1.
            return -1;
        },
        configurable: true,
        writable: true,
    });
}
