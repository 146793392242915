import React from "react";
import { getHtmlProps } from "./getHtmlProps";

export interface NumberTextBoxProps {
    className?: string;
    onChange?: (value: number | undefined) => void;
    value: number | undefined;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    maxLength?: number;
}

export function NumberTextBox(props: NumberTextBoxProps) {
    let className = "input";
    if (props.className) {
        className += " " + props.className;
    }

    return (
        <input
            type="number"
            maxLength={props.maxLength}
            value={props.value}
            className={className}
            disabled={props.disabled}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={e => props.onChange?.(e.target.value.length === 0 ? undefined : e.target.valueAsNumber)}
            {...getHtmlProps(props)}
        />
    );
}
