import React from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';

const MenuLink = ({
    to,
    children,
    activeWhenStartsWith = false,
}: {
    to: string;
    children: React.ReactNode;
    activeWhenStartsWith?: boolean;
}) => {
    const location = useLocation();
    const history = useHistory();
    const isCurrentPage = () => location.pathname === to;
    // This terrible hack is a way to get react router to refresh when clicking a link on the
    // current page. Read more in my answer here: https://stackoverflow.com/a/64905470/188246
    const handler = isCurrentPage()
        ? () => {
            setTimeout(() => {
                if (isCurrentPage()) {
                    history.replace(`/refresh?url=${encodeURIComponent(to)}`);
                }
            }, 0);
        }
        : undefined;
    const isActive = activeWhenStartsWith ? location.pathname.startsWith(to) : location.pathname === to;

    return <Link to={to} className={isActive ? 'is-active' : ''} onClick={handler}>{children}</Link>;
};

export default MenuLink;
