import { VehicleNotFoundResponse } from "@adas/shared-types";
import React from "react";

export function NotFoundVehicle({ response }: { response: VehicleNotFoundResponse }) {
    return (
        <>
            <div className="notification is-danger">
                <p data-testid="vehicle-not-found-message">
                    The vehicle {response.vehicleName} was not in our system.
                </p>
            </div>
        </>
    );
}
