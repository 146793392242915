import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import UseAppToken from '../AuthenticationHooks/UseAppToken';

interface UserSettings {
    useCustomReport: boolean;
    defaultVehicleInCollision: boolean;
}
interface UserSettingsResponse {
    userSettings: UserSettings;
}

const query = gql`
    query GetUserPermissions {
        userSettings
        @rest(
            type: "UserSettings",
            method: "GET",
            path: "api/user-settings"
        ) {
            useCustomReport
            defaultVehicleInCollision
        }
    }
`;

const GetUserSettings = (options: QueryHookOptions<UserSettingsResponse> = {}) => {
    const [appToken] = UseAppToken();
    const response = useQuery<UserSettingsResponse>(query, {
        ...options,
        skip: !appToken,
    });
    return [response.data?.userSettings, response] as const;
};

export default GetUserSettings;
