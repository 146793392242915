import { parseQueryString, routes, setAppToken } from '@adas/shared-types';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UseAzure } from '@repairify/react-auth';
import { AppContext } from '../AppContext';
import { OldApi } from '../server';

export default () => {
    const history = useHistory();
    const { logoutUser } = UseAzure();

    const { isUnregistered, setIsUnregistered } = useContext(AppContext);
    const onLogin = (idToken: string) => {
        if (isUnregistered) {
            return;
        }
        OldApi.login({ idToken }).then(async (result) => {
            const { returnUrl } = parseQueryString();
            if (result.success) {
                setIsUnregistered(false);
                setAppToken(result.token);
                // ensure this url is local to prevent social engineering attack
                // (ex. redirect to phishing website and get user to re-enter password)
                if (returnUrl != null && /^\/([A-Za-z0-9]|\/|-)+$/.test(returnUrl)) {
                    window.location.href = returnUrl;
                } else {
                    window.location.href = '/';
                }
            } else if (result.message === 'UNREGISTERED_USER') {
                setIsUnregistered(true);
                // Check if the return URL is already taking them to register.
                if (returnUrl?.slice(0, 9) === routes.pages.register) {
                    history.push(returnUrl);
                } else {
                    history.push(routes.pages.register);
                }
            } else if (result.message === 'ID_TOKEN_EXPIRED') {
                console.log('Id token expired.');
                await logoutUser();
            } else if (result.message === 'AZURE_TOKEN_INVALID') {
                console.log('Id token Invalid.');
                await logoutUser();
            }
        }).catch(() => {});
    };
    return onLogin;
};
