import { Guid } from "./types";

// use a loose check... don't want to use something too strict
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export function isGuid(value: Guid): value is Guid {
    return guidRegex.test(value);
}

/** Very basic email test. */
export function isValidEmail(email: string): boolean {
    if (typeof email !== "string") {
        return false;
    }
    const atSymbolIndex = email.indexOf("@");
    if (atSymbolIndex <= 0) {
        return false;
    }
    if (email.indexOf("@", atSymbolIndex + 1) !== -1) {
        return false;
    }
    return true;
}
