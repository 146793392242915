import React from 'react';

const FeatureServiceDown = () => (
    <div className="message is-warning" data-test-group="estimate-success-report">
        <div className="message-header">
            <p>Vehicle Features Unavailable</p>
        </div>
        <div className="message-body" data-testid="vehicle-features-down-message">
            <p>
                Vehicle features are unavailable for this estimate at this time.
            </p>
            <p>
                For now, please make a manual selection of equipped sensors or try viewing the report later.
            </p>
        </div>
    </div>
);

export default FeatureServiceDown;
