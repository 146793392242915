import { constants } from "./constants";

export interface VersionedMessage<TValue> {
    version: typeof constants.WebServerVersion;
    value: TValue;
}

export function createVersionedMessage<TValue>(value: TValue) {
    return {
        version: constants.WebServerVersion,
        value,
    };
}
