import React from 'react';
import {
    PdfDebugInfo,
    ApiDebugInfo,
    SuccessDebugInfo,
    instantToDate,
} from '@adas/shared-types';

const JsonTextLink = ({ text, jsonText, fileName }: { text: string; jsonText: string; fileName: string }) => (
    <a href={`data:text/json;charset=utf-8,${encodeURIComponent(jsonText)}`} download={`${fileName}.json`}>
        {text}
    </a>
);

const attemptPrettyPrintJson = (text: string) => {
    try {
        return JSON.stringify(JSON.parse(text), null, 2);
    } catch {
        return text;
    }
};

export const PdfDebugInfoDisplay = ({ debugInfo }:{ debugInfo: PdfDebugInfo }) => (
    <>
        <li>
            <JsonTextLink text="Pdf Output" jsonText={debugInfo.pdfJson} fileName="pdf" />
        </li>
        <li>
            <JsonTextLink text="Raw Response" jsonText={debugInfo.rawResponseJson} fileName="response" />
        </li>
    </>
);

export const ApiDebugInfoDisplay = ({ debugInfo }:{ debugInfo: ApiDebugInfo }) => (
    <>
        <li>
            <JsonTextLink text="Raw Response" jsonText={debugInfo.rawResponseJson} fileName="response" />
        </li>
        <li>
            <pre>{attemptPrettyPrintJson(debugInfo.requestBody)}</pre>
        </li>
    </>
);

const DebugInfo = ({ debugInfo }:{ debugInfo: SuccessDebugInfo }) => (
    <div className="box">
        <div>
            <strong>Diagnostics</strong>
        </div>
        <ul>
            <li>
                DB Version:
                {' '}
                {instantToDate(debugInfo.databaseLastModified).toLocaleString()}
            </li>
            {
                debugInfo.type === 'Pdf' ? <PdfDebugInfoDisplay debugInfo={debugInfo} />
                    : <ApiDebugInfoDisplay debugInfo={debugInfo} />
            }

        </ul>
    </div>
);

export default DebugInfo;
