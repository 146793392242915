import {
    CheckBox,
    constants,
    getFormattedDate,
    Guid,
    parseQueryString,
    routes,
    Spinner,
    TextBox,
    UrlBuilder,
    useDebounce,
} from "@adas/shared-types";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api, ReportResponse } from "../ClientServerApi.generated";
import { tableClickHandler } from "../utils/eventHelpers";
import { LinkButton, LoadingBar, Pager } from "./components";

export function PastReportsPage() {
    const pageIndex = parsePageIndex(useParams<{ pageNumber: string }>().pageNumber || "1");
    const searchText = parseQueryString().search ?? "";
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOwnerUserWithPlanUsers, setIsOwnerUserWithPlanUsers] = useState<boolean>(false);
    const [showAllCustomerUserReports, setShowAllCustomerUserReports] = useState<boolean>(true);
    const [reports, setReports] = useState<ReportResponse[] | undefined>(undefined);
    const history = useHistory();
    const [pageCount, setPageCount] = useState<number>(0);
    const debouncedSearchText = useDebounce(searchText, 500);

    useEffect(() => {
        setIsLoading(true);

        // wait for the debounced search text to catch up
        if (searchText !== debouncedSearchText) {
            return;
        }

        Api.listReports({
            pageIndex,
            searchText: debouncedSearchText,
            showAllCustomerUserReports,
        }).then(response => {
            if (pageIndex > 0 && response.reports.length === 0) {
                // revert back to the first page in this scenario
                updateParams(0, searchText);
            } else {
                setReports(response.reports);
                setPageCount(Math.ceil(response.rowCount / constants.RowsPerPage));
                setIsOwnerUserWithPlanUsers(response.isOwnerUserWithPlanUsers);
                setIsLoading(false);
            }
        });
    }, [pageIndex, debouncedSearchText, showAllCustomerUserReports]);

    return (
        <>
            <div className="content" data-test-group="past-reports-page">
                <header className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <h1 className="title">Past Reports</h1>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            {isLoading ? <Spinner className="level-item" /> : <></>}
                        </div>
                        {isOwnerUserWithPlanUsers && (
                            <div className="level-item">
                                <label className="checkbox">
                                    <CheckBox
                                        checked={showAllCustomerUserReports}
                                        onChange={checked => setShowAllCustomerUserReports(checked)}
                                        data-testid="show-all-customer-user-reports-checkbox"
                                    />
                                    {" Include Plan User Reports"}
                                </label>
                            </div>
                        )}
                        <div className="level-item">
                            <TextBox
                                data-testid="search-text-box"
                                className="input"
                                placeholder="Search"
                                value={searchText}
                                onChange={value => updateParams(pageIndex, value)}
                            />
                        </div>
                    </div>
                </header>
                {reports == null ? <LoadingBar /> : renderBody(reports)}
            </div>
            {reports != null && (
                <Pager
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    onPageChange={(index) => updateParams(index, searchText)}
                />
            )}
        </>
    );

    function updateParams(pageIndex: number, searchText: string) {
        const urlBuilder = UrlBuilder.fromCurrent();
        urlBuilder.setQueryParamTrimmed("search", searchText);
        urlBuilder.setPathName(routes.pages.pastReports(pageIndex + 1));
        history.push(urlBuilder.toString());
    }

    function renderBody(reports: ReportResponse[]) {
        if (reports.length === 0) {
            if (isLoading) {
                return <LoadingBar />;
            } else if (debouncedSearchText.length > 0) {
                return (
                    <div
                        data-testid="no-results-matching-box"
                        className="box"
                    >
                        No reports match your search '{debouncedSearchText}'.
                    </div>
                );
            } else {
                return (
                    <div
                        data-testid="no-results-box"
                        className="box"
                    >
                        None. Create a <Link to={routes.pages.newReport}>New Report</Link>.
                    </div>
                );
            }
        }

        const isCustomerUser = reports[0]?.shopName != null;
        return (
            <table id="past-reports-table" className="table is-hoverable" data-testid="past-reports-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>VIN</th>
                        <th>Vehicle</th>
                        {isCustomerUser && (<th className="shop-column">Shop</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map(report => {
                        const linkPath = routes.pages.getReport(report.reportId.toLowerCase() as Guid);
                        return (
                            <tr
                                key={report.reportId}
                                data-vin={report.vin}
                                onClick={tableClickHandler(() => history.push({ pathname: linkPath }))}
                            >
                                <td>{getFormattedDate(report.createdDate)}</td>
                                <td>{report.vin}</td>
                                <td>
                                    {report.year} {report.make} {report.model}
                                    {report.trim && " " + report.trim}
                                </td>
                                {isCustomerUser && (
                                    <td className="shop-column" title={report.shopName + " - " + report.email}>
                                        {report.shopName ?? ""}
                                    </td>
                                )}
                                <td>
                                    <div className="buttons is-pulled-right">
                                        <LinkButton to={linkPath} className="is-success">
                                            <span className="icon is-small">
                                                <i className="fas fa-file-alt"></i>
                                            </span>
                                            <span>View</span>
                                        </LinkButton>
                                        <button
                                            className="button is-info"
                                            onClick={e => {
                                                e.stopPropagation();
                                                window.location.href = routes.data.get.getReportPdf(report.reportId);
                                            }}
                                        >
                                            <span className="icon is-small">
                                                <i className="fas fa-file-pdf"></i>
                                            </span>
                                            <span>Download</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}

function parsePageIndex(pageNumber: string) {
    const pageNumberNum = parseInt(pageNumber, 10);
    if (isNaN(pageNumberNum) || pageNumberNum < 1) {
        return 0;
    }
    return pageNumberNum - 1;
}
