import { Guid, Instant } from '@adas/shared-types';
import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export interface UsageLine {
    vin: string;
    usageDate: string;
}

const GetAllUserUsageForMonthMutation = gql`
    mutation GetAllUserUsageForMonth($userId: String!, $usageMonth: String!) {
        userUsage(userId: $userId, usageMonth: $usageMonth)
        @rest(
            type: "UserUsage[]",
            method: "GET",
            path: "api/all-user-usage/{args.userId}/month/{args.usageMonth}"
        ) {
            vin
            usageDate
        }
    }
`;

interface DataType {
    userUsage: UsageLine[]
}

interface Params {
    userId: Guid;
    usageMonth: number;
}

const GetAllUserUsageForMonth = (options: MutationHookOptions<DataType, Params> = {}) => (
    useMutation<DataType, Params>(GetAllUserUsageForMonthMutation, options));

export default GetAllUserUsageForMonth;
