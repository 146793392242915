import { isValidVin, Modal, parseVin, TextBox, VIN } from "@adas/shared-types";
import React, { useState } from "react";

export interface VinSelectProps {
    vins: VIN[];
    onChange: (vin: VIN) => void;
}

export function VinSelect({ vins, onChange }: VinSelectProps) {
    const manualValue = "manual";
    const [selectedVin, setSelectedVin] = useState<VIN | "manual">(vins[0] ?? manualValue);
    const [manualVin, setManualVin] = useState("");
    const selectedValue = selectedVin === manualValue ? selectedVin : selectedVin.text;

    return (
        <>
            <div className="notification is-info">
                <p>We were unable to automatically determine the VIN number of the provided estimate.</p>
                <p>Please make a selection below to continue.</p>
            </div>
            <div className="field">
                <label className="label">VIN number:</label>
                <div className="control">
                    <select
                        value={selectedValue}
                        onChange={e => {
                            if (e.target.value === manualValue) {
                                setSelectedVin(manualValue);
                            } else {
                                const vin = vins.find(v => v.text === e.target.value);
                                if (vin != null) {
                                    setSelectedVin(vin);
                                }
                            }
                        }}
                    >
                        {vins.map(vin => <option value={vin.text}>{vin.text}</option>)}
                        <option value={manualValue}>Enter Manually</option>
                    </select>
                </div>
            </div>
            {selectedVin === manualValue ? renderVinInputTextBox() : <></>}
            <div className="field">
                <div className="control">
                    <button className="button is-link" type="button" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </>
    );

    function renderVinInputTextBox() {
        return (
            <div className="field">
                <div className="control">
                    <TextBox
                        type="text"
                        placeholder="VIN number"
                        value={manualVin}
                        onChange={value => setManualVin(value.trim())}
                    />
                </div>
            </div>
        );
    }

    function onSubmit() {
        if (selectedVin === manualValue) {
            if (isValidVin(manualVin)) {
                return onChange(parseVin(manualVin));
            } else {
                Modal.alert("The provided VIN was not valid.");
            }
        } else {
            return onChange(selectedVin);
        }
    }
}
