import {
    gql,
    QueryHookOptions,
    useQuery,
} from '@apollo/client';
import { isNil } from 'lodash';
import { Guid } from '@adas/shared-types';

export const IsFeatureFlagEnabledQuery = gql`
    query IsFeatureFlagEnabled($featureKey: String!) {
        featureFlag(featureKey: $featureKey)
        @rest(
            type: "FeatureFlag",
            method: "GET",
            path: "api/featureFlag?featureKey={args.featureKey}"
        ) {
            featureFlagId
            featureKey
            description
            isEnabled
        }
    }
`;

type QueryParameters = { featureKey: string };

type DataType = { featureFlag: FeatureFlag };

export interface FeatureFlag {
    description: string;
    featureFlagId: Guid;
    isEnabled: boolean;
    featureKey: string;
}
const IsFeatureFlagEnabled = (options: QueryHookOptions<DataType, QueryParameters> = {}) => {
    const response = useQuery<DataType, QueryParameters>(IsFeatureFlagEnabledQuery, {
        ...options,
        fetchPolicy: 'no-cache',
        skip: isNil(options?.variables?.featureKey),
    });
    return [response.data?.featureFlag?.isEnabled, response] as const;
};

export default IsFeatureFlagEnabled;
