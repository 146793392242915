import React from 'react';
import { UserPermissions } from '@adas/shared-types';
import GetUserPermissions from '../../../hooks/GetUserPermissions';
import { isNil } from 'lodash';

const AdminMenu = () => {
    const [userPermissions, { loading: userPermissionsLoading }] = GetUserPermissions();

    if (isNil(userPermissions)) {
        return <></>;
    }

    if ((userPermissions?.permissions & UserPermissions.Admin) === UserPermissions.Admin) {
        return (
            <>
                <p className="menu-label">
                    Admin
                </p>
                <ul className="menu-list">
                    <li>
                        <a href="/admin">
                            <span className="icon is-small is-left">
                                <i className="fas fa-screwdriver" />
                            </span>
                            Manage
                        </a>
                    </li>
                </ul>
            </>
        );
    }
    return <></>;
};

export default AdminMenu;
