import React, { useState } from 'react';
import {
    List, ListItem, ListItemText, Divider,
} from '@mui/material';
import { isEmpty, isNil, mapValues } from 'lodash';
import { Redirect } from 'react-router-dom';
import { QuestionnaireAnswers } from '../../../hooks/Questionnaire/types';
import QuestionnaireStep from './QuestionnaireStep';
import UseQuestionnaireReport from '../../../hooks/Questionnaire/UseQuestionnaireReport';
import { QuestionnaireAnswerState } from './state/types';

export default ({
    selectedAnswers,
    navigateBack,
}: {
    selectedAnswers: QuestionnaireAnswerState,
    navigateBack: () => void,
}) => {
    const [reportId, setReportId] = useState<string | undefined>();
    const [createReport, { loading }] = UseQuestionnaireReport({
        onCompleted: (response) => {
            setReportId(response.questionnaireReport.body.reportId);
        },
    });

    const {
        vin, trim, sensors, carParts,
    } = selectedAnswers;

    const answers: QuestionnaireAnswers['answers'] = {
        sensors: mapValues(selectedAnswers.sensors, (selectedSensor) => selectedSensor.isEquipped),
        carParts: mapValues(selectedAnswers.carParts, (selectedCarPartDamage) => selectedCarPartDamage.operation),
    };

    if (!isNil(selectedAnswers.trim)) {
        answers.trim = selectedAnswers.trim;
    }

    const questionnaireAnswers = {
        vin: selectedAnswers.vin,
        answers,
    };

    if (reportId) {
        return <Redirect to={`/report/${reportId.toLowerCase()}`} />;
    }
    return (
        <QuestionnaireStep
            headerText="Confirm Your Choices:"
            confirmButtonDisplay="Create Report"
            confirmButtonLoading={loading}
            onSubmit={() => createReport({ variables: { questionnaireAnswers } })}
            areAnswersValid
            navigateBack={navigateBack}
        >
            <List disablePadding>
                <ListItem>
                    <ListItemText
                        primary="Selected VIN"
                        secondary={vin}
                    />
                </ListItem>

                {!isNil(trim) && (
                    <>
                        <Divider />
                        <ListItem>
                            <ListItemText
                                primary="Selected Trim"
                                secondary={trim}
                            />
                        </ListItem>
                    </>
                )}

                {!isEmpty(sensors) && (
                    <>
                        <Divider />
                        <ListItem><ListItemText primary="Selected Sensors:" /></ListItem>
                        {Object.values(sensors).map(({ name, isEquipped }) => (
                            <ListItem>
                                <ListItemText
                                    primary={name}
                                    secondary={isEquipped ? 'Unequipped' : 'Absent'}
                                />
                            </ListItem>
                        ))}
                    </>
                )}

                {!isEmpty(carParts) && (
                    <>
                        <Divider />
                        <ListItem><ListItemText primary="Selected Damage:" /></ListItem>
                        {Object.values(carParts)
                            .filter((carPart) => carPart.operation !== 'None')
                            .map(({ name, operation }) => (
                                <ListItem>
                                    <ListItemText
                                        primary={name}
                                        secondary={operation}
                                    />
                                </ListItem>
                            ))}
                    </>
                )}
            </List>
        </QuestionnaireStep>
    );
};
