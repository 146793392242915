import React, {
    useEffect,
    useState,
    useMemo,
} from 'react';
import {
    getFeatureCategoryDisplayName,
    getMatchCategoryGroups,
    getMatchLinks,
    getReportVehicleDisplayName,
    Guid,
    isUrl,
    MatchLink,
    ReportMatch,
    ReportMatchCategoryGroup,
    responseToReport,
    SensorOverride,
    stringUtils,
    UserPermissions,
    VehicleFeatureCategory,
    ReportCalibrationOperation,
    AlwaysAppliesMatch,
    ProcedureExtractionMatch,
    ReportVehicleFeature,
    SensorSelection,
} from '@adas/shared-types';

import { isNil, uniqBy } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ReportPageLinks } from '../ReportPageLinks';
import { Api, GetReportResponse, GetReportResponseUser } from '../../../ClientServerApi.generated';
import GetUserProfile from '../../../hooks/GetUserProfile';
import FeatureServiceDown from './FeatureServiceDown';
import {
    HoverItemTooltip,
} from './HoverTooltips';
import DebugInfo from './DebugInfo';
import SensorSelections from './SensorSelections';
import ProceduresParagraph from './ProceduresParagraph';
import SentenceJoin from './SentenceJoin';
import GetUserSettings from '../../../hooks/GetUserSettings';
import GetUserPermissions from '../../../hooks/GetUserPermissions';

const allTrimsValue = 'ALL_TRIMS';

const getMatchLinkDescriptionText = (link: MatchLink) => {
    switch (link.kind) {
        case 'allData':
            return 'OEM requirement';
        case 'calibration':
            return 'How to perform procedure';
        case 'both':
            return 'OEM requirement and procedure';
        default:
            return link.url;
    }
};

const ReportForHeader = ({ reportUser }: { reportUser: GetReportResponseUser }) => (
    <div data-test-group="estimate-success-report">
        <div>
            <b>User:</b>
            {' '}
            <span data-testid="user-name">
                {`${reportUser.firstName} ${reportUser.lastName}`}
            </span>
            {' ('}
            <span data-testid="user-email">
                {reportUser.email}
            </span>
            )
        </div>
        <div>
            <b>Shop:</b>
            {' '}
            {reportUser.shopName}
        </div>
    </div>
);

const EstimateSuccessReport = (props: {
    response: GetReportResponse,
    vehicleInCollision: boolean,
    setVehicleInCollision: (showCollision: boolean) => void,
}) => {
    const { response: { response, reportUser }, vehicleInCollision, setVehicleInCollision } = props;
    const { vehicle } = response;
    const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
    const [selectedTrim, setTrim] = useState<string>(
        vehicle.selectedTrim ?? (vehicle.trims.length === 1 ? vehicle.trims[0] : allTrimsValue),
    );
    const [responseSensorOverrides, setSensorOverrides] = useState<readonly SensorOverride[]>(
        response.sensorOverrides,
    );
    // const [requiredCategoryGroups, setRequiredCategoryGroups] = useState<ReportMatchCategoryGroup[]>([]);

    const {
        requiredMatches,
        sensorSelections,
        equippedFeatures,
        optionalFeatures,
        sensorOverrides,
    }: {
        requiredMatches: ReportMatch[],
        sensorSelections: SensorSelection[],
        equippedFeatures: ReportVehicleFeature[] | undefined,
        optionalFeatures: ReportVehicleFeature[] | undefined,
        sensorOverrides: SensorOverride[],
    } = responseToReport(
        response,
        {
            trim: selectedTrim === allTrimsValue ? undefined : selectedTrim,
            sensorOverrides: responseSensorOverrides,
        },
    );
    const requiredCategoryGroups = vehicleInCollision
        ? getMatchCategoryGroups(requiredMatches)
        : getMatchCategoryGroups(requiredMatches.filter(
            ({ procedures }) => procedures.length > 0,
        ));

    const [userSettings, { loading: userSettingsLoading }] = GetUserSettings({
        onCompleted: (data) => {
            setVehicleInCollision(data.userSettings?.defaultVehicleInCollision === true);
        },
    });
    const [userPermissions] = GetUserPermissions();

    const [userProfile] = GetUserProfile();

    const equippedAdasFeatures = equippedFeatures?.filter((f: ReportVehicleFeature) => f.category === VehicleFeatureCategory.ADAS);
    const optionalAdasFeatures = optionalFeatures?.filter((f: ReportVehicleFeature) => f.category === VehicleFeatureCategory.ADAS);

    const fullVehicleName = getReportVehicleDisplayName(vehicleName, selectedTrim);
    const isFeaturesServiceDown = response.features == null;

    const isAdmin = () => typeof userPermissions?.permissions === 'number'
            // eslint-disable-next-line no-bitwise
            && ((userPermissions?.permissions & UserPermissions.Admin) === UserPermissions.Admin);

    const allowEdit = () => reportUser.isCurrentUser || isAdmin();

    const allowSave = () => reportUser.isCurrentUser;

    if (userSettingsLoading || isNil(userSettings)) {
        return <></>;
    }

    const updateIsSensorOnVehicle = (sensorId: Guid, isOnVehicle: boolean) => {
        const existingSensorOverride = sensorOverrides.find((s) => s.sensorId === sensorId);
        if (existingSensorOverride == null) {
            setSensorOverrides([{
                sensorId,
                isOnVehicle,
            }, ...sensorOverrides]);
        } else {
            existingSensorOverride.isOnVehicle = isOnVehicle;
            setSensorOverrides([...sensorOverrides]);
        }

        if (allowSave()) {
            Api.updateReportSensor({
                reportId: response.reportId,
                sensorId,
                isOnVehicle,
            }).catch(() => console.log(''));
        }
    };

    const renderTrim = () => {
        if (vehicle.trims.length === 1) {
            return <span>{fullVehicleName}</span>;
        } if (vehicle.trims.length === 0) {
            return <span>{vehicleName}</span>;
        }

        return (
            <span data-test-group="estimate-success-report">
                <select
                    data-testid="vehicle-drop-down"
                    value={selectedTrim}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (allowSave()) {
                            Api.updateReportTrim({
                                reportId: response.reportId,
                                trim: value === allTrimsValue ? undefined : value,
                            }).catch(() => console.log(''));
                        }
                        setTrim(value);
                    }}
                    disabled={!allowEdit()}
                >
                    <option value={allTrimsValue}>
                        {vehicleName}
                        {' '}
                        (All Trims)
                    </option>
                    {vehicle.trims.map((trim) => (
                        <option value={trim}>
                            {vehicleName}
                            {' '}
                            {trim}
                        </option>
                    ))}
                </select>
            </span>
        );
    };

    const renderMatch = (match: ReportMatch, matchIndex: number) => {
        const matchAdasFeatures = match.features.filter((f) => f.category === VehicleFeatureCategory.ADAS);
        const coverageIndicatorEnabled = userProfile?.coverageIndicatorEnabled || false;
        const ToolInfoMessage = ({
            icon, iconColor, message, dataTestId,
        }: { icon: string, iconColor: string, message: string, dataTestId: string }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: iconColor, fontSize: '32px', marginRight: '12px' }}>
                    <i className={`${icon}`} data-testid={dataTestId} />
                </span>
                <div>{message}</div>
            </div>
        );
        const alwaysAppliesCalibrations = match.alwaysApplies.map(
            (entry: AlwaysAppliesMatch) => entry.calibrationOperations,
        ).flat();
        const procedureCalibrations = match.procedures.map((procedure: ProcedureExtractionMatch) => procedure.calibrationOperations).flat();
        const uniqueCalibrations = uniqBy(
            procedureCalibrations.concat(alwaysAppliesCalibrations),
            (calibration: ReportCalibrationOperation) => calibration.calibrationOperationId,
        );
        const pageLinks = uniqueCalibrations.map((calibration) => calibration.pageLinks ?? []).flat();
        const uniquePageLinks = uniqBy(pageLinks, (pageLink) => pageLink.pageLinkId);

        const renderLinks = () => {
            const matchLinks = getMatchLinks(match);
            if (matchLinks.length === 0) {
                return <></>;
            }
            return (
                <>
                    <p>
                        Please see the documentation from the manufacturer below:
                    </p>
                    <ul>
                        {matchLinks.map((l) => {
                            const descriptionText = getMatchLinkDescriptionText(l);
                            if (isUrl(l.url)) {
                                return (
                                    <li>
                                        <a
                                            href={l.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="calibration-link"
                                        >
                                            {descriptionText}
                                        </a>
                                    </li>
                                );
                            }
                            return (
                                <li>
                                    {`${descriptionText} - ${l.url}`}
                                </li>
                            );
                        })}
                    </ul>
                </>
            );
        };

        return (
            <div data-test-group="report-match" data-test-selector={matchIndex} className="report-match">
                <h4>
                    {matchIndex + 1}
                    .
                    {' '}
                    <span data-testid="match-sensor-name">{match.sensor.name}</span>
                    {' '}
                    {!match.isRequired && ' - Potential Calibration'}
                </h4>
                <h5>
                    <ul>
                        {
                            uniqueCalibrations.map((calibration) => (
                                <li>
                                    {calibration.calibrationType.name}
                                    {' '}
                                    (
                                    {calibration.procedureName}
                                    )
                                </li>
                            ))
                        }
                    </ul>
                </h5>
                {!coverageIndicatorEnabled
                    ? null
                    : (
                        <ToolInfoMessage
                            icon={`far ${match.hasToolsForCalibration ? 'fa-check-circle' : 'fa-times-circle'}`}
                            iconColor={`${match.hasToolsForCalibration ? '#4F8A10' : '#D8000C'}`}
                            message={`${match.hasToolsForCalibration ? 'Capable with asTech' : 'Coverage not currently available'}`}
                            dataTestId={`${match.hasToolsForCalibration ? 'coverageAvailableIcon' : 'coverageUnavailableIcon'}`}
                        />
                    )}

                {matchAdasFeatures.length > 0 && match.category === VehicleFeatureCategory.ADAS && (
                    <p>
                        The
                        {' '}
                        {match.sensor.name}
                        {' '}
                        is responsible for
                        {' '}
                        <SentenceJoin
                            items={matchAdasFeatures.map((n) => <span data-testid="match-vehicle-feature-name">{n.name}</span>)}
                            andOrText="and"
                        />
                        {' '}
                        on this vehicle
                        {match.isRequired ? '.' : ` if the vehicle is equipped with ${
                            matchAdasFeatures.length === 1
                                ? 'this adas feature.'
                                : 'any of these adas features.'
                        }`}
                    </p>
                )}
                <ProceduresParagraph match={match} matchIndex={matchIndex} vehicleName={vehicleName} />
                {renderLinks()}
                <ReportPageLinks pageLinks={uniquePageLinks} />
            </div>
        );
    };

    const renderGroup = (group: ReportMatchCategoryGroup, startIndex: number) => (
        <>
            <h3>
                {getFeatureCategoryDisplayName(group.category)}
            </h3>
            {group.matches.map((match, i) => renderMatch(match, startIndex + i))}
        </>
    );

    return (
        <div className="content" id="estimate-success" data-test-group="estimate-success-report">
            <HoverItemTooltip id="trimToolTip" image={vehicle.trimMatch?.hoverItem} />
            <div className="box">
                <p>
                    {!reportUser.isCurrentUser && <ReportForHeader reportUser={reportUser} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: '50%' }}>
                            <b data-tip={vehicle.trimMatch?.hoverItem != null} data-for="trimToolTip">Vehicle:</b>
                            {' '}
                            {renderTrim()}
                            <input
                                type="hidden"
                                data-testid="vehicle-name"
                                value={fullVehicleName}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={

                                    (
                                        <Checkbox
                                            name="show_collision_callibrations"
                                            aria-label="Show required for all collisions"
                                            onChange={(event) => {
                                                setVehicleInCollision(event.target.checked);
                                            }}
                                            defaultChecked={(userSettings?.defaultVehicleInCollision === true)}
                                        />
                                    )
                                }
                                label="Vehicle is involved in a collision"
                                labelPlacement="start"
                            />
                        </div>
                    </div>
                    <div>
                        <b>VIN:</b>
                        {' '}
                        {vehicle.vin}
                    </div>
                    {!stringUtils.isNullOrWhitespace(response.roNumber) && (
                        <div>
                            <b>RO Number:</b>
                            {' '}
                            {response.roNumber}
                        </div>
                    )}
                </p>
                {isFeaturesServiceDown && <FeatureServiceDown />}
                <SensorSelections
                    sensorSelections={sensorSelections}
                    sensorOverrides={sensorOverrides}
                    allowEdit={allowEdit()}
                    updateIsSensorOnVehicle={updateIsSensorOnVehicle}
                />
                {/* ALWAYS APPLIES HERE */}
                {requiredCategoryGroups.length === 0 && <h3>No Required Operations</h3>}

                {requiredCategoryGroups.length > 0 && (
                    <div>
                        {requiredCategoryGroups.map(({ category, matches }) => (
                            <h3 className="operation-header">
                                {matches.length}
                                {' '}
                                Required
                                {' '}
                                {getFeatureCategoryDisplayName(category)}
                                {' '}
                                {stringUtils.pluralize('Operation', matches.length)}
                            </h3>
                        ))}
                    </div>
                )}
                <p>
                    There
                    {' '}
                    {requiredCategoryGroups.length === 1 && requiredCategoryGroups[0].matches.length === 1
                        ? 'is'
                        : 'are'}
                    {' '}
                    {requiredCategoryGroups.length === 0 && <span data-testid="no-matches-message">no</span>}
                    {requiredCategoryGroups.length > 0
                        && (
                            <SentenceJoin
                                andOrText="and"
                                items={
                                    requiredCategoryGroups.map(({ category, matches }) => (
                                        <>
                                            {matches.length}
                                            {' '}
                                            {getFeatureCategoryDisplayName(category)}
                                        </>
                                    ))
                                }
                            />
                        )}
                    {' '}
                    {stringUtils.pluralize('operation', requiredMatches.length)}
                    {' '}
                    that
                    {' '}
                    {vehicle.make}
                    {' '}
                    requires because of operations you are performing, as identified in your estimate.
                </p>
                {(equippedAdasFeatures != null || optionalAdasFeatures != null) && (
                    <div className="columns">
                        {equippedAdasFeatures != null && (
                            <div className="column">
                                <h3>
                                    Equipped ADAS Features
                                </h3>
                                {equippedAdasFeatures.length > 0
                                    ? (
                                        <ul>
                                            {equippedAdasFeatures.map((f) => (
                                                <li data-testid="equipped-adas-feature">
                                                    {f.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )
                                    : <div>None</div>}
                            </div>
                        )}
                        {optionalAdasFeatures != null && optionalAdasFeatures.length > 0
                            && (
                                <div className="column">
                                    <h3>Optional ADAS Features</h3>
                                    <ul>
                                        {optionalAdasFeatures.map((f) => <li>{f.name}</li>)}
                                    </ul>
                                </div>
                            )}
                    </div>
                )}
                <section className="report-matches">
                    {(() => {
                        // todo: there's probably a better way of expressing this...
                        let startIndex = 0;
                        return requiredCategoryGroups.map((group) => {
                            const result = renderGroup(group, startIndex);
                            startIndex += group.matches.length;
                            return result;
                        });
                    })()}
                </section>
            </div>
            <input type="hidden" data-testid="report-id-input" value={response.reportId} />
            {response.debugInfo && isAdmin() && <DebugInfo debugInfo={response.debugInfo} />}
        </div>
    );
};

export default EstimateSuccessReport;
