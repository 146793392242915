import React from 'react';
import { routes } from '@adas/shared-types';
import { Link } from 'react-router-dom';
import IsFeatureFlagEnabled from '../../hooks/IsFeatureFlagEnabled';

export const AreaForPaidUsersOnlyMessage = () => {
    const [planPageDisabled, { loading: featureFlagLoading }] = (
        IsFeatureFlagEnabled({ variables: { featureKey: 'PLAN_PAGE_DISABLED' } })
    );

    if (featureFlagLoading) {
        return null;
    }

    return (
        <div className="notification" data-testid="area-for-paid-users-only-message">
            <h3 className="title">Feature Requires Plan</h3>
            <p>
                Seeing this feature requires the purchase of a
                {' '}
                {planPageDisabled ? 'plan' : <Link to={routes.pages.account.plans}>plan</Link>}
                .
            </p>
        </div>
    );
};

export default AreaForPaidUsersOnlyMessage;
