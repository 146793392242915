import {
    gql, MutationHookOptions, useMutation,
} from '@apollo/client';
import { QuestionnaireAnswers } from './types';

const QUESTIONNAIRE_REPORT_MUTATION = gql`
    mutation QuestionnaireReport($questionnaireAnswers: QuestionnaireAnswers!) {
        questionnaireReport(input: $questionnaireAnswers)
        @rest(
            type: "QuestionnaireReport",
            method: "POST",
            path: "api/questionnaire/report"
        ) {
            body {
                reportId
            }
        }
    }
`;

type RequestBody = {
    questionnaireAnswers: QuestionnaireAnswers
};

type DataType = {
    questionnaireReport: {
        body: {
            reportId: string,
        }
    }
};

const UseQuestionnaireReport = (options: MutationHookOptions<DataType, RequestBody> = {}) => (
    useMutation<DataType, RequestBody>(QUESTIONNAIRE_REPORT_MUTATION, options)
);

export default UseQuestionnaireReport;
