import { EstimateHoverImageItem } from '@adas/shared-types';
import CanvasRenderer, { Rectangle } from './CanvasRenderer';

export default (image: EstimateHoverImageItem) => {
    const canvas = new CanvasRenderer({
        width: image.width,
        height: image.height,
        fontFamily: 'sans-serif',
    });

    try {
        const rectanglesToDraw: Rectangle[] = [];
        canvas.setFillStyle('white');
        canvas.fillRect(0, 0, image.width, image.height);
        canvas.setFillStyle('black');

        // draw all the text within the bounds
        image.textItems.forEach((item) => {
            // calculate the font size
            let fontSize = 1;
            let textSize: TextMetrics;
            for (let i = 2; i < 100; i += 1) {
                canvas.setFontSize(i);
                const size = canvas.measureText('AghpqtyZQ'); // characters that get the actual ascent and descent of the font
                textSize = canvas.measureText(item.text);

                if (
                    size.actualBoundingBoxAscent + size.actualBoundingBoxDescent < item.height
                    && textSize.width < item.width
                ) {
                    fontSize = i;
                } else {
                    break;
                }
            }

            // draw the text
            const textHeight = textSize!.actualBoundingBoxAscent + textSize!.actualBoundingBoxDescent;
            canvas.fillText(
                item.text,
                item.x,
                item.y + (item.height / 2) - (textHeight / 2),
            );

            if (item.isHighlighted) {
                rectanglesToDraw.push({
                    x: Math.max(0, item.x - 4),
                    y: Math.max(0, item.y),
                    width: Math.min(image.width - 1, item.width + 8),
                    height: Math.min(image.height - 1, item.height),
                });
            }
        });

        // draw the scaled rectangles
        rectanglesToDraw.forEach((rect) => {
            canvas.drawRectangle(rect);
        });

        return canvas.toDataUrl();
    } finally {
        canvas.dispose();
    }
};
