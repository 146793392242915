export namespace charHelpers {
    export function isAlphaNumeric(char: string) {
        return isAlpha(char) || isNumeric(char);
    }

    export function isNumeric(char: string) {
        if (char == null) {
            return false;
        }
        const charCode = char.charCodeAt(0);
        return charCode >= 48 && charCode <= 57;
    }

    export function isAlpha(char: string) {
        if (char == null) {
            return false;
        }
        const charCode = char.charCodeAt(0);
        return (charCode >= 65 && charCode <= 90) // A-Z
            || (charCode >= 97 && charCode <= 122); // a-z
    }
}
