export const constants = {
    MinimumPasswordLength: 8,
    PasswordResetExpiryMinutes: 60,
    RowsPerPage: 10,
    Countries: {
        "CA": "Canada",
        "US": "United States",
    },
    States: {
        "US": {
            "AL": "Alabama",
            "AK": "Alaska",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "FL": "Florida",
            "GA": "Georgia",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PA": "Pennsylvania",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VA": "Virginia",
            "WA": "Washington",
            "DC": "Washington D.C.",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming",
        },
        "CA": {
            "AB": "Alberta",
            "BC": "British Columbia",
            "MB": "Manitoba",
            "NB": "New Brunswick",
            "NL": "Newfoundland and Labrador",
            "NT": "Northwest Territories",
            "NS": "Nova Scotia",
            "NU": "Nunavut",
            "ON": "Ontario",
            "PE": "Prince Edward Island",
            "QC": "Quebec",
            "SK": "Saskatchewan",
            "YT": "Yukon",
        },
    },
    Months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
    // bump this in order to force people to refresh their web browsers
    WebServerVersion: "17",
    // try to avoid changing these
    ClientOutOfDateErrorCode: 400,
    ClientOutOfDateMessage: "Client out of date",
} as const;

export type CountryCodes = keyof typeof constants.Countries;
export type StateCodes = keyof typeof constants.States.CA | keyof typeof constants.States.US;
export type Months = keyof typeof constants.Months;

if (typeof window !== "undefined") {
    (window as any)["changeWebServerVersionForTesting"] = () => {
        (constants as any).WebServerVersion = "some-other-version";
    };
}
