/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */

import React, { useState } from 'react';
import {
    VIN,
    isValidVin,
    parseVin,
    TextBox,
    Button,
} from '@adas/shared-types';

export const CustomReportForm = (
    { submitVinForReport }:
    {
        submitVinForReport:(vinPut: VIN) => Promise<void>,
    },
) => {
    const [vinString, setVinString] = useState('');
    const [vinNumber, setVinNumber] = useState<VIN | undefined>(undefined);

    const handleVINChange = (vinNum: string) => {
        setVinString(vinNum);
        if (isValidVin(vinNum)) {
            setVinNumber(parseVin(vinNum));
        }
    };

    return (
        <>
            <div className="field">
                <div className="control">
                    <TextBox
                        type="text"
                        placeholder="VIN number"
                        value={vinString}
                        onChange={(value: string) => handleVINChange(value.trim())}
                    />
                </div>
            </div>
            <Button onClick={() => vinNumber && submitVinForReport(vinNumber)}>Get Report</Button>
        </>
    );
};
