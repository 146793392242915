import React from 'react';

export interface ApplicationContext {
    isUnregistered: boolean | undefined,
    setIsUnregistered: (isUnregistered: boolean | undefined) => void;
}

export const AppContext = React.createContext<ApplicationContext>({
    isUnregistered: undefined,
    setIsUnregistered: () => {},
});
