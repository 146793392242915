import React, { ChangeEventHandler } from "react";
import {
    TextField,
    InputAdornment,
    Grid,
} from '@mui/material';

export default ({
    defaultValue,
    onChange,
    error,
}: {
    defaultValue: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    error: string | undefined;
}) => (
    <Grid item xs={12}>
        <TextField
            id="shop-name-1"
            label="Shop Name"
            data-testid="shop-name-text-box"
            error={error?.length ? true : false}
            defaultValue={defaultValue}
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <i className="fas fa-wrench"></i>
                    </InputAdornment>
                ),
            }}
            fullWidth
            helperText={`Ex. ABC Collision Center`}
            onChange={onChange}
        />
    </Grid>
)