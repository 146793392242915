import { identifiers } from "../identifiers";

export class Modal {
    static confirm(message: string): Promise<boolean | undefined> {
        const modal = document.createElement("div");
        modal.className = "modal is-active";
        modal.innerHTML = `
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <div id="${identifiers.modal.message}" class="content"></div>
                    <div class="field is-grouped">
                        <div class="control">
                            <button data-testid="modal-yes-button" id="${identifiers.modal.yesButton}" class="button is-link">Yes</button>
                        </div>
                        <div class="control">
                            <button data-testid="modal-no-button" id="${identifiers.modal.noButton}" class="button is-link is-light">No</button>
                        </div>
                    </div>
                </div>
            </div>
            <button id="${identifiers.modal.closeButton}" class="modal-close is-large" aria-label="close"></button>
        `;
        document.body.appendChild(modal);
        const messageElement = document.getElementById(identifiers.modal.message)!;
        messageElement.append(...textToElements(message));

        return new Promise<boolean | undefined>(resolve => {
            const closeButton = document.getElementById(identifiers.modal.closeButton)!;
            const yesButton = document.getElementById(identifiers.modal.yesButton)!;
            const noButton = document.getElementById(identifiers.modal.noButton)!;

            closeButton.addEventListener("click", onClose);
            yesButton.addEventListener("click", onYes);
            noButton.addEventListener("click", onNo);

            function onYes() {
                cleanup();
                resolve(true);
            }

            function onNo() {
                cleanup();
                resolve(false);
            }

            function onClose() {
                cleanup();
                resolve(undefined);
            }

            function cleanup() {
                closeButton.removeEventListener("click", onClose);
                yesButton.removeEventListener("click", onYes);
                noButton.removeEventListener("click", onNo);
                modal.remove();
            }
        });
    }

    static alert(message: string): Promise<void> {
        // Prevent overlapping Alert Modals
        if(document.getElementsByClassName('modal is-active').length > 0) {
            return new Promise((resolve) => resolve());
        }
        const modal = document.createElement("div");
        modal.className = "modal is-active";
        modal.innerHTML = `
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    <div id="${identifiers.modal.message}" class="content"></div>
                    <div class="field is-grouped">
                        <div class="control">
                            <button id="${identifiers.modal.okButton}" class="button is-link">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <button id="${identifiers.modal.closeButton}" class="modal-close is-large" aria-label="close"></button>
        `;
        document.body.appendChild(modal);

        const messageElement = document.getElementById(identifiers.modal.message)!;
        messageElement.append(...textToElements(message));

        return new Promise(resolve => {
            const closeButton = document.getElementById(identifiers.modal.closeButton)!;
            const okButton = document.getElementById(identifiers.modal.okButton)!;

            closeButton.addEventListener("click", onClose);
            okButton.addEventListener("click", onClose);
            document.addEventListener("keydown", onKeyPress);

            function onClose() {
                cleanup();
                resolve();
            }

            function onKeyPress(e: KeyboardEvent) {
                if (e.key === "Enter") {
                    onClose();
                }
            }

            function cleanup() {
                closeButton.removeEventListener("click", onClose);
                okButton.removeEventListener("click", onClose);
                document.removeEventListener("keydown", onKeyPress);
                modal.remove();
            }
        });
    }

    static input(
        message: string,
        opts: { okButtonText?: string; initialText?: string } = {},
    ): Promise<string | undefined> {
        const modal = document.createElement("div");
        const okButtonText = opts.okButtonText ?? "OK";
        modal.className = "modal is-active";
        modal.innerHTML = `
            <div class="modal-background"></div>
            <div class="modal-content">
                <form id="modal-input-form">
                    <div class="box">
                        <div id="${identifiers.modal.message}" class="content"></div>
                        <div class="field">
                            <input
                                type="input"
                                id="${identifiers.modal.inputTextBox}"
                                class="input"
                            />
                        </div>
                        <div class="field is-grouped">
                            <div class="control">
                                <button id="${identifiers.modal.okButton}" type="submit" class="button is-link">${okButtonText}</button>
                            </div>
                            <div class="control">
                                <button id="${identifiers.modal.cancelButton}" class="button is-link is-light">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <button id="${identifiers.modal.closeButton}" class="modal-close is-large" aria-label="close"></button>
        `;
        document.body.appendChild(modal);
        const messageElement = document.getElementById(identifiers.modal.message)!;
        messageElement.append(...textToElements(message));

        return new Promise<string | undefined>(resolve => {
            const inputTextBox = document.getElementById(identifiers.modal.inputTextBox)! as HTMLInputElement;
            const cancelButton = document.getElementById(identifiers.modal.cancelButton)!;
            const closeButton = document.getElementById(identifiers.modal.closeButton)!;
            const inputForm = document.getElementById("modal-input-form")!;

            if (opts.initialText != null) {
                inputTextBox.value = opts.initialText;
            }

            inputForm.addEventListener("submit", onSubmit);
            cancelButton.addEventListener("click", onClose);
            closeButton.addEventListener("click", onClose);

            function onSubmit(e: Event) {
                e.preventDefault();
                const value = inputTextBox.value; // store before cleanup
                cleanup();
                resolve(value);
            }

            function onClose() {
                cleanup();
                resolve(undefined);
            }

            function cleanup() {
                inputForm.removeEventListener("submit", onSubmit);
                closeButton.removeEventListener("click", onClose);
                cancelButton.removeEventListener("click", onClose);
                modal.remove();
            }
        });
    }

    static async withLoading<T>(action: () => Promise<T>): Promise<T> {
        const modal = document.createElement("div");
        modal.className = "modal is-active";
        modal.innerHTML = `
                <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <div class="content">Loading...</div>
                    </div>
                </div>
            `;
        document.body.appendChild(modal);

        try {
            return await action();
        } finally {
            modal.remove();
        }
    }
}

function textToElements(text: string) {
    const lines = text.split(/\r?\n/);
    const elements = [];
    for (const line of lines) {
        const element = document.createElement("p");
        element.innerText = line;
        elements.push(element);
    }
    return elements;
}
