import React from "react";
import { Link } from "react-router-dom";

export interface LinkButtonProps {
    to: string;
    id?: string;
    className?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    "aria-label"?: string;
    "aria-current"?: boolean | "false" | "true" | "page" | "step" | "location" | "date" | "time";
    "data-testid"?: string;
}

export function LinkButton(props: LinkButtonProps) {
    const disabled = props.disabled ?? false;
    const className = "button " + (props.className ?? "");
    const commonProps = {
        id: props.id,
        className: className,
        "data-testid": props["data-testid"],
    };

    if (disabled) {
        return (
            <button disabled={true} {...commonProps}>
                {props.children}
            </button>
        );
    } else {
        return (
            <Link
                to={props.to}
                aria-label={props["aria-label"]}
                aria-current={props["aria-current"]}
                {...commonProps}
                onClick={e => e.stopPropagation()}
            >
                {props.children}
            </Link>
        );
    }
}
