/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
    CircularProgress,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import {
    ReportResponses,
    VIN,
} from '@adas/shared-types';
import { Api, EstimateUploadAvailability, GetReportResponse } from '../ClientServerApi.generated';
import { CustomReportForm, MaxEstimatesReachedMessage } from './estimate';
import GetUserSettings from '../hooks/GetUserSettings';
import { NotFoundVehicle } from './estimate/NotFoundVehicle';
import { OldVehicle } from './estimate/OldVehicle';

export default () => {
    const [availabilityIsLoading, setAvailabilityIsLoading] = useState(true);
    const [estimateResponse, setEstimateResponse] = useState<ReportResponses | undefined>(undefined);
    const [reportIsLoading, setReportIsLoading] = useState(false);

    const [
        estimateUploadAvailability,
        setEstimateUploadAvailability,
    ] = useState<EstimateUploadAvailability | undefined>(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    const [userSettings, { loading: userSettingsLoading }] = GetUserSettings();

    const submitVinForReport = async (vinPut: VIN) => {
        setReportIsLoading(true);
        if (vinPut) {
            await Api.getCustomReport({ vin: vinPut }).then(({ response }: GetReportResponse) => {
                setEstimateResponse(response);
                setReportIsLoading(false);
            }).catch((err) => console.log(err));
        }
        setReportIsLoading(false);
    };

    // Check that the API is online. Not sure why we do this, but ok.
    useEffect(() => {
        if (!userSettingsLoading) {
            setAvailabilityIsLoading(true);
            Api.getEstimateUploadAvailability().then((availability) => {
                setEstimateUploadAvailability(availability);
            }).finally(() => {
                setAvailabilityIsLoading(false);
            });
        }
    }, [userSettingsLoading]);

    if (userSettingsLoading || availabilityIsLoading || reportIsLoading || isNil(userSettings)) {
        return (
            <div className="content" data-test-group="feature-flag-loading-page">
                <div className="box">
                    <CircularProgress style={{ margin: '10vh auto' }} />
                </div>
            </div>
        );
    }

    if (userSettings?.useCustomReport !== true) {
        return (
            <div className="notification is-warning" data-testid="estimate-upload-disabled-message">
                Access not authorized.
            </div>
        );
    }

    if (estimateUploadAvailability == null) {
        return (
            <div className="notification is-warning">
                There was an error loading this page. Please refresh your web browser and try again.
            </div>
        );
    }
    if (estimateResponse) {
        if (estimateResponse?.type === 'Success') {
            return <Redirect to={`/report/${estimateResponse.reportId.toLowerCase()}`} />;
        }

        if (estimateResponse?.type === 'OldVehicle') {
            return <OldVehicle response={estimateResponse} />;
        }
        if (estimateResponse?.type === 'NotFound') {
            return <NotFoundVehicle response={estimateResponse} />;
        }

        if (estimateResponse?.type === 'MaxEstimateUploadReached') {
            return (
                <MaxEstimatesReachedMessage
                    uploadDisabledReason={estimateResponse.uploadDisabledReason}
                />
            );
        }
    }
    return (
        <div className="content">
            <header>
                <h1 className="title">Custom Report</h1>
            </header>
            {
                !isEmpty(estimateUploadAvailability.message)
                && (
                    <div className="notification is-info" data-testid="estimate-upload-message">
                        {estimateUploadAvailability.message.split(/\r?\n/).map((value) => <p>{value}</p>)}
                    </div>
                )
            }
            {
                estimateUploadAvailability.isEnabled
                    ? (
                        <CustomReportForm
                            submitVinForReport={submitVinForReport}
                        />
                    )
                    : (
                        <div className="notification is-danger" data-testid="estimate-upload-disabled-message">
                            Uploading estimates is temporarily disabled. Please check back in a little while.
                        </div>
                    )
            }

        </div>
    );
};
