import { cloneDeep } from 'lodash';
import { stepToIndex } from '../helpers';
import { QuestionnaireState, Steps, QuestionnaireAnswerState } from '../types';

export default (state: QuestionnaireState, triggerStep: Steps, targetStep: Steps): QuestionnaireState => {
    const targetStepIndex = stepToIndex(targetStep);
    const triggerStepIndex = stepToIndex(triggerStep);
    if (triggerStepIndex > state.currentStep) {
        return state;
    }

    const stepsToTrashAnswers = Object.values(Steps).filter((step) => stepToIndex(step) > targetStepIndex);
    const stepsToTrashQuestions = (
        Object.values(Steps).filter((step) => stepToIndex(step) < targetStepIndex && stepToIndex(step) > triggerStepIndex)
    );

    const newState: QuestionnaireState = cloneDeep(state);
    const newAnswers: QuestionnaireAnswerState = newState.draftAnswers;

    stepsToTrashAnswers.forEach((step) => {
        switch (step) {
            case Steps.TRIM:
                newAnswers.trim = undefined;
                break;
            case Steps.SENSOR:
                newAnswers.sensors = {};
                break;
            case Steps.CAR_PART:
                newAnswers.carParts = {};
                break;
            default:
                break;
        }
    });

    stepsToTrashQuestions.forEach((step) => {
        switch (step) {
            case Steps.TRIM:
                newState.trimQuestion = undefined;
                break;
            case Steps.SENSOR:
                newState.sensorQuestions = undefined;
                break;
            case Steps.CAR_PART:
                newState.carPartQuestions = undefined;
                break;
            default:
                break;
        }
    });

    return { ...newState, draftAnswers: newAnswers, savedAnswers: newAnswers };
};
