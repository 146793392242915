import { Modal } from "@adas/shared-types";
import React, { useRef } from "react";

export function FileSelection({ onSelection }: { onSelection: (fileName: string, bytes: Uint8Array) => void }) {
    const fileInput = useRef<HTMLInputElement>(null);

    return (
        <div className="field" data-test-group="file-selection">
            <label>Provide an estimate (pdf file):</label>
            <input
                type="file"
                className="input file-upload"
                data-testid="file-upload"
                multiple={false}
                ref={fileInput}
                onChange={() => onFileChanged()}
                accept="application/pdf"
            />
        </div>
    );

    async function onFileChanged() {
        const files = fileInput?.current?.files;
        if (files == null || files.length === 0) {
            return;
        }

        try {
            const fileName = files[0].name;
            const fileBytes = await readFileToArray(files[0]);
            onSelection(fileName, fileBytes);
        } catch (error) {
            console.error(error);
            Modal.alert("There was an error getting the estimate.");
        }
    }

    function readFileToArray(blob: Blob) {
        return new Promise<Uint8Array>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                resolve(new Uint8Array(arrayBuffer));
            };
            reader.onerror = () => {
                reject(reader.error!);
            };
            reader.readAsArrayBuffer(blob);
        });
    }
}
