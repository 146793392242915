import React, { ChangeEvent } from "react";
import {
    Checkbox,
    FormControlLabel,
} from '@mui/material';

export default ({ onChange, checked }: { onChange: (event: ChangeEvent<HTMLInputElement>) => void, checked: boolean }) => <FormControlLabel
    value="end"
    control={
        <Checkbox 
            id="agree-legal"
            data-testid="agree-legal-check-box"
            checked={checked}
            onChange={onChange}
        />
    }
    label= {
        <>
            I have read and agree to be bound by the&nbsp;
            <a href="https://adasthink.com/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            &nbsp;and&nbsp;
            <a href="https://adasthink.com/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
            </a>
        </>
    }
    labelPlacement="end"
/> 