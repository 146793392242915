import React, { useState } from "react";

export const RegistrationConfirmed = ({ email }: { email: string }) => {
    return (
        <div className="content box">
            <header>
                <h1 className="title">Welcome to adasThink!</h1>
            </header>

            <p>
                You're almost ready to start using adasThink! We sent you an email at
                {` ${email} `}
                so you can let us know this is really you. Just check your email and click the confirmation link inside
                to complete your registration.
            </p>
        </div>
    );
}
