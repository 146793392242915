import { CarPartQuestion, SensorQuestion, TrimQuestion } from '../../../../hooks/Questionnaire/types';

type OperationOption = 'Install' | 'Replace' | 'Repair' | 'Repair & Replace' | 'None';
export type SensorSelection = Record<string, { name: string, isEquipped: boolean }>;
export type CarPartDamageSelection = Record<string, { name: string, operation: OperationOption }>;

export interface QuestionnaireAnswerState {
    vin: string | undefined,
    trim: string | undefined,
    sensors: SensorSelection,
    carParts: CarPartDamageSelection,
}
export type QuestionnaireState = {
    currentStep: number,
    loading: boolean,
    error: boolean,
    /** The set of answers saved after a call to the exchange api. */
    savedAnswers: QuestionnaireAnswerState,
    /** The working set of answers before call to the exchange api. */
    draftAnswers: QuestionnaireAnswerState,
    trimQuestion: TrimQuestion | undefined,
    sensorQuestions: SensorQuestion[] | undefined,
    carPartQuestions: CarPartQuestion[] | undefined,
    triggerExchange: boolean,
};

export enum Steps {
    VIN = 'VIN',
    TRIM = 'Vehicle Details',
    SENSOR = 'Sensors',
    CAR_PART = 'Parts',
    CONFIRM = 'Confirmation',
}
