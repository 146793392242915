import React from "react";
import { CustomerUserInvitations } from "./components";

export function HomePage() {
    return (
        <div className="content">
            <header>
                <h1 className="title">Welcome</h1>
            </header>
            <p>
                Welcome to the adasThink portal!
            </p>
            <CustomerUserInvitations />
        </div>
    );
}
