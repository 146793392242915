/** Used to forward attributes such as html data attributes down a component to the HTML element. */
export function getHtmlProps(props: object) {
    const newObj: object = {};
    for (const propName of Object.keys(props)) {
        if (propName.indexOf("-") >= 0) {
            (newObj as any)[propName] = (props as any)[propName];
        }
    }
    return newObj;
}
