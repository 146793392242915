import React, { useEffect, useState } from "react";
import { routes } from '@adas/shared-types';
import { Api, EstimateUploadAvailability } from "../ClientServerApi.generated";
import { LoadingBar, LinkButton } from "./components";
import { EstimateUpload, MaxEstimatesReachedMessage } from "./estimate";
import IsFeatureFlagEnabled from '../hooks/IsFeatureFlagEnabled';

export function EstimatePage() {
    const [isLoading, setIsLoading] = useState(true);
    const [estimateUploadAvailability, setEstimateUploadAvailability] = useState<
        EstimateUploadAvailability | undefined
    >(undefined);

    useEffect(() => {
        setIsLoading(true);
        Api.getEstimateUploadAvailability().then(availability => {
            setEstimateUploadAvailability(availability);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <LoadingBar />;
    }

    if (estimateUploadAvailability == null) {
        return (
            <div className="notification is-warning">
                There was an error loading this page. Please refresh your web browser and try again.
            </div>
        );
    }

    return (
        <div className="content">
            <header className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">New Report</h1>
                    </div>
                </div>
            </header>
            {renderUploadMessage(estimateUploadAvailability.message)}
            {renderContent(estimateUploadAvailability)}
        </div>
    );

    function renderContent(estimateUploadAvailability: EstimateUploadAvailability) {
        if (estimateUploadAvailability.disabledReason != null) {
            return (
                <MaxEstimatesReachedMessage
                    uploadDisabledReason={estimateUploadAvailability.disabledReason}
                />
            );
        } else if (!estimateUploadAvailability.isEnabled) {
            return (
                <div className="notification is-danger" data-testid="estimate-upload-disabled-message">
                    Uploading estimates is temporarily disabled. Please check back in a little while.
                </div>
            );
        } else {
            return <EstimateUpload />;
        }
    }

    function renderUploadMessage(message: string) {
        if (message == null || message.trim().length === 0) {
            return <></>;
        }

        return (
            <div className="notification is-info" data-testid="estimate-upload-message">
                {message.split(/\r?\n/).map(value => <p>{value}</p>)}
            </div>
        );
    }
}
