import { assertNever } from "./assertions";
import {
    AlwaysAppliesMatch,
    EstimateHoverImageItem,
    EstimateHoverItem,
    ExtractionMatch,
    ProcedureExtractionMatch,
    ReportSuccessResponse,
    SensorMatch,
    TrimVehicleFeatureAvailability,
    VehicleFeature,
    VehicleFeatureAvailability,
    VehicleFeatureCategory,
} from "./client";
import { Guid } from "./utils";

export function getReportVehicleDisplayName(vehicleName: string, trim: string | undefined) {
    // prevents:
    // * 2018 Mercedes-Benze GLA 250 GLA 250
    // * 2018 BMW 530 530i xDrive
    if (trim == null) {
        return vehicleName;
    }
    for (let i = 0; i < vehicleName.length; i++) {
        for (let j = 0; j < trim.length; j++) {
            const nameIndex = i + j;
            if (vehicleName.charCodeAt(nameIndex) !== trim.charCodeAt(j)) {
                break;
            }
            if (nameIndex === vehicleName.length - 1 && vehicleName[i - 1] === " ") {
                return `${vehicleName.substring(0, i)}${trim}`;
            }
        }
    }
    return `${vehicleName} ${trim}`;
}

const vehicleFeatureCategoryOrder = [
    VehicleFeatureCategory.ADAS,
    VehicleFeatureCategory.Steering,
    VehicleFeatureCategory.Safety,
    VehicleFeatureCategory.Operational,
    VehicleFeatureCategory.ComfortAndConvenience,
];

export function reportMatchSorter(a: ReportMatch, b: ReportMatch) {
    const sortOrder = featureCategorySorter(a.category, b.category);
    return sortOrder === 0 ? a.sensor.name.localeCompare(b.sensor.name) : sortOrder;
}

export function featureCategorySorter(
    // this allows passing in any type that matches the union of the enum's values
    a: `${VehicleFeatureCategory}` | undefined,
    b: `${VehicleFeatureCategory}` | undefined,
) {
    if (a === b) {
        return 0;
    } else if (a == null) {
        return 1;
    } else if (b == null) {
        return -1;
    }

    const vehicleFeatureCategoryOrderUnionItems = vehicleFeatureCategoryOrder as `${VehicleFeatureCategory}`[];
    const aIndex = vehicleFeatureCategoryOrderUnionItems.indexOf(a);
    const bIndex = vehicleFeatureCategoryOrderUnionItems.indexOf(b);
    return aIndex < bIndex ? -1 : 1;
}

export interface ReportMatchCategoryGroup {
    category: VehicleFeatureCategory | undefined;
    matches: ReportMatch[];
}

export function getMatchCategoryGroups(matches: ReportMatch[]) {
    const result: ReportMatchCategoryGroup[] = [];

    for (const match of matches) {
        const group = getGroupForCategory(match.category);
        group.matches.push(match);
    }

    result.sort((a, b) => featureCategorySorter(a.category, b.category));

    return result;

    function getGroupForCategory(category: VehicleFeatureCategory | undefined) {
        let group = result.find(g => g.category === category);
        if (group == null) {
            group = {
                category,
                matches: [],
            };
            result.push(group);
        }
        return group;
    }
}

export function getFeatureCategoryDisplayName(category: VehicleFeatureCategory | undefined) {
    switch (category) {
        case VehicleFeatureCategory.ADAS:
            return "ADAS";
        case VehicleFeatureCategory.Safety:
            return "Safety";
        case VehicleFeatureCategory.ComfortAndConvenience:
            return "Comfort & Convenience";
        case VehicleFeatureCategory.Steering:
            return "Steering";
        case VehicleFeatureCategory.Operational:
            return "Operational";
        case undefined:
            return "Unknown";
        default:
            const _assertNever: never = category;
            return "Unknown";
    }
}

export interface SensorOverride {
    sensorId: Guid;
    isOnVehicle: boolean;
}

export interface ResponseToReportOptions {
    trim: string | undefined;
    sensorOverrides: readonly SensorOverride[];
}

export interface ReportVehicleFeature {
    vehicleFeatureId: Guid;
    name: string;
    category: VehicleFeatureCategory;
    trimAvailabilities: TrimVehicleFeatureAvailability[];
}

export interface ReportMatch {
    sensor: ReportMatchSensor;
    category: VehicleFeatureCategory | undefined;
    features: ReportVehicleFeature[];
    procedures: ProcedureExtractionMatch[];
    alwaysApplies: AlwaysAppliesMatch[];
    isRequired: boolean;
    hasToolsForCalibration: boolean;
}

export interface ReportMatchSensor {
    sensorId: Guid;
    name: string;
}

export interface SensorSelection {
    sensorId: Guid;
    sensorName: string;
    hoverItem: EstimateHoverItem | undefined;
}

export interface Report {
    requiredMatches: ReportMatch[];
    potentialMatches: ReportMatch[];
    notEquippedMatches: ReportMatch[];
    equippedFeatures: ReportVehicleFeature[] | undefined;
    optionalFeatures: ReportVehicleFeature[] | undefined;
    sensorSelections: SensorSelection[];
    sensorOverrides: SensorOverride[];
}

enum LocalFeatureAvailability {
    Equipped,
    Optional,
    NotEquipped,
}

export function responseToReport(
    response: ReportSuccessResponse,
    options: ResponseToReportOptions,
): Report {
    const sensorOverrides = getPreFilledSensorOverrides();
    const selectedTrim = options.trim ?? (response.vehicle.trims.length === 1 ? response.vehicle.trims[0] : undefined);
    const vehicleFeatures = response.features;
    const filteredMatches = (options.trim == null
        ? response.matches
        : response.matches.filter(match =>
            match.trimAvailabilities.some(a => a.trim === selectedTrim)
            || match.alwaysApplies.some(a => !a.requiresAdasFeature)
            || isSensorMatchEquipped(match.sensor.match)
        ));
    const equippedFeatures = vehicleFeatures
        ?.filter(isEquippedFeature);
    const optionalFeatures = vehicleFeatures
        ?.filter(isOptionalFeature);
    const vinProviderFeatures = getVinProviderEquippedAndOptionalFeatures(vehicleFeatures, {
        selectedTrim,
        trims: response.vehicle.trims,
    });
    const matches = getCategorizedMatches(filteredMatches);

    return {
        requiredMatches: matches.required,
        potentialMatches: matches.potential,
        notEquippedMatches: matches.declined,
        equippedFeatures: equippedFeatures?.map(vehicleFeatureToReportAdasFeature),
        optionalFeatures: optionalFeatures?.map(vehicleFeatureToReportAdasFeature),
        sensorSelections: filteredMatches
            .filter(m => {
                if (vinProviderFeatures == null) {
                    return true;
                }
                if (isAnyFeatureEquippedInVinInfoProvider(m.features ?? [])) {
                    return false;
                }
                if (isAnyFeatureOptionalInVinInfoProvider(m.features ?? [])) {
                    return true;
                }
                return isSensorMatchEquipped(m.sensor.match);
            })
            .map(m => ({
                sensorId: m.sensor.sensorId,
                sensorName: m.sensor.name,
                hoverItem: m.sensor.match?.hoverItem,
            })),
        sensorOverrides,
    };

    function getCategorizedMatches(filteredMatches: readonly ExtractionMatch[]) {
        const requiredMatches = [];
        const potentialMatches = [];
        const notEquippedMatches = [];
        for (const match of filteredMatches) {
            const isRequired = getMatchRequired(match);
            if (isRequired === true) {
                // matches that the user said "YES" to
                requiredMatches.push(toReportMatch(match));
            } else if (isRequired === false) {
                // matches the user said "NO" to
                notEquippedMatches.push(toReportMatch(match));
            } else if (isRequired === undefined) {
                // matches the user hasn't answered about yet
                potentialMatches.push(toReportMatch(match));
            } else {
                assertNever(isRequired);
            }
        }
        return {
            required: requiredMatches.sort(reportMatchSorter),
            potential: potentialMatches.sort(reportMatchSorter),
            declined: notEquippedMatches.sort(reportMatchSorter),
        };
    }

    function toReportMatch(match: ExtractionMatch): ReportMatch {
        return {
            sensor: {
                sensorId: match.sensor.sensorId,
                name: match.sensor.name,
            },
            category: match.category,
            features: match.features.map(vehicleFeatureToReportAdasFeature),
            procedures: [...match.procedures],
            alwaysApplies: [...match.alwaysApplies],
            isRequired: getMatchRequired(match) ?? false,
            hasToolsForCalibration: match.hasToolsForCalibration,
        };
    }

    function getMatchRequired(match: ExtractionMatch) {
        if (isAnyFeatureEquipped(match.features)) {
            return true;
        }
        const sensorOverride = sensorOverrides.find(o => o.sensorId === match.sensor.sensorId);
        if (sensorOverride == null) {
            return undefined;
        }
        return sensorOverride.isOnVehicle;
    }

    function isAnyFeatureEquipped(features: VehicleFeature[]) {
        return equippedFeatures != null
            && equippedFeatures.some(f => isVehicleFeatureInFeatures(f, features));
    }

    function isAnyFeatureEquippedInVinInfoProvider(features: VehicleFeature[]) {
        return vinProviderFeatures != null
            && vinProviderFeatures.equipped.some(f => isVehicleFeatureInFeatures(f, features));
    }

    function isAnyFeatureOptionalInVinInfoProvider(features: VehicleFeature[]) {
        return vinProviderFeatures != null
            && vinProviderFeatures.optional.some(f => isVehicleFeatureInFeatures(f, features));
    }

    function isVehicleFeatureInFeatures(feature: VehicleFeature, features: VehicleFeature[]) {
        return features.some(f => f.vehicleFeatureId === feature.vehicleFeatureId);
    }

    function getFeatureAvailability(feature: VehicleFeature) {
        // check if the feature is equipped according to vin info provider
        const vinProviderFeatureAvailability = getVinProviderFeatureAvailability(feature, {
            selectedTrim,
            trims: response.vehicle.trims,
        });
        if (vinProviderFeatureAvailability === LocalFeatureAvailability.Equipped) {
            return LocalFeatureAvailability.Equipped;
        }

        // check if any of the matches have this sensor name and if the sensor name is listed as equipped
        for (const match of filteredMatches) {
            if (match.features != null) {
                if (isVehicleFeatureInFeatures(feature, match.features)) {
                    const sensorOverride = sensorOverrides.find(o => o.sensorId === match.sensor.sensorId);
                    if (sensorOverride != null) {
                        if (sensorOverride.isOnVehicle) {
                            return LocalFeatureAvailability.Equipped;
                        }
                    }
                }
            }
        }

        return vinProviderFeatureAvailability;
    }

    function isEquippedFeature(feature: VehicleFeature) {
        return getFeatureAvailability(feature) === LocalFeatureAvailability.Equipped;
    }

    function isOptionalFeature(feature: VehicleFeature) {
        return getFeatureAvailability(feature) === LocalFeatureAvailability.Optional;
    }

    function getPreFilledSensorOverrides() {
        const sensorOverrides = [...options.sensorOverrides];
        for (const match of response.matches) {
            const sensorMatch = match.sensor.match;
            if (sensorMatch != null) {
                let isEquipped: boolean;
                switch (sensorMatch.kind) {
                    case "CarPart":
                        isEquipped = true;
                        break;
                    case "SensorName":
                    case "CarPartAndSensorName":
                        isEquipped = sensorMatch.isEquipped;
                        break;
                }
                if (!sensorOverrides.some(o => o.sensorId === match.sensor.sensorId)) {
                    sensorOverrides.push({
                        isOnVehicle: isEquipped,
                        sensorId: match.sensor.sensorId,
                    });
                }
            }
        }
        return sensorOverrides;
    }
}

export function getVinProviderEquippedAndOptionalFeatures(
    adasFeatures: readonly Readonly<VehicleFeature>[] | undefined,
    opts: {
        trims: readonly string[];
        selectedTrim: string | undefined;
    },
) {
    if (adasFeatures == null) {
        return undefined;
    }
    const equipped = [];
    const optional = [];

    for (const feature of adasFeatures) {
        const availability = getVinProviderFeatureAvailability(feature, opts);
        if (availability === LocalFeatureAvailability.Equipped) {
            equipped.push(feature);
        } else if (availability === LocalFeatureAvailability.Optional) {
            optional.push(feature);
        }
    }

    return {
        equipped,
        optional,
    };
}

function getVinProviderFeatureAvailability(feature: Readonly<VehicleFeature>, opts: {
    trims: readonly string[];
    selectedTrim: string | undefined;
}): LocalFeatureAvailability {
    if (opts.selectedTrim == null) {
        if (feature.trimAvailabilities.length !== opts.trims.length) {
            return LocalFeatureAvailability.Optional; // optional, since one of the trims doesn't have this
        }

        if (feature.trimAvailabilities.every(a => a.availability === VehicleFeatureAvailability.Equipped)) {
            return LocalFeatureAvailability.Equipped;
        } else {
            return LocalFeatureAvailability.Optional;
        }
    } else {
        const trimAvailability = feature.trimAvailabilities.find(a => a.trim === opts.selectedTrim);
        if (trimAvailability == null) {
            return LocalFeatureAvailability.NotEquipped;
        } else {
            switch (trimAvailability.availability) {
                case VehicleFeatureAvailability.Equipped:
                    return LocalFeatureAvailability.Equipped;
                case VehicleFeatureAvailability.Optional:
                    return LocalFeatureAvailability.Optional;
                default:
                    assertNever(trimAvailability.availability);
            }
        }
    }
}

export interface MatchLink {
    kind: "calibration" | "allData" | "both";
    url: string;
}

export function getMatchLinks(match: ReportMatch): MatchLink[] {
    const calibrationUrls = prepareUrls([
        ...match.procedures.map(p => p.calibrationUrl),
        ...match.alwaysApplies.map(p => p.calibrationUrl),
    ], "calibration");
    const allDataUrls = prepareUrls([
        ...match.procedures.map(p => p.allDataUrl),
        ...match.alwaysApplies.map(p => p.allDataUrl),
    ], "allData");

    // find links in both arrays and move them to the "both" area (todo: unit tests...)
    const both: MatchLink[] = [];
    for (let i = calibrationUrls.length - 1; i >= 0; i--) {
        const calibrationUrl = calibrationUrls[i];
        const index = allDataUrls.findIndex(u => u.url === calibrationUrl.url);
        if (index >= 0) {
            calibrationUrls.splice(i, 1);
            allDataUrls.splice(index, 1);
            both.push({
                kind: "both",
                url: calibrationUrl.url,
            });
        }
    }

    return [
        ...calibrationUrls,
        ...allDataUrls,
        ...both,
    ];

    function prepareUrls(urls: string[], kind: MatchLink["kind"]): MatchLink[] {
        return urls.map(url => url.trim())
            .filter((value, index) => urls.indexOf(value) === index && value.length > 0)
            .map(url => ({
                kind,
                url,
            }));
    }
}

export function isUrl(url: string) {
    return url.toLowerCase().startsWith("http"); // covers https
}

function vehicleFeatureToReportAdasFeature(vehicleFeature: VehicleFeature): ReportVehicleFeature {
    return {
        vehicleFeatureId: vehicleFeature.vehicleFeatureId,
        name: vehicleFeature.name,
        category: vehicleFeature.category,
        trimAvailabilities: vehicleFeature.trimAvailabilities.map(a => ({ ...a })),
    };
}

export function isSensorMatchEquipped(sensorMatch: SensorMatch | undefined) {
    if (sensorMatch == null) {
        return false;
    }

    switch (sensorMatch.kind) {
        case "CarPart":
            return true;
        case "SensorName":
        case "CarPartAndSensorName":
            return sensorMatch.isEquipped;
        default:
            const _assertNever: never = sensorMatch;
            return false;
    }
}
