/** Used to show notifications in the bottom right corner of a screen. */
export class Notification {
    private static timeout: NodeJS.Timeout | undefined;

    static show(message: string) {
        Notification.showContainer(message, false);
    }

    static showError(message: string) {
        Notification.showContainer(message, true);
    }

    private static showContainer(message: string, isError: boolean) {
        Notification.hideCurrent();

        const container = Notification.getContainer();
        container.innerText = message;
        if (isError) {
            container.classList.add("is-danger");
            container.classList.remove("is-success");
        } else {
            container.classList.remove("is-danger");
            container.classList.add("is-success");
        }
        container.classList.add("shown");

        Notification.timeout = setTimeout(() => Notification.hideCurrent(), 4_000);
    }

    private static hideCurrent() {
        Notification.getContainer().classList.remove("shown");

        if (Notification.timeout != null) {
            clearTimeout(Notification.timeout);
            Notification.timeout = undefined;
        }
    }

    private static _initialized = false;
    private static getContainer() {
        if (!Notification._initialized) {
            const notificationElement = document.createElement("div");
            notificationElement.className = "notification";
            notificationElement.id = "notification";
            notificationElement.setAttribute("data-testid", "notification");
            document.body.appendChild(notificationElement);
            Notification._initialized = true;
        }
        return document.getElementById("notification")!;
    }
}
