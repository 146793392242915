import { isEqual, mapValues } from 'lodash';
import client from '../../../../../RestClient';
import { QUESTIONNAIRE_EXCHANGE_QUERY, RequestBody, DataType } from '../../../../../hooks/Questionnaire/UseQuestionnaireExchange';
import {
    SensorQuestion, CarPartQuestion,
} from '../../../../../hooks/Questionnaire/types';
import {
    QuestionnaireAnswerState, QuestionnaireState, Steps,
} from '../types';
import {
    QuestionnaireAction,
    setExchangeLoading,
    addTrimQuestion,
    addCarPartsQuestions,
    addSensorQuestions,
    completeExchange,
    setExchangeError,
} from '../actionCreators';

// this operates similar to a thunk, will dispatch multiple actions asynchronously
export default (dispatch: React.Dispatch<QuestionnaireAction>, state: QuestionnaireState) => {
    const {
        vin, sensors, carParts, trim,
    } = state.draftAnswers;

    // which form triggered the call
    let trigger: Steps;
    if (vin !== state.savedAnswers.vin) {
        trigger = Steps.VIN;
    } else if (trim !== state.savedAnswers.trim) {
        trigger = Steps.TRIM;
    } else if (!isEqual(sensors, state.savedAnswers.sensors)) {
        trigger = Steps.SENSOR;
    } else if (!isEqual(carParts, state.savedAnswers.carParts)) {
        trigger = Steps.CAR_PART;
    } else {
        trigger = Steps.CONFIRM;
    }
    const vinUpdated = vin !== state.savedAnswers.vin;
    const trimUpdated = trim !== state.savedAnswers.trim;
    const sensorsUpdated = !isEqual(sensors, state.savedAnswers.sensors);

    const newDraftAnswers: QuestionnaireAnswerState = {
        vin,
        trim: vinUpdated ? undefined : trim,
        sensors: (trimUpdated || vinUpdated) ? {} : sensors,
        carParts: (sensorsUpdated || trimUpdated || vinUpdated) ? {} : carParts,
    };
    dispatch(setExchangeLoading());
    client.query<DataType, RequestBody>({
        query: QUESTIONNAIRE_EXCHANGE_QUERY,
        variables: {
            questionnaireAnswers: {
                vin: newDraftAnswers.vin,
                answers: {
                    trim: newDraftAnswers.trim,
                    sensors: mapValues(newDraftAnswers.sensors, (sensor) => sensor.isEquipped),
                    carParts: mapValues(newDraftAnswers.carParts, (part) => part.operation),
                },
            },
        },
    }).then(({ data }) => {
        const { questions } = data.questionnaireExchange;
        switch (questions?.[0]?.type) {
            case 'TRIM':
                dispatch(addTrimQuestion({ question: questions?.[0], trigger }));
                break;
            case 'SENSOR':
                dispatch(addSensorQuestions({ questions: questions as SensorQuestion[], trigger }));
                break;
            case 'CAR_PART':
                dispatch(addCarPartsQuestions({ questions: questions as CarPartQuestion[], trigger }));
                break;
            default:
                dispatch(completeExchange({ trigger }));
                break;
        }
    }).catch(() => {
        dispatch(setExchangeError());
    });
};
