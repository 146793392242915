import { isEmpty, isNil } from "lodash";

export const getAppToken = () => {
    const appToken = localStorage.getItem('appToken');

    if(isNil(appToken) || isEmpty(appToken)) {
        return null;
    }

    if(appToken[0] !== '"') {
        return null;
    }

    try {
        return JSON.parse(appToken) as string;
    } catch (error) {
        if(error instanceof SyntaxError){
            return null
        } else {
            throw (error);
        }
    }
}

export const setAppToken = (token: string) => {
    localStorage.setItem('appToken', JSON.stringify(token));
}

export const deleteAppToken = () => {
    localStorage.removeItem('appToken');
}