import { isNil } from 'lodash';

interface CanvasRendererOptions {
    width: number;
    height: number;
    fontFamily: string;
}

interface Dimensions {
    width: number;
    height: number;
}

export interface Rectangle extends Dimensions {
    x: number;
    y: number;
}

class CanvasRenderer {
    private canvas: HTMLCanvasElement;

    private context: CanvasRenderingContext2D;

    constructor(private readonly options: CanvasRendererOptions) {
        this.canvas = document.createElement('canvas');
        this.canvas.width = options.width;
        this.canvas.height = options.height;
        const context2d = this.canvas.getContext('2d');
        if (isNil(context2d)) {
            throw new Error('Could not get 2d rendering context');
        }
        this.context = context2d;
        this.context.textBaseline = 'top';
    }

    setFillStyle(colour: string) {
        this.context.fillStyle = colour;
    }

    fillRect(x: number, y: number, width: number, height: number) {
        this.context.fillRect(x, y, width, height);
    }

    fillText(text: string, x: number, y: number, maxWidth?: number) {
        this.context.fillText(text, x, y, maxWidth);
    }

    setFontSize(size: number) {
        this.context.font = `${size}px ${this.options.fontFamily}`;
    }

    measureText(text: string) {
        return this.context.measureText(text);
    }

    drawRectangle(rect: Rectangle) {
        this.context.beginPath();
        this.context.rect(rect.x, rect.y, rect.width, rect.height);
        this.context.lineWidth = 1;
        this.context.strokeStyle = 'blue';
        this.context.stroke();
        this.context.closePath();
    }

    toDataUrl() {
        return this.canvas.toDataURL();
    }

    dispose() {
        this.canvas.remove();
    }
}
export default CanvasRenderer;
