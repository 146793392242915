import { TextBox } from "@adas/shared-types";
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { AddEditCreditCardView, Api } from "../../ClientServerApi.generated";
import { StripeApi } from "../../stripe";
import { TestStripeApi } from "../../stripe/TestStripeApi";

export interface CreditCardUpdater {
    update(): Promise<void>;
    clear(): void;
}

class RealCreditCardUpdater {
    constructor(
        private readonly stripeApi: StripeApi,
        private readonly stripe: Stripe,
        private readonly elements: StripeElements,
    ) {
    }

    async update() {
        if (!this.stripe || !this.elements) {
            throw new Error("Error saving credit card. Please try again.");
        }
        const cardElement = this.elements.getElement(CardElement);
        if (cardElement == null) {
            throw new Error("Error finding card form. Please report this to customerservice@astech.com.");
        }
        const setupIntentSecretResponse = await Api.getStripeSetupIntentSecret();
        const response = await this.stripeApi.sendCard(this.stripe, cardElement, setupIntentSecretResponse.secret);
        if (response.error) {
            console.error(response.error);
            throw new Error(response.error.message!);
        }
        const stripePaymentMethodId = response.setupIntent?.payment_method as string;
        if (stripePaymentMethodId == null) {
            throw new Error("No payment method found. Please report this to customerservice@astech.com.");
        }
        await Api.updateCreditCard({ stripePaymentMethodId });
    }

    clear() {
        if (this.stripe == null || this.elements == null) {
            return;
        }
        this.elements.getElement(CardElement)?.clear();
    }
}

class TestCreditCardUpdater {
    creditCardNumber = "";

    async update() {
        if (this.creditCardNumber.trim().length === 0) {
            throw new Error("Please provide a credit card number.");
        }
        const testApi = new TestStripeApi();
        await testApi.sendCard(this.creditCardNumber.trim());
    }

    clear() {
        // do nothing...
    }
}

export interface AddEditCreditCardProps {
    creditCardView: AddEditCreditCardView;
    clearErrorMessage: () => void;
    setCreditCardUpdater: (updater: CreditCardUpdater) => void;
}

export function AddEditCreditCard({ creditCardView, clearErrorMessage, setCreditCardUpdater }: AddEditCreditCardProps) {
    if (creditCardView.isTestApi) {
        return <TestCreditCardInput setCreditCardUpdater={setCreditCardUpdater} />;
    } else {
        return (
            <RealCreditCardInput
                creditCardView={creditCardView}
                clearErrorMessage={clearErrorMessage}
                setCreditCardUpdater={setCreditCardUpdater}
            />
        );
    }
}

function RealCreditCardInput({
    creditCardView,
    clearErrorMessage,
    setCreditCardUpdater,
}: {
    creditCardView: AddEditCreditCardView;
    clearErrorMessage: () => void;
    setCreditCardUpdater: (updater: CreditCardUpdater) => void;
}) {
    const stripePublicKey = creditCardView.stripePublicKey;
    const stripeApi = useMemo(() => new StripeApi(stripePublicKey), [stripePublicKey]);
    return (
        <Elements stripe={stripeApi.stripe()}>
            <ElementsConsumer>
                {({ elements, stripe }) => {
                    return (
                        <StripeCreditCardInput
                            elements={elements ?? undefined}
                            stripe={stripe ?? undefined}
                            stripeApi={stripeApi}
                            clearErrorMessage={clearErrorMessage}
                            setCreditCardUpdater={setCreditCardUpdater}
                        />
                    );
                }}
            </ElementsConsumer>
        </Elements>
    );
}

function StripeCreditCardInput({
    elements,
    stripe,
    stripeApi,
    clearErrorMessage,
    setCreditCardUpdater,
}: {
    elements: StripeElements | undefined;
    stripe: Stripe | undefined;
    stripeApi: StripeApi;
    clearErrorMessage: () => void;
    setCreditCardUpdater: (updater: CreditCardUpdater) => void;
}) {
    useEffect(() => {
        if (elements != null && stripe != null) {
            setCreditCardUpdater(new RealCreditCardUpdater(stripeApi, stripe, elements));
        }
    }, [elements, stripe, stripeApi]);

    return (
        <div className="field">
            <div
                style={{
                    borderColor: "#dbdbdb",
                    borderRadius: "4px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "10px",
                    backgroundColor: "#fff",
                }}
            >
                <CardElement
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: "16px",
                                color: "#363636",
                            },
                        },
                    }}
                    onChange={() => clearErrorMessage()}
                />
            </div>
        </div>
    );
}

function TestCreditCardInput({
    setCreditCardUpdater,
}: {
    setCreditCardUpdater: (value: TestCreditCardUpdater) => void;
}) {
    const [testCardNumber, setTestCardNumber] = useState("");
    const creditCardUpdater = useMemo(() => new TestCreditCardUpdater(), []);

    useEffect(() => {
        setCreditCardUpdater(creditCardUpdater);
    }, []);

    useEffect(() => {
        if (creditCardUpdater != null) {
            creditCardUpdater.creditCardNumber = testCardNumber;
        }
    }, [testCardNumber, creditCardUpdater]);

    return (
        <>
            <div className="field" data-test-group="test-credit-card">
                <div className="control has-icons-left">
                    <TextBox
                        data-testid="test-credit-card-number-text-box"
                        value={testCardNumber}
                        onChange={value => setTestCardNumber(value)}
                    />
                    <span className="icon is-small is-left">
                        <i className="fas fa-credit-card"></i>
                    </span>
                </div>
            </div>
        </>
    );
}
