import React from 'react';
import {
    Box, Button, Typography,
} from '@mui/material';

export default ({
    headerText,
    confirmButtonDisplay = 'Next',
    confirmButtonLoading = false,
    onSubmit,
    areAnswersValid,
    navigateBack,
    children,
}: {
    headerText: string,
    confirmButtonDisplay?: string
    confirmButtonLoading?: boolean
    onSubmit: () => void,
    areAnswersValid: boolean,
    navigateBack: () => void,
    children: React.ReactNode,
}) => (
    <>
        <Box sx={{ my: 5 }}>
            <Typography sx={{ color: '#4a4a4a' }}>{headerText}</Typography>
        </Box>
        {children}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                variant="contained"
                sx={{ mt: 3, ml: 1 }}
                onClick={() => navigateBack()}
            >
                Back
            </Button>
            <Button
                variant="contained"
                sx={{ mt: 3, ml: 1 }}
                color="primary"
                disabled={confirmButtonLoading || !areAnswersValid}
                onClick={() => onSubmit()}
            >
                {confirmButtonDisplay}
            </Button>
        </Box>
    </>
);
