/** String that is lowercase in order to do case insensitive searches. */
export type SearchString = string & { _searchStringBrand: undefined };

export namespace stringUtils {
    export function pluralize(text: string, count: number) {
        if (count === 1) {
            return text;
        } else {
            return `${text}s`;
        }
    }

    export function getPossesiveName(name: string) {
        return name + (name.endsWith("s") ? "'" : "'s");
    }

    export function isNullOrWhitespace(text: string | undefined): text is undefined {
        if (text == null) {
            return true;
        }

        // todo: something faster
        return /^\s*$/.test(text);
    }

    export function indexesOf(text: SearchString, searchText: SearchString) {
        let indexes: number[] | undefined;
        if (searchText.length === 0) {
            return indexes;
        }
        for (let i = 0; i < text.length; i++) {
            for (let j = 0; j < searchText.length; j++) {
                if (text.charCodeAt(i + j) !== searchText.charCodeAt(j)) {
                    break;
                }

                if (j == searchText.length - 1) {
                    if (indexes == null) {
                        indexes = [];
                    }
                    indexes.push(i);
                }
            }
        }
        return indexes;
    }

    export function getSearchString(text: string): SearchString {
        return text.toLowerCase() as SearchString;
    }
}
