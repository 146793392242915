import {
    axiosPostOldApi, routes, UserPermissions, UserSettings,
} from '@adas/shared-types';

/**
 * This is what is remaining of the old non-code generated API.
 * Ideally, everything should be migrated to the code generated classes.
 */
export interface LoginMessage {
    idToken: string;
}

type LoginResponse = {
    success: true;
    token: string;
    data: UserPermissions;
    settings: UserSettings;
} | {
    success: false;
    token: string;
    message: string;
};
export class OldApi {
    static login = (message: LoginMessage) => (
        axiosPostOldApi<LoginMessage, LoginResponse>(routes.data.post.login, message)
    );
}
