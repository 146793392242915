import React, {useState} from "react";
import { Button } from "../Button";
import {
    Grid,
} from '@mui/material';
import validateRegistrationForm, { Address, ValidationErrorResponse, ValidationField } from "./validateRegistrationForm";
import FirstNameField from "./FirstNameField";
import LastNameField from "./LastNameField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import ShopNameField from "./ShopNameField";
import ErrorMessage from "./ErrorMessage";
import { EditAddress } from "../EditAddress";

export interface AdminRegistrationMessage {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    shopName: string;
    shopAddress: Address;
}

export const AdminRegistrationForm = (
    {
        isSubmitting,
        onSubmit,
        apiErrorResponse,
    } : 
    {
        isSubmitting: boolean;
        onSubmit: (regForm: AdminRegistrationMessage) => void;
        apiErrorResponse: string | undefined;
    }
) => {
    const [registerMessage, setRegisterMessage] = useState<AdminRegistrationMessage>({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        shopName: '',
        phone: '',
        shopAddress: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            country: 'US',
            state: "AL",
            zip: '',
        },
    });
    const [validationMessage, setValidationMessage] = useState<ValidationErrorResponse>({});

    const [errorMessage, setErrorMessage] = useState('');

    const fieldsToValidate: ValidationField[] = ['firstName', 'lastName', 'shopName', 'shopAddress', 'email', 'phone']
    const runFormValidation = () => {
        const validationCheck = validateRegistrationForm(registerMessage, fieldsToValidate);
        setValidationMessage(validationCheck?.errorMessages ?? {})
        setErrorMessage(validationCheck?.displayMessage?.[0] ?? '')
        return validationCheck;
    };

    const validateAndSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const validatedFormInputs = runFormValidation();
        if(!validatedFormInputs.displayMessage.length) {
            onSubmit(registerMessage);
        }
    };

    const updateRegisterMessage = (prop: Partial<AdminRegistrationMessage>) => {
        setRegisterMessage({ ...registerMessage, ...prop });
    };

    const { firstName, lastName, shopAddress, shopName, phone, email } = registerMessage;
    return (
        <form method="post" onSubmit={validateAndSubmit} >
            <Grid container spacing={1} columnSpacing={2}>
                <Grid item container spacing={2} columnSpacing={2} paddingBottom={2}>
                    <Grid item xs={12}>
                        <h2>User Info</h2>
                    </Grid>
                    <Grid item xs={6}>
                        <FirstNameField
                            defaultValue={firstName}
                            onChange={(value) => updateRegisterMessage({ firstName: value.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LastNameField
                            defaultValue={lastName}
                            onChange={(value) => updateRegisterMessage({ lastName: value.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EmailField 
                            error={validationMessage?.email}
                            defaultValue={email}
                            disabled={false}
                            onChange={(e) => updateRegisterMessage({ email: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneField
                            value={phone}
                            onChange={(value) => updateRegisterMessage({ phone: value })}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <h2>Shop Info</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <ShopNameField
                            defaultValue={shopName}
                            onChange={(e) => updateRegisterMessage({ shopName: e.target.value })}
                            error={validationMessage?.shopName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EditAddress
                            onChange={(address) => updateRegisterMessage({shopAddress: {...shopAddress, ...address}})}
                            address={shopAddress}
                            isShopAddress
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} columnSpacing={2} paddingTop={2} xs={12}>
                    <Grid item xs={12} justifyContent="center" paddingTop={1}>
                        <ErrorMessage errorMessage={errorMessage} apiErrorResponse={apiErrorResponse} />
                        <Button data-testid="register-button" kind="link" type="submit" disabled={isSubmitting}>
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default AdminRegistrationForm;