import { assertCountryCode, assertStateCode, assertString } from "./assertions";
import { CountryCodes, StateCodes } from "./constants";

export type ApiSucessResponse<T = undefined> = { success: true; data: T } | { success: false; message: string };

export enum UserPermissions {
    AllUsers = 0,
    PdfUploads = 1 << 0,
    Admin = 1 << 1,
    AdminDownload = 1 << 2,
    UserManagement = 1 << 3,
    LogViewing = 1 << 4,
    Reports = 1 << 5,
}

export interface LoginMessage {
    email: string;
    password: string;
}

export function validateLoginMessage(message: LoginMessage) {
    assertString(message.email, "email");
    assertString(message.password, "password");
}

// todo: eventually remove this as it's already on the ClientServer
export interface Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: StateCodes;
    country: CountryCodes;
    zip: string;
}

export function validateAddress(address: Address) {
    assertString(address.addressLine1, "addressLine1");
    assertString(address.addressLine2, "addressLine2");
    assertString(address.city, "city");
    assertString(address.zip, "zip");
    assertCountryCode(address.country, "country");
    assertStateCode(address.state, address.country, "state");
}

export interface EmailMessageData {
    from: string;
    to: string | readonly string[];
    subject: string;
    html: string;
}

export interface NewUserPermissions {
    [key: string]: boolean
}
export interface AvailablePermissions {
    permissionKey: string;
    permissionName: string;
}

export interface UserSettings {
    useCustomReport: boolean;
    defaultVehicleInCollision: boolean;
}

export type UserPermissionKey = keyof NewUserPermissions