/** Milliseconds since epoch. Used instead of Date objects because it serializes better. */
export type Instant = number & { _instantBrand: undefined };

export function dateToInstant(date: Date): Instant {
    return date.getTime() as Instant;
}

export function millisecondsToInstant(milliseconds: number): Instant {
    return milliseconds as Instant;
}

export function instantToDate(instant: Instant): Date {
    return new Date(instant);
}

export function getFormattedDate(instant: Instant) {
    const date = instantToDate(instant);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${leftPadNum(month)}-${leftPadNum(day)}`;
}

export function getUtcFormattedYearAndMonth(instant: Instant) {
    const date = instantToDate(instant);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;

    return `${year}-${leftPadNum(month)}`;
}

function leftPadNum(value: number) {
    if (value < 10) {
        return `0${value}`;
    }
    return value;
}
