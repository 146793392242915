import {
    gql, QueryHookOptions, QueryResult, OperationVariables, useQuery,
} from '@apollo/client';
import { UserProfileResponse } from '../ClientServerApi.generated';

const query = gql`
    query GetUserProfile {
        userProfile @rest(type: "UserProfile", path: "api/user-profile?v=17") {
            email
            firstName
            coverageIndicatorEnabled
            lastName
            phone
            shopAddress
            shopName
        }
    }
`;

type DataType = { userProfile: UserProfileResponse };

type HookType = (options?: QueryHookOptions<DataType>) => (
    [UserProfileResponse | undefined, QueryResult<DataType, OperationVariables>]
);

const GetUserProfile: HookType = (options) => {
    const response = useQuery<DataType>(query, options);
    return [response.data?.userProfile, response];
};

export default GetUserProfile;
