import { ReportPageLink } from "@adas/shared-types";
import { isEmpty } from "lodash";

export const ReportPageLinks = ({ pageLinks }: { pageLinks: ReportPageLink[] }) => {
    if (isEmpty(pageLinks)) {
        return <></>;
    }
    return (
        <>
            <p>
                Repairify Video Tutorials:
            </p>
            <ul>
                {pageLinks.map(({ description, url, pageLinkId }) =>
                    <li key={pageLinkId}>
                        <a
                            href={url}
                            target="_blank"
                            className="page-link"
                        >
                            {description}
                        </a>
                    </li>)}
            </ul>
        </>
    );
}