/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    formatPriceWithCurrency, Guid, routes,
} from '@adas/shared-types';
import React from 'react';
import { isNil } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Link } from '@mui/material';
import { PlansPagePlanView, PlansPageView } from '../ClientServerApi.generated';
import { LinkButton } from './components';

const getBoxClassName = (isSelected: boolean) => `box has-text-centered${isSelected ? ' selected-plan' : ''}`;

type PlanKinds = PlansPagePlanView | 'enterprise' | 'free-trial';

const PlansPageBody = ({
    plansView, plans, isYearly, setIsYearly,
}:
{
    plansView: PlansPageView,
    plans: PlansPagePlanView[],
    isYearly: boolean,
    setIsYearly: (value: boolean) => void }) => {
    const planKinds: PlanKinds[] = [];
    if (plansView.planId == null) {
        planKinds.push('free-trial');
    }
    planKinds.push(...plans.filter((p) => p.isYearly === isYearly));

    // only show the enterprise box if they aren't added to a private plan
    if (plans.every((plan) => plan.isPublic)) {
        planKinds.push('enterprise');
    }

    const boxes = planKinds.map((plan) => {
        if (plan === 'free-trial') {
            return (
                <Grid xs={4} sx={{ minHeight: '500px' }}>
                    <div className={getBoxClassName(plansView.planId == null)} data-testid="free-trial-box">
                        <h3 className="title is-3">Free trial</h3>
                        <p>
                            <span className="plan-price">Free</span>
                        </p>
                        <div className="plan-content">
                            <p>No credit card required</p>
                            <p>30 day free trial</p>
                            <p>Maximum of 10 estimates</p>
                            <p>Links to OEM documentation</p>
                            <p>PDF reports</p>
                            <p>Access past reports</p>
                        </div>
                    </div>
                </Grid>
            );
        } if (plan === 'enterprise') {
            return (
                <Grid xs={4} sx={{ minHeight: '500px' }}>
                    <div className="box has-text-centered" data-testid="enterprise-box">
                        <h3 className="title is-3">Enterprise</h3>
                        <p>
                            <span className="plan-price">Contact Us</span>
                        </p>
                        <div className="plan-content">
                            <p>More than 200 estimates per month</p>
                            <p>Boutique solutions</p>
                            <p>Custom integrations</p>
                        </div>
                        <p>
                            <a href="mailto:customerservice@astech.com" className="button is-primary">Contact Us</a>
                        </p>
                    </div>
                </Grid>
            );
        }
        const isSelected = plansView.planId === plan.planId;
        const newPlanMessage = plansView.planId == null ? 'Start Now' : 'Switch';
        const routeTo = `${routes.pages.account.plan(plan.planId.toLowerCase() as Guid)}?review=true`;
        const formattedPrice = plan.price?.priceInCents != null
            ? formatPriceWithCurrency(plan.price)
            : 'Contact Us';
        const estimatesPerMonthText = plan.estimatesPerMonth != null
            ? `${plan.estimatesPerMonth} estimates per month`
            : 'Billed per use';

        const planClassName = plan.isYearly ? 'plan-yearly' : 'plan-monthly';
        const planClass = isNil(plan.estimatesPerMonth) ? '' : planClassName;
        return (
            <Grid xs={4} key={plansView.planId ?? plan.name} sx={{ minHeight: '500px' }}>
                <div
                    className={getBoxClassName(isSelected)}
                    data-test-group="plan-box"
                    data-test-selector={plan.name}
                >
                    <h3 className="title is-3">{plan.name}</h3>
                    <p>
                        <span className={`plan-price ${planClass}`} data-testid="plan-price">
                            {formattedPrice}
                        </span>
                    </p>
                    <div className="plan-content">
                        <p>{estimatesPerMonthText}</p>
                        <p>Links to OEM documentation</p>
                        <p>PDF reports</p>
                        <p>Access past reports</p>
                        <p>Anytime, anywhere access</p>
                        <p>Unlimited users per plan</p>
                    </div>
                    <LinkButton className="is-primary" data-testid="select-plan-button" to={routeTo}>
                        {isSelected ? 'View Your Plan' : newPlanMessage}
                    </LinkButton>
                </div>
            </Grid>
        );
    });

    return (
        <>
            {!isYearly && (
                <div className="notification">
                    Save a month per year by using one of our
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => setIsYearly(!isYearly)}
                    >
                        yearly plans
                    </Link>
                    .
                </div>
            )}
            <Grid container spacing={2} columnSpacing={2}>
                {boxes}
            </Grid>
        </>
    );
};

export default PlansPageBody;
