import { Button } from '@adas/shared-types';
import React, { useEffect, useState } from 'react';
import { Api, PlansPageView } from '../ClientServerApi.generated';
import { AreaManagedByAccountOwnerMessage } from './billing';
import { LoadingBar } from './components';
import PlansPageBody from './PlansPageBody';

export const PlansPage = () => {
    const [plansView, setPlansView] = useState<PlansPageView | undefined>(undefined);
    const [isYearly, setIsYearly] = useState<boolean>(false);

    useEffect(() => {
        Api.getPlansView().then((view) => {
            setPlansView(view);
            if (view.plans !== 'notOwner') {
                const selectedPlan = view.plans.find((p) => p.planId === view.planId);
                setIsYearly(selectedPlan?.isYearly ?? false);
            }
        }).catch(() => {
            console.error('Failed to retrieve plans.');
        });
    }, []);

    if (plansView == null) {
        return <LoadingBar />;
    } if (plansView.plans === 'notOwner') {
        return <AreaManagedByAccountOwnerMessage />;
    }

    return (
        <div className="content" id="plans">
            <header className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">Plans</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="buttons">
                        <div className="level-item">
                            <Button
                                data-testid="switch-monthly-yearly-button"
                                kind="info"
                                onClick={() => setIsYearly(!isYearly)}
                            >
                                {`Show ${isYearly ? 'Monthly' : 'Yearly'} Plans`}
                            </Button>
                        </div>
                    </div>
                </div>
            </header>
            <PlansPageBody plansView={plansView} plans={plansView.plans} isYearly={isYearly} setIsYearly={setIsYearly} />
        </div>
    );
};

export default PlansPage;
