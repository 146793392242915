import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Notification } from '.';
import { constants, getAppToken, routes } from '..';

const baseURL = process.env.REACT_APP_API_URL ?? `/`;
import { Modal } from "./Modal";

axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if(!error.response) { return }
    
    const { status } = error?.response || {};

    
    if (status === constants.ClientOutOfDateErrorCode) {
        if (error.response.data === 'Client out of date') {
            showClientOutOfDate();
            throw new Error("Client out of date.");
        }
    }
    if(status === 401) {
        console.error('401: Unauthorized.')
        return window.location.href = routes.pages.logout;
    }
    if (status !== 200) {
        throw new Error(`${status} - ${error.response.data}`);
    }
    Notification.showError("There was an error making the request.");
    return Promise.reject(error.message);

});

axios.interceptors.request.use((config) => {
    const appToken = getAppToken();
    if(!appToken) {
        return config;
    }
    const headers = config.headers || {};
    headers.Authorization =  `Bearer ${appToken}`;

    return { ...config, headers };
});

export const axiosGet = <R>(url: string, config?: AxiosRequestConfig | undefined) => 
    axios.get<R>(url, {
        ...config,
        baseURL,
        params: { ...config?.params, v: constants.WebServerVersion },
    }).then(({ data }) => data);

interface MessageWrapper<TValue> {
    version: string,
    value: TValue,
}

export const axiosPost = <D, R>(url: string, data: D, config?: AxiosRequestConfig<MessageWrapper<D>> | undefined) => 
    axios.post<MessageWrapper<D>, AxiosResponse<R>> (url, { version: "17", value: data }, { 
        ...config, 
        baseURL,
    })
    .then(({ data }) => data);

export const axiosPostOldApi = <D, R>(url: string, data: D, config?: AxiosRequestConfig<D> | undefined) => 
    axios.post<D, AxiosResponse<R>> (url, data, { 
        ...config, 
        baseURL,
    })
    .then(({ data }) => data);


const showClientOutOfDate = () => {
    showRefreshMessage("We've been busy updating our servers! Click OK to refresh your page.");
}

const showUnauthorized = () => {
    showRefreshMessage("Your user session has expired.", routes.pages.logout);
}

let isShowing = false;

const showRefreshMessage = (message: string, url?: string) => {
    if (isShowing) {
        return;
    }

    isShowing = true;

    Modal.alert(message)
        .then(() => {
            if(url) {
                window.location.href = url;
            } else {
                window.location.reload();
            }
        });
}