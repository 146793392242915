import React, { ChangeEvent } from "react";
import PhoneInput from "react-phone-input-2";
import { isNil } from 'lodash';
import {
    Grid,
} from '@mui/material';

export default ({
    value,
    onChange,
}: {
    value: string;
    onChange: (value: string) => void;
}) => (
    <PhoneInput
        preferredCountries={["us", "ca"]}
        country="us"
        placeholder=" "
        value={value}
        onChange={onChange}
        containerStyle={{ height: "55px" }}
        inputStyle={{ height: "55px" }}
        specialLabel="Phone Number"
    />
)