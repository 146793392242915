import React, { useState } from 'react';
import {
    FormControlLabel, FormGroup, RadioGroup, FormControl, Radio, Grid, Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import { CarPartQuestion, OperationOption } from '../../../hooks/Questionnaire/types';
import QuestionnaireStep from './QuestionnaireStep';
import { CarPartDamageSelection } from './state/types';

const startState = (carPartQuestions: CarPartQuestion[], selectedCarPartsDamage: CarPartDamageSelection) => Object.fromEntries(
    carPartQuestions.map((question) => {
        const selectedCarPartDamageOperation = selectedCarPartsDamage[question.id]?.operation ?? 'None';
        return [question.id, { name: question.name, operation: selectedCarPartDamageOperation }];
    }),
);
export default ({
    carPartQuestions, setSelectedCarPartsDamage, selectedCarPartsDamage, navigateBack,
}: {
    carPartQuestions: CarPartQuestion[],
    setSelectedCarPartsDamage: (selectedSensors: CarPartDamageSelection) => void,
    selectedCarPartsDamage: CarPartDamageSelection,
    navigateBack: () => void,
}) => {
    const [carPartDamageValues, setCarPartDamageValues] = (
        useState<CarPartDamageSelection>(startState(carPartQuestions, selectedCarPartsDamage)));
    return (
        <QuestionnaireStep
            headerText="Please identify any labor operations related to the parts below:"
            onSubmit={() => setSelectedCarPartsDamage(carPartDamageValues)}
            areAnswersValid
            navigateBack={navigateBack}
        >
            <FormGroup>
                {sortBy(carPartQuestions, 'name').map(({ id, name, answers }) => (
                    <Grid
                        container
                        sx={{
                            border: 0,
                            borderTop: '1px',
                            borderColor: '#CCC',
                            borderStyle: 'solid',
                        }}
                    >
                        <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                            <Typography sx={{ color: '#4a4a4a' }}>{name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl key={id}>
                                <RadioGroup
                                    row
                                    value={carPartDamageValues[id].operation}
                                    onChange={
                                        (e) => setCarPartDamageValues({
                                            ...carPartDamageValues,
                                            [id]: { name, operation: e.target.value as OperationOption },
                                        })
                                    }
                                >
                                    {answers.map((operationOption) => (
                                        <FormControlLabel
                                            sx={{ color: '#4a4a4a' }}
                                            key={operationOption}
                                            value={operationOption}
                                            control={<Radio size="small" />}
                                            label={operationOption}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                ))}
            </FormGroup>
        </QuestionnaireStep>
    );
};
