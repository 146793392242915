import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import QuestionnaireStep from './QuestionnaireStep';

export default ({
    selectedVin, setSelectedVin, navigateBack, error,
}: {
    selectedVin: string | undefined,
    setSelectedVin: (newVin: string) => void,
    navigateBack: () => void,
    error: boolean,
}) => {
    const [vinTextValue, setVinTextValue] = useState<string | undefined>(selectedVin);
    return (
        <QuestionnaireStep
            headerText="Please enter a VIN:"
            areAnswersValid={!isNil(vinTextValue)}
            onSubmit={isNil(vinTextValue) ? () => null : () => setSelectedVin(vinTextValue)}
            navigateBack={navigateBack}
        >
            <TextField
                fullWidth
                label="VIN"
                value={vinTextValue}
                onChange={(e) => setVinTextValue(e.target.value.trim())}
                error={error}
                helperText={error && 'VIN failed to validate'}
            />
        </QuestionnaireStep>
    );
};
