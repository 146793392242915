import React from "react";
import { getHtmlProps } from "./getHtmlProps";

export interface DropDownProps {
    size?: "regular" | "small";
    allowEmpty?: boolean;
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    options: DropDownOption[];
    disabled?: boolean;
}

export interface DropDownOption {
    text: string;
    value: string;
}

const emptyValue = "EMPTY_VALUE";

export function DropDown(props: DropDownProps) {
    return (
        <div className={getDropDownClassName()}>
            <select
                onChange={e => {
                    if (e.target.value === emptyValue) {
                        props.onChange(undefined);
                    } else {
                        props.onChange(e.target.value);
                    }
                }}
                disabled={props.disabled}
                {...getHtmlProps(props)}
            >
                {props.allowEmpty && <option value={"EMPTY_VALUE"} selected={props.value == null}></option>}
                {props.options.map(o => {
                    return (
                        <option
                            key={o.value}
                            value={o.value}
                            selected={o.value === props.value}
                        >
                            {o.text}
                        </option>
                    );
                })}
            </select>
        </div>
    );

    function getDropDownClassName() {
        let className = "select";

        switch (props.size) {
            case "regular":
            case undefined:
                break;
            case "small":
                className += " is-small";
                break;
            default:
                const _assertNever: never = props.size;
                break;
        }

        return className;
    }
}
