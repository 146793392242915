import React, { ChangeEventHandler } from "react";
import { isNil } from 'lodash';
import {
    TextField,
    InputAdornment,
    Grid,
} from '@mui/material';

export default ({
    defaultValue,
    onChange,
    error,
    disabled,
}: {
    defaultValue: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    error: string | undefined;
    disabled: boolean;
}) => (
    <TextField
        id="email-input"
        data-testid="email-text-box"
        label="Email"
        error={error?.length ? true : false}
        helperText={error}
        defaultValue={defaultValue}
        disabled={disabled}
        variant="outlined"
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <i className="fas fa-envelope"></i>
                </InputAdornment>
            ),
        }}
        fullWidth
        onChange={onChange}
    />
)