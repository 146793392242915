import React from 'react';
import { EstimateHoverLineItem } from '@adas/shared-types';

const HoverLineItem = (
    { lineItem }:
    { lineItem: EstimateHoverLineItem },
) => (
    <>
        <div className="hover-image-cell">{lineItem.lineNumber ?? ''}</div>
        <div className="hover-image-cell">{lineItem.operation ?? ''}</div>
        <div className="hover-image-cell">{lineItem.description}</div>
    </>
);

export default HoverLineItem;
