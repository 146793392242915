import { SensorOverride } from "./report";
import { Guid, Instant } from "./utils";
import { VIN } from "./vin";

export interface EstimateUploadOptions {
    vin: VIN | undefined;
    vehicleName: VehicleName | undefined;
}

export interface ReportUploadResponse {
    response: ReportResponses;
    // used to show the view report page quickly on success
    user: ReportUploadUser;
}

export interface ReportUploadUser {
    firstName: string;
    lastName: string;
    email: string;
    shopName: string;
}

export type ReportResponses =
    | ReportSuccessResponse
    | ReportErrorResponses;

export type ReportErrorResponses =
    | VinSelectionResponse
    | OldVehicleResponse
    | VehicleNotFoundResponse
    | VinNotFoundResponse
    | VehicleInfoCurrentlyUnavailableResponse
    | MaxEstimateUploadReachedResponse
    | PdfErrorResponse;

export interface VinSelectionResponse {
    type: "VinSelection";
    vins: VIN[];
}

export interface VehicleName {
    year: string;
    make: string;
    model: string;
}
export interface VehicleNameRecord {
    vehicleNameId: Guid;
    year: string;
    make: string;
    model: string;
}

export interface OldVehicleResponse {
    type: "OldVehicle";
    vin: string;
    vehicleName: string;
}

export interface VehicleNotFoundResponse {
    type: "NotFound";
    vin: string;
    vehicleName: string;
}

export interface VinNotFoundResponse {
    type: "VinNotFound";
    vin: string;
    /** List of vehicle names for selection of possible vehicles. */
    possibleVehicles: VehicleName[];
}

export interface VehicleInfoCurrentlyUnavailableResponse {
    type: "VehicleInfoCurrentlyUnavailable";
}

export interface MaxEstimateUploadReachedResponse {
    type: "MaxEstimateUploadReached";
    uploadDisabledReason: UploadDisabledReason;
}

export type UploadDisabledReason = "expiryDateReached" | "freeTrialLimitReached" | "limitReached";

export interface PdfErrorResponse {
    type: "PdfError";
}

export interface ReportSuccessResponse {
    type: "Success";
    reportId: Guid;
    vehicle: ReportVehicle;
    matches: readonly ExtractionMatch[];
    /** Undefined when they can't be figured out. */
    features: readonly VehicleFeature[] | undefined;
    sensorOverrides: readonly SensorOverride[];
    roNumber: string | undefined;
    debugInfo?: SuccessDebugInfo;
}

export interface ReportVehicle {
    vin: string;
    year: string;
    make: string;
    model: string;
    selectedTrim: string | undefined;
    trims: readonly string[];
    trimMatch: TrimMatch | undefined;
}

export type SuccessDebugInfo = PdfDebugInfo | ApiDebugInfo;

export interface PdfDebugInfo {
    type: "Pdf";
    databaseLastModified: Instant;
    rawResponseJson: string;
    pdfJson: string;
}

export interface ApiDebugInfo {
    type: "Api";
    databaseLastModified: Instant;
    rawResponseJson: string;
    /** This may be JSON or XML (CCC) */
    requestBody: string;
}

// This should match AdasFeatureCategory
export enum VehicleFeatureCategory {
    ADAS = "ADAS",
    Safety = "Safety",
    ComfortAndConvenience = "Comfort & Convenience",
    Steering = "Steering",
    Operational = "Operational",
}

export interface VehicleFeature {
    vehicleFeatureId: Guid;
    name: string;
    trimAvailabilities: TrimVehicleFeatureAvailability[];
    category: VehicleFeatureCategory;
}

export interface ExtractionMatch {
    sensor: ExtractionMatchSensor;
    category: VehicleFeatureCategory | undefined;
    features: VehicleFeature[];
    trimAvailabilities: TrimVehicleFeatureAvailability[];
    procedures: ProcedureExtractionMatch[];
    alwaysApplies: AlwaysAppliesMatch[];
    hasToolsForCalibration: boolean;
}

export interface ExtractionMatchSensor {
    sensorId: Guid;
    name: string;
    match: SensorMatch | undefined;
}

export interface AlwaysAppliesMatch {
    allDataUrl: string;
    calibrationUrl: string;
    requiresAdasFeature: boolean;
    calibrationOperations: ReportCalibrationOperation[];
}

export interface ProcedureExtractionMatch {
    lineNumbers: number[];
    carPart: ProcedureExtractionMatchCarPart;
    operation: Operation;
    allDataUrl: string;
    calibrationUrl: string;
    hoverItems: EstimateHoverItem[];
    calibrationOperations: ReportCalibrationOperation[];
}

export interface ProcedureExtractionMatchCarPart {
    carPartId: Guid;
    name: string;
}
export interface CalibrationTool {
    key: string;
    calibrationToolId: Guid;
}
export interface ReportPageLink {
    pageLinkId: Guid;
    description: string;
    url: string;
}
export interface ReportCalibrationOperation {
    calibrationOperationId: Guid;
    procedureName: string;
    preRequirements: ReportPreRequirement[];
    calibrationType: ReportCalibrationType;
    specialTools: boolean;
    scanToolRequired: boolean;
    calibrationToolIds: Guid[];
    pageLinks: ReportPageLink[] | undefined;
    calibrationUrl: string;
    userHasTools: boolean;
}

export interface ReportPreRequirement {
    preRequirementId: Guid;
    name: string;
}

export interface ReportCalibrationType {
    calibrationTypeId: Guid;
    name: string;
}

export type EstimateHoverItem = EstimateHoverImageItem | EstimateHoverLineItem;

export interface EstimateHoverLineItem {
    kind: "lineItem";
    lineNumber: number | undefined;
    operation: string | undefined;
    description: string;
}

export interface EstimateHoverImageItem {
    kind: "image";
    width: number;
    height: number;
    textItems: EstimateHoverImageItemText[];
}

export interface EstimateHoverImageItemText {
    x: number;
    y: number;
    width: number;
    height: number;
    isHighlighted: boolean;
    text: string;
}

export enum Operation {
    Install = "Install",
    Replace = "Replace",
    Repair = "Repair",
}

export interface TrimVehicleFeatureAvailability {
    trim: string | undefined;
    availability: VehicleFeatureAvailability;
}

export enum VehicleFeatureAvailability {
    /** Definitely on the vehicle. */
    Equipped,
    /** It may be on the vehicle. */
    Optional,
}



export declare type UserTools = Guid[];
export declare type DeprecatedUserTools = Guid[];
export interface TrimMatch {
    trim: string;
    hoverItem: EstimateHoverImageItem | undefined;
}

export type SensorMatch =
    | CarPartSensorMatch
    | SensorNameSensorMatch
    | CarPartAndSensorNameSensorMatch;

export interface CarPartSensorMatch {
    kind: "CarPart";
    lineNumber: number | undefined;
    hoverItem?: EstimateHoverItem;
}

export interface SensorNameSensorMatch {
    kind: "SensorName";
    isEquipped: boolean;
    lineNumber: number | undefined;
    hoverItem?: EstimateHoverItem;
}

export interface CarPartAndSensorNameSensorMatch {
    kind: "CarPartAndSensorName";
    isEquipped: boolean;
    lineNumber: number | undefined;
    hoverItem?: EstimateHoverItem;
}
