import React, { useEffect, useState } from "react";

export function LoadingBar() {
    let [passedTimeout, setPassedTimeout] = useState(false);
    // Wait a little bit before showing the loading in order to prevent
    // this loading bar from flashing on the screen
    useEffect(() => {
        const timeout = setTimeout(() => {
            setPassedTimeout(true);
        }, 800);
        return () => clearTimeout(timeout);
    });

    if (!passedTimeout) {
        return <></>;
    }

    return (
        <>
            <progress className="progress is-large is-info" data-testid="report-progress-bar" max="100"></progress>
        </>
    );
}
