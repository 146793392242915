import {
    Button,
    getErrorMessage,
    Guid,
    isValidEmail,
    Notification,
    parseQueryString,
    routes,
    TextBox,
    EditAddress,
} from '@adas/shared-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AddEditCreditCardView,
    AddEditCustomerMessage,
    AddEditCustomerView,
    Api,
} from '../ClientServerApi.generated';
import { AddEditCreditCard, AreaManagedByAccountOwnerMessage, CreditCardUpdater } from './billing';
import { LinkButton, LoadingBar } from './components';
import IsFeatureFlagEnabled from '../hooks/IsFeatureFlagEnabled';

const CreditCardContainer = ({
    creditCardView,
    clearErrorMessage,
    setCreditCardUpdater,
    showEditCreditCard,
    setShowEditCreditCard,
}: {
    creditCardView: AddEditCreditCardView;
    clearErrorMessage: () => void;
    setCreditCardUpdater: (updater: CreditCardUpdater) => void;
    showEditCreditCard: boolean;
    setShowEditCreditCard: (value: boolean) => void;
}) => {
    if (creditCardView.card != null) {
        return (
            <article className="message">
                <div className="message-body">
                    <p>
                        Credit card on file:
                        {' '}
                        <span data-testid="credit-card-on-file">
                            xxxx-xxxx-xxxx-
                            {creditCardView.card.last4}
                        </span>
                    </p>
                    {showEditCreditCard && (
                        <AddEditCreditCard
                            creditCardView={creditCardView}
                            clearErrorMessage={clearErrorMessage}
                            setCreditCardUpdater={setCreditCardUpdater}
                        />
                    )}
                    <p>
                        <Button
                            data-testid="show-edit-credit-card-button"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowEditCreditCard(!showEditCreditCard);
                            }}
                        >
                            {showEditCreditCard ? 'Cancel Updating' : 'Update Credit Card'}
                        </Button>
                    </p>
                </div>
            </article>
        );
    }

    return (
        <div className="field">
            <label className="label">
                Credit Card
                <div className="control has-icons-left">
                    <AddEditCreditCard
                        creditCardView={creditCardView}
                        clearErrorMessage={clearErrorMessage}
                        setCreditCardUpdater={setCreditCardUpdater}
                    />
                </div>
            </label>
        </div>
    );
};

export const EditBillingPage = () => {
    const history = useHistory();
    const returnPlanId = parseQueryString().returnPlanId as Guid | undefined;
    const [isLoading, setIsLoading] = useState(true);
    const [customerView, setCustomerView] = useState<AddEditCustomerView | undefined>(undefined);
    const [customerMessage, setCustomerMessage] = useState<AddEditCustomerMessage>({
        fullName: '',
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: 'AL',
            country: 'US',
            zip: '',
        },
        phone: '',
        email: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [submittedButton, setSubmittedButton] = useState(false);
    const [creditCardUpdater, setCreditCardUpdater] = useState<CreditCardUpdater | undefined>(undefined);
    const [showEditCreditCard, setShowEditCreditCard] = useState<boolean>(false);
    const [planPageDisabled, { loading: featureFlagLoading }] = (
        IsFeatureFlagEnabled({ variables: { featureKey: 'PLAN_PAGE_DISABLED' } })
    );

    const getReturnUrl = () => {
        if (returnPlanId != null && !planPageDisabled) {
            return `${routes.pages.account.plan(returnPlanId)}?review=true`;
        }
        return routes.pages.account.index;
    };

    const updateMessage = (prop: Partial<AddEditCustomerMessage>) => {
        setCustomerMessage({ ...customerMessage, ...prop });
        setErrorMessage('');
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        customerMessage.fullName = customerMessage.fullName.trim();
        customerMessage.address.addressLine1 = customerMessage.address.addressLine1.trim();
        customerMessage.address.addressLine2 = customerMessage.address.addressLine2.trim();
        customerMessage.address.city = customerMessage.address.city.trim();
        customerMessage.address.zip = customerMessage.address.zip.trim();
        customerMessage.phone = customerMessage.phone.trim();
        customerMessage.email = customerMessage.email.trim();

        if (customerMessage.fullName.length === 0) {
            setErrorMessage('Please provide the name on the credit card.');
        } else if (customerMessage.address.addressLine1.length === 0) {
            setErrorMessage('Please provide a street address.');
        } else if (customerMessage.address.city.length === 0) {
            setErrorMessage('Please provide a city.');
        } else if (customerMessage.address.zip.length === 0) {
            setErrorMessage('Please provide a zip or postal code.');
        } else if (customerMessage.phone.length === 0) {
            setErrorMessage('Please provide a phone number.');
        } else if (!isValidEmail(customerMessage.email)) {
            setErrorMessage('Please provide a valid email address.');
        } else {
            setSubmittedButton(true);
            try {
                const result = await Api.addEditCustomer(customerMessage);
                if (result.stripeError) {
                    throw new Error(result.stripeError);
                }
                if (creditCardUpdater != null && showEditCreditCard) {
                    await creditCardUpdater.update();
                    creditCardUpdater.clear();
                }
                Notification.show('Updated billing information.');

                if (returnPlanId != null) {
                    history.push(getReturnUrl());
                }
            } catch (err:any) {
                setErrorMessage(getErrorMessage(err) ?? 'Unknown error.');
                Notification.showError('Error updating billing information.');
                return;
            } finally {
                setSubmittedButton(false);
            }

            // now refresh the view to get the right credit card displaying
            const customer = await Api.getCustomer();
            setCustomerView(customer);
        }
    };

    useEffect(() => {
        Api.getCustomer()
            .then((newCustomerView) => setCustomerView(newCustomerView))
            .catch(() => setErrorMessage('An unknown error occured'));
    }, []);

    useEffect(() => {
        if (customerView == null) {
            return;
        }

        if (customerView.details === 'notOwner') {
            setIsLoading(false);
        } else {
            const { customer } = customerView.details;
            setShowEditCreditCard(customerView.details.cardView.card == null);

            if (customer == null) {
                Api.getUserProfile().then((userProfile) => {
                    // prefill some information from the user profile to help them out
                    setCustomerMessage({
                        ...customerMessage,
                        fullName: userProfile.shopName,
                        address: {
                            ...customerMessage.address,
                            city: userProfile.shopAddress.city,
                            state: userProfile.shopAddress.state,
                            country: userProfile.shopAddress.country,
                        },
                    });
                }).finally(() => {
                    setIsLoading(false);
                });
            } else {
                setCustomerMessage(customer);
                setIsLoading(false);
            }
        }
    }, [customerView]);

    if (isLoading || featureFlagLoading || customerView == null) {
        return <LoadingBar />;
    }
    if (customerView.details === 'notOwner') {
        return <AreaManagedByAccountOwnerMessage />;
    }
    const { cardView } = customerView.details;
    return (
        <div className="content" data-test-group="edit-billing-page">
            <header>
                <h1 className="title">Billing</h1>
            </header>

            {cardView.card == null
                ? (
                    <div data-testid="saving-wont-charge-cc-message" className="notification">
                        Note: Saving your billing information will not charge your credit card.
                    </div>
                )
                : <></>}

            <form method="post" onSubmit={onSubmit}>
                <div className="field">
                    <label className="label">Name on Credit Card</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="full-name-text-box"
                            type="text"
                            value={customerMessage.fullName}
                            onChange={(value) => updateMessage({ fullName: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="email-text-box"
                            type="email"
                            name="email"
                            value={customerMessage.email}
                            onChange={(value) => updateMessage({ email: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope" />
                        </span>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Phone Number</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="phone-number-text-box"
                            value={customerMessage.phone}
                            onChange={(value) => updateMessage({ phone: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-phone" />
                        </span>
                    </div>
                </div>
                <EditAddress
                    address={customerMessage.address}
                    onChange={(address) => updateMessage({ address })}
                />
                <CreditCardContainer
                    creditCardView={cardView}
                    clearErrorMessage={() => setErrorMessage('')}
                    setCreditCardUpdater={setCreditCardUpdater}
                    showEditCreditCard={showEditCreditCard}
                    setShowEditCreditCard={setShowEditCreditCard}
                />
                {submittedButton && <progress className="progress is-link" max="100" />}
                {errorMessage.length > 0
                    ? (
                        <div className="notification is-warning" data-testid="error-message">
                            {errorMessage}
                        </div>
                    )
                    : <></>}
                <div className="field is-grouped">
                    <div className="control">
                        <Button
                            data-testid="save-button"
                            kind="link"
                            type="submit"
                            disabled={submittedButton}
                        >
                            {returnPlanId != null ? 'Save & Review Order' : 'Save'}
                        </Button>
                    </div>
                    <div className="control">
                        <LinkButton to={getReturnUrl()}>Cancel</LinkButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditBillingPage;
