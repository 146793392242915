import React, { useEffect, useReducer } from 'react';

import {
    Stepper, Step, StepLabel, CircularProgress, Box,
} from '@mui/material';
import TrimSelectForm from './TrimSelectForm';
import SensorSelectForm from './SensorSelectForm';
import VinSelectForm from './VinSelectForm';
import ConfirmAnswers from './ConfirmAnswers';
import CarPartsSelectForm from './CarPartsSelectForm';
import {
    initialState, questionnaireReducer,
} from './state/questionnaireReducer';
import callQuestionnaireExchange from './state/thunks/callQuestionnaireExchange';
import { Steps } from './state/types';
import { indexToStep } from './state/helpers';
import {
    navigateBack, selectTrim, selectVin, selectSensors, selectCarParts,
} from './state/actionCreators';

export default () => {
    const [state, dispatch] = useReducer(questionnaireReducer, initialState);
    const {
        currentStep, draftAnswers, error, loading, triggerExchange, trimQuestion, sensorQuestions, carPartQuestions,
    } = state;
    const {
        vin, trim, sensors, carParts,
    } = draftAnswers;

    useEffect(() => {
        if (!loading && triggerExchange) {
            callQuestionnaireExchange(dispatch, state);
        }
    }, [loading, state, triggerExchange]);

    const activeComponent = () => {
        switch (indexToStep(currentStep)) {
            case Steps.VIN:
                return (
                    <VinSelectForm
                        setSelectedVin={(value) => dispatch(selectVin(value))}
                        selectedVin={vin}
                        navigateBack={() => dispatch(navigateBack())}
                        error={error}
                    />
                );
            case Steps.TRIM:
                return (
                    <TrimSelectForm
                        trims={trimQuestion?.answers ?? []}
                        setSelectedTrim={(value) => dispatch(selectTrim(value))}
                        selectedTrim={trim}
                        navigateBack={() => dispatch(navigateBack())}
                    />
                );
            case Steps.SENSOR:
                return (
                    <SensorSelectForm
                        sensorQuestions={sensorQuestions ?? []}
                        setSelectedSensors={(value) => dispatch(selectSensors(value))}
                        selectedSensors={sensors}
                        navigateBack={() => dispatch(navigateBack())}
                    />
                );
            case Steps.CAR_PART:
                return (
                    <CarPartsSelectForm
                        carPartQuestions={carPartQuestions ?? []}
                        setSelectedCarPartsDamage={(value) => dispatch(selectCarParts(value))}
                        selectedCarPartsDamage={carParts}
                        navigateBack={() => dispatch(navigateBack())}
                    />
                );
            case Steps.CONFIRM:
                return (
                    <ConfirmAnswers
                        selectedAnswers={draftAnswers}
                        navigateBack={navigateBack}
                    />
                );
            default:
                return <div>Error</div>;
        }
    };
    return (
        <div>
            <Stepper activeStep={currentStep} sx={{ py: 3 }} alternativeLabel>
                {Object.values(Steps).map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {loading ? <Box sx={{ textAlign: 'center' }}><CircularProgress size={60} /></Box> : activeComponent()}
        </div>
    );
};
