import React, { useState } from "react";
import { Modal } from "../utils";
import { Button } from "./Button";

export function ConditionalEditFieldSet(props: {
    children: React.ReactNode;
    onSave: () => Promise<boolean>;
    onCancel: () => void;
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    if (isEditing) {
        return renderIsEditing();
    } else {
        return renderView();
    }

    function renderView() {
        return (
            <>
                <i
                    className="fas fa-edit is-clickable"
                    onClick={() => setIsEditing(true)}
                    data-testid="conditional-edit-field-set-button"
                />
            </>
        );
    }

    function renderIsEditing() {
        return (
            <fieldset data-test-group="conditional-edit-field-set">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    {props.children}
                    <div className="field is-grouped">
                        <Button
                            disabled={isUpdating}
                            data-testid="edit-box-save-button"
                            kind="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={onCancel}
                            disabled={isUpdating}
                            data-testid="edit-box-cancel-button"
                            type="button"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </fieldset>
        );
    }

    async function onSubmit() {
        setIsUpdating(true);
        try {
            const wasSuccess = await Modal.withLoading(() => props.onSave());
            if (wasSuccess) {
                setIsEditing(false);
            }
        } finally {
            setIsUpdating(false);
        }
    }

    function onCancel(e: React.MouseEvent) {
        e.preventDefault();
        setIsEditing(false);
        props.onCancel();
    }
}
