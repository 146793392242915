import { constants, CountryCodes, StateCodes } from "./constants";
import { Guid, Instant, isGuid } from "./utils";

export function assertNever(value: never): never {
    throw new Error(`Unexpected value: ${value}`);
}

export function assertObject(value: object, name: string): asserts value is object {
    if (typeof value !== "object") {
        throw new Error(`${name} was not an object.`);
    }
}

export function deleteProtoPropertyIfExists(value: object) {
    // Deletes the __proto__ property on an object in order
    // to prevent a prototype pollution vulnerability.
    // https://medium.com/node-modules/what-is-prototype-pollution-and-why-is-it-such-a-big-deal-2dd8d89a93c
    delete (value as any).__proto__;
}

export function assertString(value: string, name: string): asserts value is string {
    if (typeof value !== "string") {
        throw new Error(`${name} was not a string.`);
    }
}

export function assertGuid(value: Guid, name: string): asserts value is Guid {
    if (typeof value !== "string" || !isGuid(value)) {
        throw new Error(`${name} was not a guid.`);
    }
}

export function validateGuid(value: string, name: string): Guid {
    assertGuid(value as Guid, name);
    return value.toUpperCase() as Guid;
}

export function assertInstant(value: Instant, name: string): asserts value is Instant {
    if (typeof value !== "number") {
        throw new Error(`${name} was not an instant.`);
    }
}

export function assertNumber(value: number, name: string): asserts value is number {
    if (typeof value !== "number") {
        throw new Error(`${name} was not a number.`);
    }
}

export function assertBoolean(value: boolean, name: string): asserts value is boolean {
    if (typeof value !== "boolean") {
        throw new Error(`${name} was not a boolean.`);
    }
}

export function assertArray<T>(value: readonly T[], name: string): asserts value is T[] {
    if (!(value instanceof Array)) {
        throw new Error(`${name} was not an array.`);
    }
}

export function assertCountryCode(country: CountryCodes, name: string) {
    const countryCodes = Object.keys(constants.Countries);
    if (!countryCodes.includes(country)) {
        throw new Error(`Invalid country code ${country} for ${name}`);
    }
}

export function assertStateCode(state: StateCodes, country: CountryCodes, name: string) {
    const stateCodes = Object.keys(constants.States[country]);
    if (!stateCodes.includes(state)) {
        throw new Error(`Invalid state code for country '${country}' ${state} for ${name}`);
    }
}
