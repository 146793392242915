import React from 'react';
import {
    ProcedureExtractionMatch,
    Operation,
    assertNever,
    ReportMatch,
} from '@adas/shared-types';
import { sortBy } from 'lodash';
import { HoverItemsTooltip } from './HoverTooltips';
import SentenceJoin from './SentenceJoin';

const getOperationText = (operation: Operation) => {
    switch (operation) {
        case Operation.Install:
            return 'installed';
        case Operation.Replace:
            return 'replaced';
        case Operation.Repair:
            return 'repaired';
        default:
            return assertNever(operation);
    }
};

const ProcedureReason = (
    {
        procedure,
        toolTipName,
        procedureArrayIndex,
    }:
    {
        procedure: ProcedureExtractionMatch,
        toolTipName: string,
        procedureArrayIndex: number,
    },
) => (
    <li>
        <HoverItemsTooltip id={toolTipName} hoverItems={procedure.hoverItems} />
        <span
            data-test-group="match-procedure"
            data-test-selector={`${procedureArrayIndex}`}
            data-tip={procedure.hoverItems.length > 0}
            data-for={toolTipName}
        >
            <span data-testid="match-procedure-car-part">
                {procedure.carPart.name}
            </span>
            {' '}
            is
            {' '}
            <span
                data-testid="match-procedure-operation"
                data-value={procedure.operation}
            >
                {getOperationText(procedure.operation)}
            </span>
            {procedure.lineNumbers.length > 0 && (
                <>
                    {' '}
                    (Line
                    {' '}
                    <SentenceJoin
                        items={procedure.lineNumbers.map((n) => (
                            <span data-testid="match-procedure-line-number">
                                {n}
                            </span>
                        ))}
                        andOrText="and"
                    />
                    )
                </>
            )}
        </span>
    </li>
);

const ProceduresParagraph = (
    {
        match,
        matchIndex,
        vehicleName,
    }:
    {
        match: ReportMatch,
        matchIndex: number,
        vehicleName: string
    },
) => {
    const sortProcedureMatches = sortBy(match.procedures, ['lineNumbers[0]', 'carPart.name', 'operation']);
    return (
        <p>
            <p>
                {`The ${vehicleName} repair manual states that a calibration or operation is required 
                for the ${match.sensor.name} when the following occurs:`}
            </p>
            <ul>
                {
                    match.alwaysApplies.length > 0
                    && <li>Vehicle is involved in a collision</li>
                }
                {sortProcedureMatches.map((procedureMatch: ProcedureExtractionMatch, i) => (
                    <ProcedureReason
                        procedure={procedureMatch}
                        toolTipName={`procedure-${matchIndex}-${i}`}
                        procedureArrayIndex={i}
                    />
                ))}
            </ul>
        </p>
    );
};

export default ProceduresParagraph;
