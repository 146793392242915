export function ifNotMakingSelection(action: () => void) {
    return () => {
        const selection = window.getSelection();
        if (selection == null || selection.toString().length === 0) {
            action();
        }
    };
}

export function ifNotDoubleClick(action: () => void) {
    let timeoutHandle: NodeJS.Timeout | undefined;
    return () => {
        if (timeoutHandle != null) {
            clearTimeout(timeoutHandle);
            timeoutHandle = undefined;
            return;
        }

        timeoutHandle = setTimeout(() => {
            timeoutHandle = undefined;
            action();
        }, 200);
    };
}

// globally if any selection exists
let previousClickHadSelection = false;
window.addEventListener('click', () => {
    const selection = window.getSelection();
    previousClickHadSelection = selection != null && selection.toString().length > 0;
});

export function ifPreviouslyHadNoSelection(action: () => void) {
    return () => {
        // works because this will fire before the global event
        if (!previousClickHadSelection) {
            action();
        }
    };
}

export function tableClickHandler(action: () => void) {
    return ifPreviouslyHadNoSelection(ifNotDoubleClick(ifNotMakingSelection(action)));
}
