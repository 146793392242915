import {
    Button, Notification, routes, TextBox, EditAddress,
} from '@adas/shared-types';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isEmpty } from 'lodash';
import { UserProfileMessage } from '../ClientServerApi.generated';
import GetUserProfile from '../hooks/GetUserProfile';
import updateUserProfile from '../hooks/UpdateUserProfile';
import { LinkButton, LoadingBar } from './components';

const UserProfile = () => {
    const [userProfileDraft, setUserProfileDraft] = useState<UserProfileMessage | undefined>();
    const [errorMessage, setErrorMessage] = useState('');

    const updateUserProfileMessage = (userProfile: UserProfileMessage, prop: Partial<UserProfileMessage>) => {
        setUserProfileDraft({ ...userProfile, ...prop });
        setErrorMessage('');
    };

    const [mutateUserProfile] = updateUserProfile({
        onCompleted: (result) => {
            if (result.updateUserProfile.error) {
                setErrorMessage(result.updateUserProfile.error);
            } else {
                updateUserProfileMessage(userProfileDraft as UserProfileMessage, {});
                Notification.show('Saved user profile.');
            }
            setUserProfileDraft({ ...(userProfileDraft as UserProfileMessage) });
        },
    });

    const [userProfile, { loading }] = GetUserProfile({
        onCompleted: ({ userProfile: profile }) => {
            setUserProfileDraft({ ...profile });
        },
    });

    if (loading || !userProfileDraft || !userProfile) {
        return <LoadingBar />;
    }

    return (
        <div className="content" data-test-group="user-profile-page">
            <header>
                <h1 className="title">User Profile</h1>
            </header>

            <form method="post" onSubmit={(event) => onSubmit(userProfileDraft, event)}>
                <div className="field">
                    <label className="label">First Name</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="first-name-text-box"
                            value={userProfileDraft.firstName}
                            onChange={(value) => updateUserProfileMessage(userProfileDraft, { firstName: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-user" />
                        </span>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="last-name-text-box"
                            value={userProfileDraft.lastName}
                            onChange={(value) => updateUserProfileMessage(userProfileDraft, { lastName: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-user" />
                        </span>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                        <TextBox
                            className="input"
                            data-testid="email-text-box"
                            type="email"
                            name="email"
                            value={userProfile.email}
                            disabled
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope" />
                        </span>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Phone Number</label>
                    <div className="control has-icons-left">
                        <PhoneInput
                            data-testid="phone-phone-input"
                            preferredCountries={['us', 'ca']}
                            country="us"
                            placeholder=" "
                            containerStyle={{ height: '40px' }}
                            inputStyle={{ height: '40px' }}
                            value={userProfileDraft.phone}
                            onChange={(value) => updateUserProfileMessage(userProfileDraft, { phone: value })}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Shop Name</label>
                    <div className="control has-icons-left">
                        <TextBox
                            data-testid="shop-name-text-box"
                            value={userProfileDraft.shopName}
                            onChange={(value) => updateUserProfileMessage(userProfileDraft, { shopName: value })}
                        />
                        <span className="icon is-small is-left">
                            <i className="fas fa-wrench" />
                        </span>
                    </div>
                </div>
                <EditAddress
                    address={userProfileDraft.shopAddress}
                    onChange={(shopAddress) => updateUserProfileMessage(userProfileDraft, { shopAddress })}
                    isShopAddress
                />
                {errorMessage.length > 0
                    ? (
                        <div className="notification is-warning" data-testid="error-message">
                            {errorMessage}
                        </div>
                    )
                    : <></>}
                <div className="field is-grouped">
                    <div className="control">
                        <Button
                            data-testid="save-button"
                            type="submit"
                            kind="link"
                        >
                            Save
                        </Button>
                    </div>
                    <div className="control">
                        <LinkButton to={routes.pages.account.index}>Cancel</LinkButton>
                    </div>
                </div>
            </form>
        </div>
    );

    function onSubmit(userProfile: UserProfileMessage, event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const {
            firstName,
            lastName,
            shopName,
            shopAddress: {
                addressLine1, addressLine2, city, state, zip, country,
            },
            phone,
        } = userProfile;
        const newUserProfile: UserProfileMessage = {
            ...userProfile,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            shopName: shopName.trim(),
            shopAddress: {
                addressLine1: addressLine1.trim(),
                addressLine2,
                city: city.trim(),
                zip: zip.trim(),
                state,
                country,
            },
            phone: phone.trim(),
        };

        if (isEmpty(newUserProfile.firstName)) {
            setErrorMessage('Please provide your first name.');
        } else if (isEmpty(newUserProfile.lastName)) {
            setErrorMessage('Please provide your last name.');
        } else if (isEmpty(newUserProfile.shopName)) {
            setErrorMessage("Please provide your shop's name.");
        } else if (isEmpty(newUserProfile.shopAddress.addressLine1)) {
            setErrorMessage("Please provide your shop's address line 1.");
        } else if (isEmpty(newUserProfile.shopAddress.city)) {
            setErrorMessage("Please provide your shop's city.");
        } else if (isEmpty(newUserProfile.shopAddress.zip)) {
            setErrorMessage("Please provide your shop's zip or postal code.");
        } else if (newUserProfile.phone.length < 3) {
            setErrorMessage('Please provide a phone number.');
        } else {
            mutateUserProfile({ variables: { userProfile: newUserProfile } });
        }
    }
};

export default UserProfile;
