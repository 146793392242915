import { OldVehicleResponse } from "@adas/shared-types";
import React from "react";

export function OldVehicle({ response }: { response: OldVehicleResponse }) {
    return (
        <>
            <div className="notification is-warn">
                <p data-testid="vehicle-too-old-message">
                    The vehicle {response.vehicleName} is not in our system because it is older than the year 2013.
                </p>
            </div>
        </>
    );
}
