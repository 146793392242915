import { routes, UploadDisabledReason } from '@adas/shared-types';
import React from 'react';
import { Link } from 'react-router-dom';
import IsFeatureFlagEnabled from '../../hooks/IsFeatureFlagEnabled';

export interface MaxEstimatesReachedMessageProps {
    uploadDisabledReason: UploadDisabledReason;
}

export const MaxEstimatesReachedMessage = ({ uploadDisabledReason }: { uploadDisabledReason: UploadDisabledReason }) => {
    const [planPageDisabled, { loading: featureFlagLoading }] = (
        IsFeatureFlagEnabled({ variables: { featureKey: 'PLAN_PAGE_DISABLED' } })
    );

    if (featureFlagLoading) {
        return null;
    }
    const getUploadDisabledReasonText = () => {
        // It's ok that these display this message for non-account owners. When they click the link, they'll be presented with
        // a message that says their account is managed by an account manager and who those people are.
        switch (uploadDisabledReason) {
            case 'expiryDateReached':
                return (
                    <>
                        <p>
                            Your plan has expired.
                        </p>
                        <p>
                            To continue using adasThink, please upgrade your
                            {' '}
                            {planPageDisabled ? 'plan' : (
                                <Link to={routes.pages.account.plans}>
                                    plan
                                </Link>
                            )}
                            .
                        </p>
                    </>
                );
            case 'freeTrialLimitReached':
                return (
                    <>
                        <p>
                            Wow! You&apos;ve hit the maximum number of reports for your free trial!
                        </p>
                        <p>
                            To continue using adasThink, please upgrade your
                            {' '}
                            {planPageDisabled ? 'plans' : (
                                <Link to={routes.pages.account.plans}>
                                    plan
                                </Link>
                            )}
                            .
                        </p>
                    </>
                );
            case 'limitReached':
                return (
                    <>
                        <p>
                            You&apos;ve hit the maximum number of reports for your plan!
                        </p>
                        <p>
                            To continue using adasThink for the current month, please upgrade your
                            {' '}
                            {planPageDisabled ? 'plans' : (
                                <Link to={routes.pages.account.plans}>
                                    plan
                                </Link>
                            )}
                            .
                        </p>
                    </>
                );
            default:
                return (
                    <div>
                        Unhandled scenario. Looks like you stumbled on a bug! Maybe try refreshing the page.
                    </div>
                );
        }
    };
    return (
        <div className="notification is-info" data-testid="max-estimates-reached-message">
            {getUploadDisabledReasonText()}
        </div>
    );
};
