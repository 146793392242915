import React, { useState } from 'react';
import {
    FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { isNil } from 'lodash';
import QuestionnaireStep from './QuestionnaireStep';

export default ({
    trims, selectedTrim, setSelectedTrim, navigateBack,
}: {
    trims: string[],
    selectedTrim: string | undefined,
    setSelectedTrim: (newVin: string) => void,
    navigateBack: () => void,
}) => {
    const [trimValue, setTrimValue] = useState<string | undefined>(selectedTrim);
    return (
        <QuestionnaireStep
            headerText="Please select the vehicle trim:"
            onSubmit={isNil(trimValue) ? () => null : () => setSelectedTrim(trimValue)}
            areAnswersValid={!isNil(trimValue)}
            navigateBack={navigateBack}
        >
            <FormControl fullWidth>
                <InputLabel id="trim-select-label">Trim</InputLabel>
                <Select
                    labelId="trim-select-label"
                    id="trim-select"
                    value={trimValue}
                    label="Trim"
                    onChange={(e) => setTrimValue(e.target.value)}
                >
                    {trims.map((trim) => <MenuItem value={trim}>{trim}</MenuItem>)}
                </Select>
            </FormControl>
        </QuestionnaireStep>
    );
};
