import React from 'react';
import { constants, CountryCodes, StateCodes } from '../constants';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from '@mui/material';
export function EditStateCountry({
    state,
    country,
    onChange,
    isCountryDisabled = false,
}: {
    state: StateCodes;
    country: CountryCodes;
    onChange: (data: { state: StateCodes; country: CountryCodes }) => void;
    isCountryDisabled?: boolean;
}) {
    const countries = Object.entries(constants.Countries);
    const states = Object.entries(constants.States[country] ?? {});

    return (
        <Grid container spacing={2}>
            <Grid item>
                <FormControl sx={{ minWidth: 250 }}>
                    <InputLabel id="country-select-helper-label">Country</InputLabel>
                    <Select
                        labelId="country-select-helper-label"
                        id="country-select-helper"
                        value={country}
                        label="Country"
                        fullWidth
                        onChange={(e) => {
                            const chosenCountry = e.target.value as CountryCodes;
                            // select the first state
                            const stateItems = Object.keys(constants.States[chosenCountry])[0] as StateCodes;
                            onChange({ state: stateItems, country: chosenCountry });
                        }}
                        data-testid="country-drop-down"
                    >
                        {countries.map(([value, display], i) => (
                            <MenuItem value={value} key={value} selected={value === country}>
                                {display}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl sx={{ minWidth: 250 }}>
                    <InputLabel>State</InputLabel>
                    <Select
                        labelId="state-select-helper-label"
                        data-testid="state-drop-down"
                        id="state-select-helper"
                        value={state}
                        fullWidth
                        label="State"
                        onChange={(e) => onChange({ state: e.target.value as StateCodes, country })}
                    >
                        {states.map(([value, display], i) => (
                            <MenuItem value={value} key={value} selected={value === state}>
                                {display}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
