export function formatCentsAsDollars(priceInCents: number) {
    return (priceInCents / 100).toFixed(2);
}

export function formatPriceWithCurrency(price: { currency: string; priceInCents: number }) {
    const formattedPrice = formatCentsAsDollars(price.priceInCents);
    if (price.currency.toLowerCase() === "cad") {
        return `CA$${formattedPrice}`;
    } else if (price.currency.toLowerCase() === "usd") {
        return `US$${formattedPrice}`;
    } else {
        return `${formattedPrice} ${price.currency.toUpperCase()}`;
    }
}
