import {
    getFormattedDate, Guid, routes, stringUtils,
} from '@adas/shared-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import { AccountPageView, AccountPageViewOwnerDetails, Api } from '../ClientServerApi.generated';
import { CustomerUserInvitations, LoadingBar } from './components';
import IsFeatureFlagEnabled from '../hooks/IsFeatureFlagEnabled';

const getExpiresText = (ownerDetails: AccountPageViewOwnerDetails) => {
    if (ownerDetails.usage.expiryDate != null) {
        if (ownerDetails.usage.uploadDisabledReason === 'expiryDateReached') {
            return `, Expired: ${getFormattedDate(ownerDetails.usage.expiryDate)}`;
        } if (ownerDetails.usage.uploadDisabledReason == null) {
            return `, Expires: ${getFormattedDate(ownerDetails.usage.expiryDate)}`;
        }
    }
    return '';
};

const ViewPlanLink = ({ ownerDetails }: { ownerDetails: AccountPageViewOwnerDetails }) => {
    if (ownerDetails.currentPlanId == null) {
        return (
            <>
                {' ('}
                <Link to={routes.pages.account.plans}>Upgrade</Link>
                )
            </>
        );
    }
    return (
        <>
            {' ('}
            <Link
                data-testid="view-plan-link"
                to={routes.pages.account.plan(ownerDetails.currentPlanId.toLowerCase() as Guid)}
            >
                View
            </Link>
            {' | '}
            <Link to={routes.pages.account.plans}>Change</Link>
            )
        </>
    );
};

const PlanOwnerSection = ({ ownerDetails, planPageDisabled = false }: {
    ownerDetails: AccountPageViewOwnerDetails,
    planPageDisabled?: boolean,
}) => {
    const { planName, usage } = ownerDetails;
    return (
        <ul data-test-group="account-page">
            <li>
                <b>Plan:</b>
                {' '}
                <span data-testid="plan-name">
                    {`${planName}${getExpiresText(ownerDetails)}`}
                </span>
                {planPageDisabled ? null : <ViewPlanLink ownerDetails={ownerDetails} />}
            </li>
            <li>
                <b>Usage this month:</b>
                {' '}
                <span data-testid="reports-used-count">
                    {usage.usedCount}
                </span>
                {usage.allowedCount != null && (
                    <>
                        /
                        <span data-testid="reports-allowed-count">
                            {usage.allowedCount}
                        </span>
                    </>
                )}
            </li>
            {ownerDetails.usersCount === 'notAvailable' ? null : (
                <li>
                    <b>Users:</b>
                    {' '}
                    <span data-testid="users-count">
                        {ownerDetails.usersCount}
                    </span>
                    {' '}
                    (
                    <Link to={routes.pages.account.users}>Manage</Link>
                    )
                </li>
            )}
        </ul>
    );
};

const BillingAndCreditCard = ({ accountView }: { accountView: AccountPageView }) => {
    if (accountView.ownerDetails === 'notOwner') {
        return null;
    }

    return (
        <li>
            <Link to={routes.pages.account.billing}>
                {accountView.ownerDetails.hasBillingInformation
                    ? 'Update'
                    : 'Add'}
                {' '}
                Billing Information
            </Link>
        </li>
    );
};

const ApiDetails = () => (
    <li data-test-group="account-page">
        <Link to={routes.pages.account.apiDetails} data-testid="view-api-information-link">
            View API Information
        </Link>
    </li>
);

const PlanUserSection = ({ accountView }: { accountView: AccountPageView }) => (
    <ul data-test-group="account-page">
        <li>
            Your plan is managed by
            {' '}
            <span data-testid="owner-name">{accountView.ownerName}</span>
            .
        </li>
        <li>
            Usage this month:
            {' '}
            <span data-testid="user-reports-used-count">{accountView.userReportsUsedCount}</span>
            {' '}
            {stringUtils.pluralize('report', accountView.userReportsUsedCount)}
        </li>
    </ul>
);

export const AccountPage = () => {
    const [accountView, setAccountView] = useState<AccountPageView | undefined>(undefined);
    const [planPageDisabled, { loading: featureFlagLoading }] = (
        IsFeatureFlagEnabled({ variables: { featureKey: 'PLAN_PAGE_DISABLED' } })
    );
    const refresh = () => {
        setAccountView(undefined);
        Api.getAccountView().then((view) => {
            setAccountView(view);
        }).catch(() => {
            console.log('Error retrieving Account View');
        });
    };

    useEffect(() => refresh(), []);

    if (isNil(accountView) || featureFlagLoading) {
        return <LoadingBar />;
    }

    return (
        <div className="content">
            <header>
                <h1 className="title">Account</h1>
            </header>

            <CustomerUserInvitations onChange={() => refresh()} />

            <h2 className="subtitle">Plan</h2>
            <p>
                {accountView.ownerDetails === 'notOwner'
                    ? <PlanUserSection accountView={accountView} />
                    : <PlanOwnerSection ownerDetails={accountView.ownerDetails} planPageDisabled={planPageDisabled} />}
            </p>

            <h2 className="subtitle">Details</h2>
            <p>
                <ul>
                    <li>
                        <Link to={routes.pages.account.userProfile}>Update User Profile or Password</Link>
                    </li>
                    <BillingAndCreditCard accountView={accountView} />
                    {accountView.isApiUser && <ApiDetails />}
                </ul>
            </p>
        </div>
    );
};

export default AccountPage;
