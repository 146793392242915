import React from 'react';

const SentenceJoin = (
    {
        items,
        andOrText,
    }:
    {
        items: JSX.Element[],
        andOrText: 'and' | 'or'
    },
) => {
    const joinedSentenceElements = items.map((Item: JSX.Element, i) => {
        const elementArray = [];
        if (i > 0) {
            if (i === items.length - 1) {
                if (i > 1) {
                    elementArray.push(<>,</>);
                }
                elementArray.push(<>{` ${andOrText} `}</>);
            } else {
                // This is not the last item
                elementArray.push(<>{', '}</>);
            }
        }
        elementArray.push(Item);

        return elementArray;
    });
    return (
        <>
            {joinedSentenceElements}
        </>
    );
};

export default SentenceJoin;
