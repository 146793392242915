import { Modal, routes, TextBox } from "@adas/shared-types";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Api, ApiDetailsPageView } from "../ClientServerApi.generated";
import { LoadingBar } from "./components";

export function ApiDetailsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState<ApiDetailsPageView | "notFound" | undefined>(undefined);

    useEffect(() => {
        if (isLoading) {
            Api.getApiDetails().then(view => {
                setView(view ?? "notFound");
                setIsLoading(false);
            });
        }
    }, [isLoading]);

    if (view == null) {
        return <LoadingBar />;
    } else if (view === "notFound") {
        return <Redirect to={routes.pages.account.index} />;
    }

    return (
        <div className="content" data-test-group="api-details-page">
            <header>
                <h1 className="title">Account - Api Information</h1>
            </header>

            <div className="field">
                <label className="label">User Id</label>
                <div className="control">
                    <TextBox data-testid="user-id" value={view.userId} readOnly={true} />
                </div>
            </div>
            <ApiKeyField
                label="API Key 1"
                value={view.apiKey1}
                onRegenerate={() => {
                    Api.regenerateApiKey1().then(() => setIsLoading(true));
                }}
            />
            <ApiKeyField
                label="API Key 2"
                value={view.apiKey2}
                onRegenerate={() => {
                    Api.regenerateApiKey2().then(() => setIsLoading(true));
                }}
            />
        </div>
    );
}

function ApiKeyField(props: { label: string; value: string; onRegenerate: () => void }) {
    const [show, setShow] = useState(false);

    return (
        <div className="field" data-test-group="api-key-field">
            <label className="label">
                {props.label}
                {" ("}
                <a href="javascript:void(0)" onClick={() => setShow(!show)} data-testid="toggle-key-visibility-button">
                    {show ? "Hide" : "Show"}
                </a>
                {" | "}
                <a href="javascript:void(0)" onClick={() => onRegenerate()} data-testid="regenerate-key-button">
                    Regenerate
                </a>
                {")"}
            </label>
            <div className="control">
                <TextBox
                    data-testid="api-key-text-box"
                    value={show ? props.value : "\u2022".repeat(props.value?.length)}
                    readOnly={true}
                />
            </div>
        </div>
    );

    async function onRegenerate() {
        const originalShow = show;
        setShow(true);
        const shouldRegenerate = await Modal.confirm(
            `Are you sure you want to regenerate ${props.label}?`
                + `\nWARNING: This will deactivate the current ${props.label} and generate a new one. `
                + `Any services using this key will no longer be able communicate with the server until they switch over to the new key.`,
        );
        if (shouldRegenerate) {
            props.onRegenerate();
        } else {
            setShow(originalShow);
        }
    }
}
