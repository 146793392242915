import { DropDown, isValidVin, Modal, parseVin, TextBox, VehicleName, VIN } from "@adas/shared-types";
import React, { useState } from "react";

export interface VinNotFoundProps {
    vin: string;
    possibleVehicles: VehicleName[];
    onVinChange: (vin: VIN) => void;
    onVehicleSelect: (vehicle: VehicleName) => void;
}

export function VinNotFound({
    vin,
    onVinChange,
    onVehicleSelect,
    possibleVehicles,
}: VinNotFoundProps) {
    const [localVin, setLocalVin] = useState(vin);
    const [vehicleName, setVehicleName] = useState<VehicleName | undefined>(
        possibleVehicles.length === 0 ? undefined : possibleVehicles[0],
    );
    const vehicleNameIndex = vehicleName == null ? undefined : possibleVehicles.indexOf(vehicleName);

    return (
        <>
            {possibleVehicles.length === 0 && (
                <div className="notification is-info">
                    <p>No data found on VIN '{vin}'.</p>
                </div>
            )}
            {possibleVehicles.length > 0 && (
                <div className="field">
                    <label className="label">Vehicle:</label>
                    <div className="control">
                        <DropDown
                            data-testid="vehicle-drop-down"
                            value={vehicleNameIndex?.toString()}
                            allowEmpty={true}
                            onChange={index => {
                                if (index == null) {
                                    setVehicleName(undefined);
                                } else {
                                    setVehicleName(possibleVehicles[parseInt(index, 10)]);
                                }
                            }}
                            options={possibleVehicles.map((v, i) => {
                                const formattedName = `${v.year} ${v.make} ${v.model}`;
                                return {
                                    text: formattedName,
                                    value: i.toString(),
                                };
                            })}
                        />
                    </div>
                </div>
            )}
            <div className="field">
                <label className="label">VIN number:</label>
                <div className="control">
                    <TextBox
                        placeholder="VIN number"
                        data-testid="vin-number-text-box"
                        value={vehicleName == null ? localVin : vin}
                        disabled={vehicleName != null}
                        onChange={value => setLocalVin(value.trim())}
                    />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <button
                        data-testid="submit-button"
                        className="button is-link"
                        type="button"
                        onClick={onSubmit}
                        disabled={localVin === vin && vehicleName == null}
                    >
                        {possibleVehicles.length === 0 ? "Try Again" : "Next"}
                    </button>
                </div>
            </div>
        </>
    );

    function onSubmit() {
        if (vehicleName == null) {
            if (isValidVin(localVin)) {
                onVinChange(parseVin(localVin));
            } else {
                Modal.alert("The provided VIN was not valid.");
            }
        } else {
            onVehicleSelect(vehicleName);
        }
    }
}
