export function parseQueryString(queryString?: string) {
    const query: { [key: string]: string | undefined } = {};
    queryString = queryString ?? window.location.search.substring(1);
    for (const [key, value] of queryString.split("&").map(keyValue => keyValue.split("="))) {
        if (key != null && key.length > 0) {
            query[decodeURIComponent(key)] = decodeURIComponent(value || "");
        }
    }
    return query;
}
