import { Guid } from "./utils";

export const routes = {
    pages: {
        home: "/",
        userUsage: '/user-usage/:userId/month/:month',
        register: "/register",
        login: "/login",
        logout: "/logout",
        newReport: "/new-report",
        customReport: "/custom-report",
        questionnaire: "/questionnaire",
        pastReports(pageNumber?: number) {
            if (pageNumber == null || pageNumber === 1) {
                return `/reports`;
            }
            return `/reports/${pageNumber}`;
        },
        forgotPassword: "/forgot-password",
        passwordReset: "/password-reset",
        confirmAccount: "/confirm-account",
        getReport(id: Guid) {
            return `/report/${id}`;
        },
        account: {
            index: "/account",
            userProfile: "/account/profile",
            billing: "/account/billing",
            plan(id: Guid) {
                return `/account/plan/${id}`;
            },
            plans: "/account/plans",
            users: "/account/users",
            apiDetails: "/account/api",
        },
        gettingStarted: "/getting-started",
    },
    data: {
        get: {
            getReportPdf(reportId: Guid) {
                return `/api/report-pdf/${reportId}`;
            },
        },
        post: {
            login: "/api/login",
            upload: "/api/upload",
            customReport: "/api/custom-report"
        },
    },
} as const;
