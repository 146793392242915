import { constants } from "../../constants";
import { isValidEmail } from "../../utils";
import { isNil } from 'lodash';
import { CountryCodes, StateCodes } from "../../constants";

type RegisterValidationKeys<Type> = {
    [Property in keyof Type]?: string;
};

export type ValidationErrorResponse = RegisterValidationKeys<RegistrationFields>;

export type RegisterValidationResponse = {
    error: boolean | null;
    errorMessages: ValidationErrorResponse;
    displayMessage: Array<string>;
}

export interface RegistrationFields {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    shopName: string;
    shopAddress: Address;
    agreeLegal: boolean;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: StateCodes;
    country: CountryCodes;
    zip: string;
}

export type ValidationField = 'agreeLegal' | 'firstName' | 'lastName' | 'shopName' | 'shopAddress' | 'email' | 'phone' | 'password';

export default (regFormData: Partial<RegistrationFields>, fieldsToValidate: ValidationField[]): RegisterValidationResponse => {

    const rs: RegisterValidationResponse = { error: null, errorMessages: {}, displayMessage:[] };

    const { agreeLegal, firstName, lastName, shopAddress, shopName, password, passwordConfirm, email, phone } = regFormData;

    if(fieldsToValidate.includes('agreeLegal')) {
        if(isNil(agreeLegal) || !agreeLegal) {
            rs.errorMessages.agreeLegal = 'You must agree to the terms of service and privacy policy in order to register.';
            rs.displayMessage.push(rs.errorMessages.agreeLegal);
        }
    }
    if (fieldsToValidate.includes('firstName')) {
        if(isNil(firstName) || firstName.trim().length === 0) {
            rs.errorMessages.firstName = 'Please provide your first name.';
            rs.displayMessage.push(rs.errorMessages.firstName);
        }
    }
    if (fieldsToValidate.includes('lastName')) {
        if (isNil(lastName) || lastName.trim().length === 0) {
            rs.errorMessages.lastName = 'Please provide your last name.';
            rs.displayMessage.push(rs.errorMessages.lastName);
        }
    }
    if (fieldsToValidate.includes('shopName')) {
        if (isNil(shopName) || shopName.trim().length === 0) {
            rs.errorMessages.shopName = `Please provide your shop's name.`;
            rs.displayMessage.push(rs.errorMessages.shopName);
        }
    }
    if (fieldsToValidate.includes('shopAddress')) {
        if (isNil(shopAddress) || shopAddress.addressLine1.trim().length === 0) {
            rs.errorMessages.shopAddress = `Please provide your shop's address line 1.`;
            rs.displayMessage.push(rs.errorMessages.shopAddress);
        }
        if(isNil(shopAddress) || shopAddress.city.trim().length === 0) {
            rs.errorMessages.shopAddress = `Please provide your shop's city.`;
            rs.displayMessage.push(rs.errorMessages.shopAddress);
        }
        if (isNil(shopAddress) || shopAddress.zip.trim().length === 0) {
            rs.errorMessages.shopAddress = `Please provide your shop's zip or postal code.`;
            rs.displayMessage.push(rs.errorMessages.shopAddress);
        }
    }
    if (fieldsToValidate.includes('email')) {
        if (isNil(email) || !isValidEmail(email.trim())) {
            rs.errorMessages.email = 'Please provide a valid email address.';
            rs.displayMessage.push(rs.errorMessages.email);
        }
    }
    if (fieldsToValidate.includes('phone')) {
        if (isNil(phone) || phone.trim().length < 3) {
            rs.errorMessages.phone = 'Please provide a phone number.';
            rs.displayMessage.push(rs.errorMessages.phone);
        }
    }
    return rs;
};
