import { Button } from "@adas/shared-types";
import React, { useEffect, useState } from "react";

export function VehicleInfoCurrentlyUnavailable({ onTryAgain }: { onTryAgain: () => void }) {
    const [countDown, setCountDown] = useState(10);

    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                setCountDown(countDown - 1);
            }, 1000);
        }
    }, [countDown]);

    return (
        <div className="message is-warning" data-testid="vehicle-info-unavailable-message">
            <div className="message-header">
                <p>Vehicle Information Temporarily Unavailable</p>
            </div>
            <div className="message-body">
                <p>
                    An error occurred retrieving vehicle information. You may want to try again in a few minutes by
                    clicking the button below.
                </p>
                <Button
                    onClick={onTryAgain}
                    data-testid="try-again-button"
                    disabled={countDown > 0}
                >
                    {`Try Again${countDown > 0 ? ` (${countDown}s)` : ""}`}
                </Button>
            </div>
        </div>
    );
}
