import {
    gql, useMutation, MutationHookOptions, MutationTuple,
} from '@apollo/client';
import { UserProfileMessage } from '../ClientServerApi.generated';

const query = gql`
    mutation updateUserProfile($input: userProfile!) {
        updateUserProfile(input: $userProfile)  @rest(
            type: "UserProfile",
            path: "api/user-profile/update",
            method: "POST"
        ) {
            error: string
        }
    }
`;

type ResultType = { updateUserProfile: { error?: string } };

type HookType = (options?: MutationHookOptions<ResultType, { userProfile: UserProfileMessage }>) => (
    MutationTuple<ResultType, { userProfile: UserProfileMessage }>
);

const UpdateUserProfile: HookType = (options) => {
    const [mutateUserProfile, rest] = useMutation<ResultType, { userProfile: UserProfileMessage }>(query, options);
    return [mutateUserProfile, rest];
};

export default UpdateUserProfile;
