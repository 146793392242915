import React, { HTMLAttributes, MouseEventHandler } from "react";
import { getHtmlProps } from "./getHtmlProps";

export interface ButtonProps {
    size?: "regular" | "small";
    kind?: "regular" | "warning" | "danger" | "info" | "link" | "light-link" | "cancel" | "primary" | "success";
    className?: string;
    children: React.ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    href?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    type?: "submit" | "reset" | "button";
}

export function Button(props: ButtonProps) {
    if (props.href != null && props.href.length > 0 && !props.disabled) {
        return (
            <a
                className={getButtonClassName(props)}
                type={props.type ?? "button"}
                href={props.href}
                style={props.style}
                onClick={props.onClick}
                {...getHtmlProps(props)}
            >
                {props.children}
            </a>
        );
    }
    return (
        <button
            className={getButtonClassName(props)}
            onClick={props.onClick}
            type={props.type ?? "button"}
            style={props.style}
            disabled={props.disabled}
            {...getHtmlProps(props)}
        >
            {props.children}
        </button>
    );
}

function getButtonClassName(props: ButtonProps) {
    let className = "button";
    const size = props.size ?? "regular";
    const kind = props.kind ?? "regular";

    if (props.className && props.className.length > 0) {
        className += ` ${props.className}`;
    }

    switch (size) {
        case "regular":
            break;
        case "small":
            className += " is-small";
            break;
        default:
            const _assertNever: never = size;
            break;
    }

    switch (kind) {
        case "regular":
            break;
        case "info":
            className += " is-info";
            break;
        case "danger":
            className += " is-danger";
            break;
        case "warning":
            className += " is-warning";
            break;
        case "link":
            className += " is-link";
            break;
        case "light-link":
        case "cancel":
            className += " is-link is-light";
            break;
        case "primary":
            className += " is-primary";
            break;
        case "success":
            className += " is-success";
            break;
        default:
            const _assertNever: never = kind;
            break;
    }

    return className;
}
