import {
    EstimateUploadOptions,
    Guid,
    Modal,
    ReportResponses,
    ReportUploadResponse,
    routes,
    VehicleName,
    VIN,
    VinNotFoundResponse,
    VinSelectionResponse,
} from "@adas/shared-types";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import UseAppToken from '../../AuthenticationHooks/UseAppToken';
import { LastUploadedReportSuccessResponseStore } from "../../utils/LastUploadedReportSuccessResponseStore";
import { LoadingBar } from "../components";
import { FileSelection } from "./FileSelection";
import { MaxEstimatesReachedMessage } from "./MaxEstimatesReachedMessage";
import { NotFoundVehicle } from "./NotFoundVehicle";
import { OldVehicle } from "./OldVehicle";
import { VehicleInfoCurrentlyUnavailable } from "./VehicleInfoCurrentlyUnavailable";
import { VinNotFound } from "./VinNotFound";
import { VinSelect } from "./VinSelect";

export function EstimateUpload() {
    const [appToken] = UseAppToken();
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [fileBytes, setFileBytes] = useState<Uint8Array | undefined>(undefined);
    const [vinNumber, setVinNumber] = useState<VIN | undefined>(undefined);
    const [vehicleName, setVehicleName] = useState<VehicleName | undefined>(undefined);
    const [estimateResponse, setEstimateResponse] = useState<ReportResponses | undefined>(undefined);
    const [tryAgainCount, setTryAgainCount] = useState(0);

    useEffect(() => {
        tryRunEstimate();
    }, [fileName, fileBytes, vinNumber, vehicleName, tryAgainCount]);

    return (
        <section className="upload">
            {renderMain()}
        </section>
    );

    function renderMain() {
        if (isLoading) {
            return <LoadingBar />;
        } else if (estimateResponse == null) {
            return renderUploadForm();
        } else if (estimateResponse.type === "PdfError") {
            return (
                <>
                    {
                        <div className="notification is-danger" data-testid="pdf-error-message">
                            <p>
                                Oops! Looks like there was a problem inspecting your PDF. Please make sure it's text
                                searchable (you should be able to search and highlight text) and that you properly
                                followed <Link to={routes.pages.gettingStarted}>the instructions</Link>{" "}
                                for your estimating system. You can also check our{" "}
                                <Link to={routes.pages.gettingStarted}>guide</Link> or get in touch with us at{" "}
                                <a href="mailto:customerservice@astech.com">customerservice@astech.com</a> and we'll take a look!
                            </p>
                        </div>
                    }
                    {renderUploadForm()}
                </>
            );
        } else if (estimateResponse.type === "VinSelection") {
            return renderVinSelection(estimateResponse);
        } else if (estimateResponse.type === "VinNotFound") {
            return renderVinNotFound(estimateResponse);
        } else if (estimateResponse.type === "Success") {
            return <Redirect to={routes.pages.getReport(estimateResponse.reportId.toLowerCase() as Guid)} />;
        } else if (estimateResponse.type === "OldVehicle") {
            return <OldVehicle response={estimateResponse} />;
        } else if (estimateResponse.type === "NotFound") {
            return <NotFoundVehicle response={estimateResponse} />;
        } else if (estimateResponse.type === "VehicleInfoCurrentlyUnavailable") {
            return <VehicleInfoCurrentlyUnavailable onTryAgain={() => setTryAgainCount(tryAgainCount + 1)} />;
        } else if (estimateResponse.type === "MaxEstimateUploadReached") {
            return (
                <MaxEstimatesReachedMessage
                    uploadDisabledReason={estimateResponse.uploadDisabledReason!}
                />
            );
        } else {
            const expectNever: never = estimateResponse;
            return <div>Unhandled scenario.</div>;
        }
    }

    function renderUploadForm() {
        return (
            <FileSelection
                onSelection={(fileName, fileBytes) => {
                    setFileName(fileName);
                    setFileBytes(fileBytes);
                }}
            />
        );
    }

    function renderVinSelection(vinSelection: VinSelectionResponse) {
        return (
            <VinSelect
                vins={vinSelection.vins}
                onChange={vin => {
                    setVinNumber(vin);
                }}
            />
        );
    }

    function renderVinNotFound(vinNotFound: VinNotFoundResponse) {
        return (
            <VinNotFound
                vin={vinNotFound.vin}
                possibleVehicles={vinNotFound.possibleVehicles}
                onVinChange={vin => {
                    setVinNumber(vin);
                }}
                onVehicleSelect={vehicleName => {
                    setVehicleName(vehicleName);
                }}
            />
        );
    }

    async function tryRunEstimate() {
        if (fileBytes == null) {
            return;
        }

        try {
            setIsLoading(true);
            setFileBytes(fileBytes);

            const formData = new FormData();
            formData.append("estimate", new Blob([fileBytes]), fileName);
            const uploadOptions: EstimateUploadOptions = {
                vin: vinNumber,
                vehicleName: vehicleName,
            };
            formData.append("options", JSON.stringify(uploadOptions));

            const responsePromise = fetch(routes.data.post.upload, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization:`Bearer ${appToken}`
                }
            });
            const response = await responsePromise;
            const responseData: ReportUploadResponse = await response.json();

            if (responseData.response.type === "PdfError") {
                reset();
            } else if (responseData.response.type === "Success") {
                // store this to make showing the view report page faster
                LastUploadedReportSuccessResponseStore.instance.set({
                    response: responseData.response,
                    user: responseData.user,
                });
            }

            setEstimateResponse(responseData.response);
        } catch (error) {
            // todo: handle this better
            console.error(error);
            Modal.alert("There was an error checking the estimate.");
            reset();
        } finally {
            setIsLoading(false);
        }
    }

    function reset() {
        setFileBytes(undefined);
        setFileName("");
        setVinNumber(undefined);
        setEstimateResponse(undefined);
    }
}
