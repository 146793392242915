import React, { useMemo } from 'react';
import { constants, Guid } from '@adas/shared-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { LoadingBar, Pager } from '../components';
import CustomerUsageTable from './CustomerUsageTable';
import Header from './Header';
import GetUserUsageForMonth from './hooks/GetUserUsageForMonth';

const parsePageIndex = (pageNumber: string) => {
    const pageNumberNum = parseInt(pageNumber, 10);
    if (Number.isNaN(pageNumberNum) || pageNumberNum < 1) {
        return 0;
    }
    return pageNumberNum - 1;
};

export default () => {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const { month, userId } = useParams<{ month: string | undefined, userId: Guid | undefined }>();

    const updateParams = (pageNumber: number) => {
        const params = new URLSearchParams({ pageNumber: (pageNumber + 1).toString() });
        history.replace({ pathname, search: params.toString() });
    };

    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const pageIndex = parsePageIndex(queryParams.get('pageNumber') || '1');

    const rowCount = 10;
    const offset = (pageIndex) * rowCount;

    const [{ usage, usageCount, error }, { loading }] = (
        GetUserUsageForMonth({
            variables: {
                offset, limit: rowCount, userId, monthTime: month,
            },
        }));

    const pageCount = Math.ceil(usageCount / constants.RowsPerPage);

    if (isNil(userId) || isNil(month)) {
        return null;
    }

    return (
        <>
            <div className="content">
                <Header userId={userId} usageMonth={parseInt(month, 10)} />
                {loading ? <LoadingBar /> : (
                    <>
                        <CustomerUsageTable userUsage={usage} error={error} />
                        <Pager
                            pageIndex={pageIndex}
                            pageCount={pageCount}
                            onPageChange={updateParams}
                        />
                    </>
                )}
            </div>
        </>
    );
};
