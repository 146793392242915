import React, { useEffect, useState } from "react";

export function Spinner({ className }: { className?: string }) {
    let [passedTimeout, setPassedTimeout] = useState(false);
    // Wait a little bit in order to prevent it from flashing on the screen
    // if only being shown for a bit.
    useEffect(() => {
        const timeout = setTimeout(() => {
            setPassedTimeout(true);
        }, 800);
        return () => clearTimeout(timeout);
    });

    if (!passedTimeout) {
        return <></>;
    }

    return (
        <div className={"la-ball-spin-clockwise la-dark la-sm " + (className ?? "")}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
