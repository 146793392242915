import React from "react";
import { getHtmlProps } from "./getHtmlProps";

export interface TextBoxProps {
    className?: string;
    type?: "text" | "email" | "password";
    size?: "regular" | "small";
    onChange?: (value: string) => void;
    value: string;
    name?: string;
    maxLength?: number;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
}

export function TextBox(props: TextBoxProps) {
    let className = "input";
    if (props.className) {
        className += " " + props.className;
    }
    if (props.size === "small") {
        className += " is-small";
    }

    return (
        <input
            type={props.type ?? "text"}
            value={props.value}
            className={className}
            disabled={props.disabled}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            onChange={(event) => props.onChange?.(event.target.value)}
            name={props.name}
            {...getHtmlProps(props)}
        />
    );
}
