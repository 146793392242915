import React from "react";

export default ({
    errorMessage,
    apiErrorResponse
}: {
    errorMessage: string;
    apiErrorResponse: string | undefined;
}) => (
    (errorMessage.length > 0 || apiErrorResponse && apiErrorResponse.length > 0)
        ? (
            <div className="notification is-warning" data-testid="error-message-notification">
                {errorMessage.length ? errorMessage : apiErrorResponse && apiErrorResponse.length ? apiErrorResponse : ''}
            </div>
        )
    : <></>
)