import React from "react";
import { HowToSaveEstimates } from "./help";

export function GettingStartedPage() {
    return (
        <div className="content">
            <HowToSaveEstimates />
        </div>
    );
}
